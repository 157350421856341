import { multilineTextTransformBase } from "./Helpers/multilineTextTransform";

/* Renders a separate section with h2 title and the text within a well. Newlines (both cr and crlf) are replaced with the br tags.
   text - the text to display in the well.
   Newline characters in the text will be replaced with the br tags. 
   The text can be null or undefined to indicate the lack of value.
   An empty string is a valid value that isn't replaced.
   escapeHtml (default: true) - if the text contains html, it will be escaped. This doesn't apply to the br tags generated inside to replace newline characters.
   showNotSpefied (default: true) - when text has no value, showNotSpefied decides whether to display the "Not specified" replacement (true) or just leave the well empty (false).
   notSpecifiedText (defalut: "brak") - the text to use to replace empty value (text that is undefined or null)
 */
export function DescriptionSection(props: {
  title: string;
  text: string | null | undefined;
  /** Text to use to replace a null/undefined value, used when notSpecifiedText is true */
  notSpecifiedText?: string;
  /** Whether to show the replacement text for the null/undefined text. Default is true. */
  showNotSpecfied?: boolean;
  /** Whether the specified text should be html-escaped. Default is true. */
  escapeHtml?: boolean;
}) {
  const notSpecifiedText =
    props.notSpecifiedText === undefined ? "brak" : props.notSpecifiedText!;
  const showNotSpecfied =
    props.showNotSpecfied === undefined ? true : props.showNotSpecfied!;
  const shouldEscapeHTML =
    props.escapeHtml === undefined ? true : props.escapeHtml;

  let text = multilineTextTransformBase(props.text, shouldEscapeHTML);

  return (
    <div className="-app-display-section">
      <h2>{props.title}</h2>
      <div className="card bg-light bg-gradient">
        {(props.text !== null && props.text !== undefined) ||
        !showNotSpecfied ? (
          <div
            className="card-body"
            dangerouslySetInnerHTML={{
              __html: text || "",
            }}
          ></div>
        ) : (
          <div className="card-body">
            <small>{notSpecifiedText}</small>
          </div>
        )}
      </div>
    </div>
  );
}

export default DescriptionSection;
