import { useState } from "react";
import List from "./Page1/List";
import Form from "./Page2/Form";
import NoRowsMessage from "./NoRowsMessage";

/** This is the master form for a new return request. */
export default function NewReturnRequest(props: {
  navigate: (url: string) => void;
  backUrl?: string;
}) {
  let [isFirstPage, setIsFirstPage] = useState<boolean>(true);
  let [isNoRows, setIsNoRows] = useState<boolean>(false);
  let [reelIds, setReelIds] = useState<number[]>([]);

  const navigateBack = (): void => {
    props.navigate(props.backUrl ?? "../index")
  }

  return (
    <>
      {isNoRows && <NoRowsMessage onClose={navigateBack}/>}
      {isFirstPage && !isNoRows && (
        <List
          onOk={function (ids: number[]): void {
            setReelIds(ids);
            setIsFirstPage(false);
          }}
          onCancel={navigateBack}
          onNoRows={function (): void {
            setIsNoRows(true);
          }}
        />
      )}
      {!isFirstPage && (
        <Form
          onOk={navigateBack}
          onCancel={navigateBack}
          onError={navigateBack}
          selectedCableReelIds={reelIds}
        />
      )}
    </>
  );
}
