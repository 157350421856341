export default function NoRowsMessage(props: { onClose: () => void }) {
  return (
    <>
      <h1 className="border-bottom pb-2 mt-4 mb-4">Brak bębnów do zwrotu</h1>
      <div className="row my-5">
        <div className="col-sm-8 offset-sm-2">
          <div className="alert alert-warning" role="alert">
            <div className="mb-3">
              <h2>
                <i className="bi bi-exclamation-triangle-fill text-warning me-2"></i>{" "}
                Brak bębnów możliwych do zwrotu
              </h2>
            </div>
            <div>
              <p>Nie masz bębnów, które można zwrócić.</p>
              <p className="ms-5">
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.onClose();
                  }}
                  href="#"
                  className="alert-link"
                >
                  Wróć
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
