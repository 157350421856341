import { DateTime } from "luxon";
import { ReactNode } from "react";

export const date_format_dateOnly = "yyyy-MM-dd";
export const date_format_dateTime = "yyyy-MM-dd HH:mm";
export const date_format_dateTimeWithSeconds = "yyyy-MM-dd HH:mm:ss";

/** Renders a Bootstrap row for a details form with label and a date (specified as string, Date or DateTime)), formatted with the specified format, date only by default.
 * For formats see Luxon .toFormat() method.
 * The module exports date_format_dateOnly, date_format_dateTime, date_format_dateTimeWithSeconds formats.
 * No value (null or undefined) is displayed with nullElement.
 */
export function DateTimeDetailsFormRow(props: {
  /** The label to display on the left */
  label: string;
  /** The value to display, either ISO date string, like "2022-07-01T17:15:12" or a native Date object or a Luxon DateTime object. */
  value: string | Date | DateTime | null | undefined;
  /** Format to apply to the date, "yyyy-MM-dd" by default */
  format?: string;
  /** The element to replace null/undefined values, a small tag with "not specified" text by default */
  nullElement?: ReactNode;
}) {
  let nullElement = props.nullElement || <small>Nie podano</small>;
  let format = props.format || "yyyy-MM-dd";

  let r: ReactNode;

  if (props.value === null || props.value === undefined) {
    r = nullElement;
  } else {
    let dateTime: DateTime;
    if (typeof (props.value) === "string") {
      dateTime = DateTime.fromISO(props.value);
    } else if (props.value instanceof Date) {
      dateTime = DateTime.fromJSDate(props.value);
    }
    else if (props.value instanceof DateTime) {
      dateTime = props.value;
    } else {
      throw new TypeError("Unsupported value parameter type.");
    }
    const formatted = dateTime.toFormat(format);
    r = (<>{formatted}</>);
  }

  return (
    <div className="row mb-3">
      <div className="col-sm-4 -app-display-label">{props.label}</div>
      <div className="col-sm-8">{r}</div>
    </div>
  );
}



export default DateTimeDetailsFormRow;
