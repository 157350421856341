import { MouseEventHandler } from "react";

/** A standarized Edit button for the action row in the details form. */
export function EditButton(props: {
  /** onClick - when the button is clicked */
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  text?: string;
}) {
  return (
    <button
      className="btn btn-primary py-1 me-2 ps-4 pe-5" style={{marginTop: "8px"}}
      type="button"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <i className="bi bi-pencil-square ps-2 me-3"></i> {props.text || "Edytuj"}
    </button>
  );
}

export default EditButton;
