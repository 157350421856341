import React from "react";
import { loginFunction, logoutFunction } from "repositories/userRepository";

export interface LoginMethods {
    login: loginFunction;
    logout: () => Promise<void>;
    onLoggedIn: (userName: string) => void;
}

export const LoginContext = React.createContext<LoginMethods | null>(null);
