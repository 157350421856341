import HeaderLink from "./HeaderLink";
import PlainLink from "./PlainLink";

export function CableReelFragment() {
  return (
    <>
      <HeaderLink to="/CableReel/index">Zwroty bębnów</HeaderLink>

      <HeaderLink to="/CableReel/ReturnRequest/index">Zgłoszenia</HeaderLink>

      <PlainLink to="/CableReel/ReturnRequest/index">Wszystkie</PlainLink>
      <PlainLink to="/CableReel/ReturnRequest/index?isActive=true">
        Aktywne
      </PlainLink>
      <PlainLink to="/CableReel/ReturnRequest/index?isOfferRejected=true">
        Odrzucone
      </PlainLink>
      <PlainLink to="/CableReel/ReturnRequest/index?isActive=false">
        Zamknięte
      </PlainLink>

      <HeaderLink to="/CableReel/CableReel/index">Bębny</HeaderLink>
    </>
  );
}
