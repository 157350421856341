import { useNavigate, useParams, useSearchParams } from "react-router-dom";

/*
 * A compontent that wraps any other components, providing in props: id from the url, backUrl from the query and navigate(url) method
 * @param props
 * @returns
 */
export function GenericWrapperWithIdNavigateAndBackUrl(props: {
  component: any;
}) {
  let { id } = useParams();
  let [searchParams] = useSearchParams();
  let backUrl = searchParams.get("backUrl") || undefined;
  let idAsNumber: number | undefined = id !== undefined ? +id : undefined;
  let navigate = useNavigate();

  const r = props.component({
    id: idAsNumber,
    navigate: (url: string) => {
      navigate(url);
    },
    backUrl: backUrl,
  });
  return r;
}

/*
 * A compontent that wraps any other components, providing in props: id from the url, backUrl from the query, navigate(url) method and isNew property determined from the routing.
 * This is used for tree forms where you can have id in new form.
 * @param props
 * @returns
 */
export function GenericWrapperWithIdNavigateIsNewAndBackUrl(props: {
  component: any;
  isNew: boolean;
}) {
  let { id } = useParams();
  let [searchParams] = useSearchParams();
  let backUrl = searchParams.get("backUrl") || undefined;
  let idAsNumber: number | undefined = id !== undefined ? +id : undefined;
  let navigate = useNavigate();

  const r = props.component({
    id: idAsNumber,
    navigate: (url: string) => {
      navigate(url);
    },
    backUrl: backUrl,
    isNew: props.isNew
  });
  return r;
}

/*
 * A compontent that wraps any other components, providing in props: backUrl from the query and navigate(url) method
 * @param props
 * @returns
 */
export function GenericWrapperWithNavigateAndBackUrl(props: {
  component: any;
}) {
  let [searchParams] = useSearchParams();
  let backUrl = searchParams.get("backUrl") || undefined;
  let navigate = useNavigate();

  const r = props.component({
    navigate: (url: string) => {
      navigate(url);
    },
    backUrl: backUrl,
  });
  return r;
}

/*
 * A compontent that wraps any other components, providing in props: navigate(url) method
 * @param props
 * @returns
 */
export function GenericWrapperWithNavigate(props: { component: any }) {
  let navigate = useNavigate();
  const r = props.component({
    navigate: (url: string) => {
      navigate(url);
    },
  });
  return r;
}
