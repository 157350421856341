import { ValueFormatterParams } from "ag-grid-community";
import { DateTime } from "luxon";

/**
 * Displays a column with a native date (a JS date object) as date part only.
 * This is a regular ValueFormatter technique.
 * @param params
 * @returns
 */
export default function nativeDateDateOnlyColumnFormatter(
  params: ValueFormatterParams
): string {
  let r = "";

  if (params.value && params.value instanceof Date) {
    r = DateTime.fromJSDate(params.value).toFormat('yyyy-MM-dd');
  }

  return r;
}
