import httpGet from "Common/Http/httpGet";

/** Retrieves and sorts registrationBranches */
export default async function getRegistrationBranches(): Promise<GetRegistrationBranchesResult> {
  let registrationBranchConfig: RegistrationBranchConfig =
    await httpGet<RegistrationBranchConfig>(
      "/api/Account/RegistrationBranchConfig"
    );

  registrationBranchConfig.registrationBranches.sort(
    (a, b) => a.order - b.order
  );

  let r = registrationBranchConfig.registrationBranches.map<{
    value: number;
    label: string;
  }>((x) => {
    return { value: x.id, label: x.name };
  });

  return { branches: r, showSelect: registrationBranchConfig.showSelect };
}

export interface GetRegistrationBranchesResult {
  branches: {
    value: number;
    label: string;
  }[];
  showSelect: boolean;
}

interface RegistrationBranchConfig {
  showSelect: boolean;
  registrationBranches: RegistrationBranch[];
}

interface RegistrationBranch {
  id: number;
  order: number;
  name: string;
  eMail: string;
}
