import {
  Control,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { appendValidOrInvalidClassName } from "Common/EditForm/appendErrorClassNames";
import {
  maxLengthValidator,
  minLengthValidator,
  noWhitespacesOnlyValidator,
  trimmedStringValidator,
} from "../Validators/stringValidations";

export interface TextAreaProps {
  required?: boolean;
  requiredErrorMessage?: string;
  /** The maximum length, there's no maxLenght error message  */
  maxLength: number;
  /** The minimum length, there's no maxLenght error message  */
  minLength?: number;
  minLengthErrorMessage?: string;
  /** Whether to allow strings with whitespaces on start and on end */
  trimmedString?: boolean;
  trimmedStringErrorMessage?: string;
  /* no whitespaces only */
  noWhitespacesOnly?: boolean;
  noWhitespacesOnlyErrorMessage?: string;
  /** Additional class namesto add to the input, other classes remain */
  className?: string;
  placeholder?: string;
  /** When set to true, an empty string will be automatically transformed to null, so that model will be `string | null` */
  transformEmptyStringToNull?: boolean;
  rows?: number;
  disabled?: boolean;
  readOnly?: boolean;
}

export default function TextArea<T extends FieldValues>(
  props: UseControllerProps<T> & TextAreaProps
) {
  let localProps = { ...props };

  localProps.rules = { ...props.rules };
  if (!!props.required) {
    localProps.rules.required =
      props.requiredErrorMessage ?? "Pole jest wymagane.";
  }

  localProps.rules.validate = { ...props.rules?.validate };

  if (props.maxLength !== undefined) {
    localProps.rules.validate.maxLength = maxLengthValidator(props.maxLength);
  }

  if (props.minLength !== undefined) {
    localProps.rules.validate.minLenght = minLengthValidator(
      props.minLength,
      props.minLengthErrorMessage
    );
  }

  if (props.trimmedString !== undefined) {
    localProps.rules.validate.trimmedString = trimmedStringValidator(
      props.trimmedStringErrorMessage
    );
  }

  if (props.noWhitespacesOnly !== undefined) {
    localProps.rules.validate.noWhitespacesOnly = noWhitespacesOnlyValidator(
      props.noWhitespacesOnlyErrorMessage
    );
  }

  const {
    field,
    fieldState: { isTouched, error },
  } = useController(localProps);

  return (
    <textarea
      id={field.name}
      className={appendValidOrInvalidClassName(
        `form-control ${props.className ?? ""}`,
        error,
        isTouched
      )}
      maxLength={props.maxLength}
      placeholder={props.placeholder}
      rows={props.rows}
      disabled={props.disabled}
      readOnly={props.readOnly}
      onChange={(e) =>
        field.onChange(
          e.target.value === "" && props.transformEmptyStringToNull
            ? null
            : e.target.value
        )
      } // send value to hook form
      onBlur={field.onBlur} // notify when input is touched/blur
      value={field.value === null ? "" : field.value} // input value
      name={field.name} // send down the input name
      ref={field.ref} // send input ref, so we can focus on input when error appear
    ></textarea>
  );
}
