import { ReactElement, ReactNode } from "react";

/** Renders a Bootstrap row for a details form with label and any React content as the value */
export function BooleanDetailsFormRow(props: {
  /** The label to display on the left */
  label: string;
  /** The boolean value to display */
  value: boolean | undefined | null;
  /** The element to display for the true value, plain Yes text by default */
  trueElement?: ReactNode;
  /** The element to display for the false value, plain No text by default */
  falseElement?: ReactNode;
  /** The element to replace null/undefined values, a small tag with "not specified" text by default */
  nullElement?: ReactNode;
}) {
  let trueElement = props.trueElement || <>tak</>;
  let falseElement = props.falseElement || <>nie</>;
  let nullElement = props.nullElement || <small>Nie podano</small>;

  let r: ReactNode;

  if (props.value === null || props.value === undefined) {
    r = nullElement;
  } else {
    r = props.value ? trueElement : falseElement;
  }

  return (
    <div className="row mb-3">
      <div className="col-sm-4 -app-display-label">{props.label}</div>
      <div className="col-sm-8">{r}</div>
    </div>
  );
}

export default BooleanDetailsFormRow;
