// ping handling

let pingHandle: any = null;

export function startPinging() {
  pingHandle = window.setInterval(async () => {
    let response = await fetch("/api/Infrastructure/Login/WhoIAm");
    if (!response.ok) throw new Error("Response error.");
  },
    3 * 60 * 1000);
}

export function cleanupPinging() {
  if (pingHandle) window.clearTimeout(pingHandle);
}
