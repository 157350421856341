/**
 * Validator that checks whether the value matches the specified pattern.
 * @param pattern Regex to match against
 * @param message Optional custom message to display
 * @returns The error message or undefined if there's no error
 */
export function patternValidator(
  pattern: string,
  message?: string
): (value: string | null) => string | undefined {
  return (value) => {
    let errorMessage = undefined;

    if (typeof value === "string" && value != "") {
      const regex = new RegExp(pattern);
      if (!regex.test(value)) {
        errorMessage = message || `Wartość musi być wprowadzona w/g wymaganego wzoru.`;
      }
    }

    return errorMessage;
  };
}

export const digitsOnlyRegexString = "^\\d+$";
