import { motion } from "framer-motion";

export default function SaveSuccess() {
  return (
    <motion.div
      key="loading"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0 }}
      exit={{ opacity: 0, transition: { duration: 0.2 } }}
    >
      <div className="row my-5">
        <div className="col-sm-12">
          <div className="alert alert-success" role="alert">
            <div className="mb-3">
              <h2>
                <i className="bi bi-check-circle text-success me-2"></i>
                Przypomnienie hasła
              </h2>
            </div>
            <div>
              <p>
                Na podany adres e-mail wysłana została wiadomość. Kliknij na
                łącze w wiadomości aby ustawić nowe hasło.
              </p>
              <p>
                Uwaga! Jeśli jeszcze nie potwierdziłeś swojego adresu e-mail,
                nie otrzymasz wiadomości. Potwierdź adres e-mail i ponownie
                rozpocznij całą procedurę.
              </p>
              <p>Możesz teraz zamknąć okno przeglądarki.</p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
