import { useEffect, useState } from "react";
import { ComplaintItemViewModel } from "./ViewModel";
import { FormProvider, useForm } from "react-hook-form";
import getClassMetadata from "Common/classMetadataService";
import getComplaintTypes from "./getComplaintTypes";
import LoadingContentWrapper from "Common/Loading/LoadingContentWrapper";
import FormButtons from "Common/EditForm/formButtons";
import TextInputFormRow from "Common/EditForm/TextInput/TextInputFormRow";
import getTextMetadata from "Common/EditForm/ClassMetadata/getTextMetadata";
import SelectFormRow from "Common/EditForm/Select/SelectFormRow";
import DecimalInputFormRow from "Common/EditForm/DecimalInput/DecimalInputReactNumberFormatFormRow";
import getDecimalMetadata from "Common/EditForm/ClassMetadata/getDecimalMetadata";
import TextAreaSection from "Common/EditForm/TextArea/TextAreaSection";
import getTextAreaMetadata from "Common/EditForm/ClassMetadata/getTextAreaMetadata";
import Label from "Common/EditForm/Label";
import Description from "Common/EditForm/Description";
import FieldError from "Common/EditForm/FieldError";
import TextInput from "Common/EditForm/TextInput/TextInput";

export const complaintReasonId_Other = 5;
export const complaintReasonId_TransportDamage = 6;

export default function ComplaintItemForm(props: {
  values: ComplaintItemViewModel | null;
  onOk: (values: ComplaintItemViewModel) => void;
  onCancel: () => void;
}) {
  const vmDefaultValues: ComplaintItemViewModel = {
    lineCode: "",
    productName: "",
    catalogNumber: "",
    amount: null,
    complaintTypeId: null,
    comments: "",
  };

  let [isLoading, setIsLoading] = useState<boolean>(true);
  let isEditForm: boolean = !!props.values; // helper to determine if this is a new or edit form
  let [metadata, setMetadata] = useState<{ [key: string]: any }>({});
  let [complaintTypes, setComplaintTypes] = useState<
    {
      value: number;
      label: string;
    }[]
  >([]);
  let [isReasonOther, setIsReasonOther] = useState<boolean>(false);
  let [isReasonTransportDamage, setIsReasonTransportDamage] =
    useState<boolean>(false);

  const methods = useForm<ComplaintItemViewModel>({
    mode: "onTouched",
    defaultValues: vmDefaultValues,
  });

  const { handleSubmit, watch, trigger, formState, control, getValues, reset } =
    methods;

  const { isSubmitting, errors } = formState;

  const onSubmit = async (values: ComplaintItemViewModel) => {
    // let vmToSave = Object.assign({}, viewModel, values); // if values contain all the necessary data, this step will be unnecessary
    props.onOk(values);
    return;
  };

  const onInitialize = async () => {
    let retrievedComplaintTypes = await getComplaintTypes();
    let valueLabelPairs = retrievedComplaintTypes.map((x) => {
      return { value: x.id, label: x.name };
    });
    setComplaintTypes(valueLabelPairs);

    let metadata_response = await getClassMetadata("ComplaintItemVM");
    setMetadata(metadata_response);

    if (isEditForm) {
      reset(props.values!);
    } else {
      reset(vmDefaultValues);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    onInitialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // we want to trigger revalidation of catalogNumber on every change of lineCode
  // we want to show an info box when the reason is other or transport damage
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "lineCode") {
        // only if the field is touched or already submitted: this makes sense only when it is a new form, otherwise we should display an error immediatelly
        // Note: isSubmitted becomes true after the user attempts to submit, even if the validation fails; after that the validation messages are displayed and the field shoud be revalidated
        if (
          isEditForm ||
          formState.touchedFields.catalogNumber ||
          formState.isSubmitted
        ) {
          trigger("catalogNumber");
        }
      }
      if (name === "complaintTypeId") {
        setIsReasonOther(value.complaintTypeId === complaintReasonId_Other);

        if (
          isEditForm ||
          formState.touchedFields.comments ||
          formState.isSubmitted
        ) {
          trigger("comments");
        }

        setIsReasonTransportDamage(
          value.complaintTypeId === complaintReasonId_TransportDamage
        );
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, formState]);

  return (
    <>
      <div className="content-wrapper">
        <h1 className="border-bottom pb-2 mt-4 mb-5">
          {" "}
          {isEditForm
            ? "Edycja pozycji reklamacji "
            : "Nowa pozycja reklamacji"}
        </h1>
        <LoadingContentWrapper isLoading={isLoading}>
          {!isLoading && (
            <FormProvider {...methods}>
              <form noValidate>
                <div className="row mb-1">
                  {/* We have mb-1, unlike in other rows, because alert at the bottom adds its own margin. */}
                  <Label
                    label="Kod pozycji / numer katalogowy *"
                    id="lineCode"
                    isError={!!errors["lineCode"] || !!errors["catalogNumber"]}
                    description="Kod pozycji z faktury VAT lub dokumentu Wz / numer katalogowy towaru (wymagane jedno z pól)."
                  ></Label>
                  <div className="col-sm-8">
                    <div className="row">
                      <div className="col-sm-6 col-xl-5">
                        <TextInput
                          {...getTextMetadata<ComplaintItemViewModel>(
                            "lineCode",
                            metadata["lineCode"]
                          )}
                          control={control}
                          className=""
                          patternErrorMessage="Kod może zawierać wyłącznie cyfry."
                          placeholder="Kod pozycji"
                        />
                        <FieldError
                          error={
                            errors["lineCode"]?.message as
                              | string
                              | null
                              | undefined
                          }
                        ></FieldError>
                      </div>
                      <div className="col-sm-6 col-xl-5 gy-2 gy-sm-0">
                        <TextInput
                          {...getTextMetadata<ComplaintItemViewModel>(
                            "catalogNumber",
                            metadata["catalogNumber"]
                          )}
                          control={control}
                          className=""
                          placeholder="Numer katalogowy"
                          rules={{
                            validate: {
                              atLeastOneIdentifier: (v: any) => {
                                // validaton between fields - with useForm/getValues()
                                const lineCode = getValues("lineCode");
                                const lineCodeHasValue =
                                  !!lineCode && !!lineCode.trim();
                                const catalogNumberHasValue = !!v && !!v.trim();
                                return !catalogNumberHasValue &&
                                  !lineCodeHasValue
                                  ? "Jeśli nie podałeś kodu pozycji, musisz podać numer katalogowy."
                                  : undefined;
                              },
                            },
                          }}
                        />
                        <FieldError
                          error={
                            errors["catalogNumber"]?.message as
                              | string
                              | null
                              | undefined
                          }
                        ></FieldError>
                      </div>
                    </div>
                    <div className="mt-3">
                      <div
                        className="alert alert-primary d-flex align-items-center"
                        role="alert"
                      >
                        <div className="flex-shrink-0 ms-3 me-4">
                          <i className="bi bi-lightbulb"></i>
                        </div>
                        <div>
                          Musisz wypełnić przynajmniej jedno z pól powyżej:{" "}
                          <b>kod pozycji</b> lub <b>numer katalogowy</b>.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <TextInputFormRow
                  {...getTextMetadata<ComplaintItemViewModel>(
                    "productName",
                    metadata["productName"]
                  )}
                  label="Nazwa towaru"
                  longDescription="Nazwa reklamowanego towaru (niewymagane)."
                  control={control}
                  inlineDescription={undefined}
                />

                <DecimalInputFormRow
                  {...getDecimalMetadata<ComplaintItemViewModel>(
                    "amount",
                    metadata["amount"]
                  )}
                  label="Ilość do reklamacji *"
                  longDescription="Ilość wadliwych sztuk towaru (wymagane)."
                  control={control}
                  inlineDescription={undefined}
                ></DecimalInputFormRow>

                <SelectFormRow
                  {...getTextMetadata<ComplaintItemViewModel>(
                    "complaintTypeId",
                    metadata["complaintTypeId"]
                  )}
                  label="Rodzaj reklamacji *"
                  longDescription="Przyczyna reklamacji towaru (wymagane)."
                  control={control}
                  inlineDescription={undefined}
                  options={complaintTypes}
                  addNullOption={true}
                  valueIsNumber={true}
                  className="-app-medium-control"
                />

                {isReasonOther && (
                  <div className="row">
                    <div className="col-sm-8 offset-sm-4">
                      <div
                        className="alert alert-primary d-flex align-items-center"
                        role="alert"
                      >
                        <div className="flex-shrink-0 ms-3 me-4">
                          <i className="bi bi-lightbulb"></i>
                        </div>
                        <div>
                          Wybrałeś rodzaj reklamacji <b>Inne</b>. W polu{" "}
                          <b>Uwagi</b> poniżej opisz przyczynę.
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {isReasonTransportDamage && (
                  <div className="row">
                    <div className="col-sm-8 offset-sm-4">
                      <div
                        className="alert alert-primary d-flex align-items-center"
                        role="alert"
                      >
                        <div className="flex-shrink-0 ms-3 me-4">
                          <i className="bi bi-lightbulb"></i>
                        </div>
                        <div>
                          Wybrałeś rodzaj reklamacji <b>Szkoda transportowa</b>.
                          Przed wysłaniem upewnij się, że na głównej stronie
                          formularza dodałeś załącznik z protokołem szkody.
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <hr />
                <TextAreaSection
                  {...getTextAreaMetadata<ComplaintItemViewModel>(
                    "comments",
                    metadata["comments"]
                  )}
                  rows={6}
                  label="Uwagi *"
                  longDescription='Uwagi do reklamowanego towaru (wymagane tylko dla przyczyny reklamacji "Inne")'
                  control={control}
                  inlineDescription={undefined}
                  required={false}
                  rules={{
                    validate: {
                      requiredWhenReasonIsOther: (v: any) => {
                        const hasComments = !!v && !!v.trim();
                        const isReasonOtherLocal =
                          getValues("complaintTypeId") ===
                          complaintReasonId_Other;
                        return isReasonOtherLocal && !hasComments
                          ? 'Dla przyczyny reklamacji "Inne" pole jest wymagane.'
                          : undefined;
                      },
                    },
                  }}
                />

                <div className="row pt-2">
                  <div className="offset-sm-4 col-sm-8">
                    <FormButtons
                      onSave={(e) => handleSubmit(onSubmit)(e)}
                      onCancel={(e) => props.onCancel()}
                      isSubmitting={isSubmitting}
                    ></FormButtons>
                  </div>
                </div>
              </form>
            </FormProvider>
          )}
        </LoadingContentWrapper>
      </div>
    </>
  );
}
