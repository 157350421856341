import { ReactNode } from "react";

/** Renders a Bootstrap row for a details form with label and any React content as the value */
export function GenericDetailsFormRow(props: {
  /** Any React content to display as the value */
  children: ReactNode;
  /** The label to display on the left */
  label: string;
}) {
  return (
    <div className="row mb-3">
      <div className="col-sm-4 -app-display-label">{props.label}</div>
      <div className="col-sm-8">{props.children}</div>
    </div>
  );
}

export default GenericDetailsFormRow;
