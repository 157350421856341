export function getCanBeReturnedString(
  hasReturnRequest: boolean,
  daysForReturn: number | null
): string {
  let r: string = "";

  if (hasReturnRequest) {
    r = "W trakcie zwrotu";
  } else if (!!daysForReturn) {
    r = daysForReturn.toString() + " dni";
  } else {
    r = "Nie";
  }
  return r;
}
