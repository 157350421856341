import {
  Control,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

export interface SelectOption {
  value: string | number;
  label: string;
}

export interface SelectProps {
  required?: boolean;
  requiredErrorMessage?: string;
  options: SelectOption[];
  addNullOption?: boolean;
  nullOptionText?: string;
  /** Additional class names to add to the input, other classes remain */
  className?: string;
  valueIsNumber?: boolean;
  /** When set to true, an empty string will be automatically transformed to null; this option is always on when valueIsNumber is set   */
  transformEmptyStringToNull?: boolean;
  disabled?: boolean;
}

export default function Select<T extends FieldValues>(
  props: UseControllerProps<T> & SelectProps
) {
  let localProps = { ...props };

  localProps.rules = { ...props.rules };
  if (!!props.required) {
    localProps.rules.required =
      props.requiredErrorMessage ?? "Pole jest wymagane.";
  }

  localProps.rules.validate = { ...props.rules?.validate };

  let internalOptions = props.options; // no changes by default

  if (props.addNullOption) {
    const nullOptionText: string =
      props.nullOptionText ?? (props.required ? "Wybierz..." : "Brak");
    const nullOption = { label: nullOptionText, value: "" };
    internalOptions = [nullOption, ...internalOptions];
  }

  const {
    field,
    fieldState: { isTouched, error },
  } = useController(localProps);

  let className = props.className ?? "";

  return (
    <select
      className={`form-select ${className}`}
      id={field.name}
      disabled={props.disabled}
      onChange={(e) =>
        field.onChange(
          transformValue(
            e.target.value,
            !!props.valueIsNumber,
            !!props.transformEmptyStringToNull
          )
        )
      } // send value to hook form
      onBlur={field.onBlur} // notify when input is touched/blur
      value={
        field.value === null ? "" : (field.value as string | number).toString()
      } // input value
      name={field.name} // send down the input name
      ref={field.ref} // send input ref, so we can focus on input when error appear
    >
      {internalOptions.map((x) => (
        <option key={x.value} value={x.value.toString()}>
          {x.label}
        </option>
      ))}
    </select>
  );
}

function transformValue(
  value: string,
  toNumber: boolean,
  transformNull: boolean
): string | number | null {
  let r: string | number | null = value;
  if (value == "" && transformNull) r = null;
  if (toNumber) {
    if (value == "") r = null;
    else r = parseInt(value);
  }
  return r;
}
