import { Pane } from "../../Common/FrontPage/pane";
import "../../Common/FrontPage/frontPage.scss";
import "../../Common/FrontPage/frontPagePerCompany.scss";

export function Index() {
  return (
    <div className="-mko-location-front-page">
      <h1>Zwroty bębnów</h1>

      <div className="-mko-lfp-panes-container">
        <Pane link="../ReturnRequest/Index" text="Zgłoszenia zwrotu" />
        <Pane link="../ReturnRequest/New" text="Nowe zgłoszenie" />
        <Pane link="../CableReel/Index" text="Bębny" />
      </div>
    </div>
  );
}
