import React from "react";
import ReactDOM from "react-dom/client";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

import {
  RouterProvider,
} from "react-router-dom";

import { router } from "Navigation/router";

// import all global styles below

import "./styles/general.scss";
import "./styles/displayForm.scss";
import "./styles/editForm.scss";
import "./styles/itemList.scss";

import "./Common/AgGridHelpers/agGridStyles.scss";

import { localizeNumberFilterDecimalSeparator } from "Common/AgGridHelpers/localizeNumberFilterDecimalSeparator";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

localizeNumberFilterDecimalSeparator();

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
