import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link, Route, Routes } from "react-router-dom";
import "./Navbar.scss";
import { useContext } from "react";
import { CompanyContext } from "App/companyContext";
import { companyInfoMap } from "repositories/companyRepository";
import { ConfigFromServerContext } from "App/configFromServerContext";

export default function AppNavbar(props: {
  userName: string;
  onLogout: () => void;
}) {
  const companyId = useContext(CompanyContext);
  const companyInfo = companyInfoMap.get(companyId ?? 0);
  if (companyInfo === undefined) throw new Error("Config error.");
  const configFromServer = useContext(ConfigFromServerContext)!;

  return (
    <Navbar bg="light" expand="md">
      <div className="container-fluid">
        <Navbar.Brand as={Link} to="/">
          <img src={`/logo/${companyInfo.companyLogo}`} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Navbar.Text>
              <span>Biuro obsługi klienta</span>
              {configFromServer.isTestEnvironment && (
                <span
                  className="text-warning ms-2"
                  style={{ fontVariant: "small-caps" }}
                  title="Jesteś w środowisku testowym. To, co robisz w tym środowisku, nie wpływa na środowisko produkcyjne."
                >
                  (środowisko testowe)
                </span>
              )}
            </Navbar.Text>
          </Nav>

          <Nav>
            <NavDropdown
              title={
                <>
                  <i className="bi bi-person-fill"></i>
                  &nbsp;{props.userName}
                </>
              }
              id="basic-nav-dropdown"
              align="end" /* This aligns the menu to the right */
            >
              <NavDropdown.Item to="/InnerAccount/MyAccount" as={Link}>
                <i className="bi bi-person-fill me-2"></i>Moje konto
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item to="/InnerAccount/ChangePassword" as={Link}>
                <i className="bi bi-key me-2"></i>Zmień hasło
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#" onClick={props.onLogout}>
                <i className="bi bi-box-arrow-right me-2"></i>Wyloguj
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}
