import { createRef, useContext, useEffect, useState } from "react";

import { FormProvider, useForm } from "react-hook-form";
import { httpPost } from "Common/Http/httpPost";
import getClassMetadata from "Common/classMetadataService";
import LoadingContentWrapper from "Common/Loading/LoadingContentWrapper";
import TextInputFormRow from "Common/EditForm/TextInput/TextInputFormRow";
import getTextMetadata from "Common/EditForm/ClassMetadata/getTextMetadata";
import SelectFormRow from "Common/EditForm/Select/SelectFormRow";
import SaveError from "./SaveError";
import SaveSuccess from "./SaveSuccess";
import { CompanyContext } from "App/companyContext";
import { companyInfoMap } from "repositories/companyRepository";
import ReCAPTCHA from "react-google-recaptcha";
import httpGet from "Common/Http/httpGet";
import FormButtons from "Common/EditForm/formButtons";
import { useNavigate } from "react-router-dom";
import { ViewModel, viewModelNewValues } from "./viewModel";
import { httpPostNoReturn } from "Common/Http/httpPostNoReturn";

export default function ForgotPasswordForm(props: {}) {
  let [isLoading, setIsLoading] = useState<boolean>(true);
  let [isSaveError, setIsSaveError] = useState<boolean>(false);
  let [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
  let [isSaveSuccessful, setIsSaveSuccessful] = useState<boolean>(false);
  let [metadata, setMetadata] = useState<{ [key: string]: any }>({});
  let [captchaValue, setCaptchaValue] = useState<string | null>(null);
  let [captchaSiteKey, setCaptchaSiteKey] = useState<string>("");

  const companyId = useContext(CompanyContext);
  const companyInfo = companyInfoMap.get(companyId ?? 0);
  if (companyInfo === undefined) throw new Error("Config error.");

  const navigate = useNavigate();

  const methods = useForm<ViewModel>({
    mode: "onTouched",
    defaultValues: viewModelNewValues,
  });

  const { handleSubmit, formState, control, getValues, watch, trigger, reset } =
    methods;

  const { isSubmitting } = formState;

  const onSubmit = async (values: ViewModel) => {
    let vmToSave = Object.assign({}, values);

    vmToSave.captchaValue = captchaValue;

    try {
      await httpPostNoReturn<ViewModel>(
        "/api/Account/ForgotPassword",
        vmToSave
      );

      setIsSaveSuccessful(true);
    } catch (e) {
      setIsSaveError(true);
    }

    return;
  };

  const onInitialize = async () => {
    // load form values
    // fetch existing data for the form
    // load data for selects, etc.
    // this method is async so we can use await here

    let metadata_response = await getClassMetadata("ForgotPasswordVM");
    setMetadata(metadata_response);

    let captchaKey = await httpGet<string>("/api/Account/GetCaptchaSiteKey");
    setCaptchaSiteKey(captchaKey);

    let initialValues = Object.assign({}, viewModelNewValues);

    reset(initialValues);

    setIsLoading(false);
  };

  useEffect(() => {
    onInitialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCaptchaChange = (token: string | null) => {
    setCaptchaValue(token);
    setIsSaveEnabled(!!token);
  };

  return (
    <>
      <div className="container" style={{ maxWidth: 720 }}>
        <div className="content-wrapper">
          <h1 className="border-bottom pb-2 mt-4 mb-3">Przypomnienie hasła</h1>
          <LoadingContentWrapper isLoading={isLoading} isFullWidth={true}>
            {!isLoading && !isSaveError && !isSaveSuccessful && (
              <FormProvider {...methods}>
                <form noValidate>
                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <div
                        className="alert alert-primary d-flex align-items-center"
                        role="alert"
                      >
                        <div className="flex-shrink-0 ms-3 me-4">
                          <i className="bi bi-lightbulb"></i>
                        </div>
                        <div>
                          Upewnij się, że potwierdziłeś już swój adres e-mail po
                          rejestracji konta.
                          <br />
                          Upewnij się również, że wpisałeś poprawnie swój adres
                          e-mail poniżej.
                          <br />
                          Ze względu na ochronę danych użytkowników, jeśli
                          e-mail do ustawienia nowego hasła nie został wysłany z
                          powyższych powodów, nie możemy cię o tym poinformować.
                        </div>
                      </div>
                    </div>
                  </div>

                  <TextInputFormRow
                    {...getTextMetadata<ViewModel>("email", metadata["email"])}
                    label="Adres e-mail"
                    control={control}
                  />

                  <div className="row pt-3">
                    <div className="offset-sm-4 col-sm-8">
                      <ReCAPTCHA
                        sitekey={captchaSiteKey}
                        onChange={onCaptchaChange}
                      />
                    </div>
                  </div>
                  <div className="row pt-2 pb-5">
                    <div className="offset-sm-4 col-sm-8">
                      <FormButtons
                        onSave={(e) => handleSubmit(onSubmit)(e)}
                        onCancel={(e) => navigate("/")}
                        isSubmitting={isSubmitting}
                        isOkDisabled={!isSaveEnabled}
                        saveText="Wyślij"
                      ></FormButtons>
                    </div>
                  </div>
                </form>
              </FormProvider>
            )}
            {isSaveError && <SaveError />}
            {isSaveSuccessful && <SaveSuccess />}
          </LoadingContentWrapper>
        </div>
      </div>
    </>
  );
}
