import { useContext, useEffect, useRef, useState } from "react";
import { httpPost } from "Common/Http/httpPost";
import LoadingContentWrapper from "Common/Loading/LoadingContentWrapper";
import SaveError from "./SaveError";
import SaveSuccessRequireApproval from "./SaveSuccessRequireApproval";
import httpPostNoBody from "Common/Http/httpPostNoBody";
import { httpPostNoBodyWithReturn } from "Common/Http/httpPostNoBodyWithReturn";
import SaveSuccessNoApproval from "./SaveSuccessNoApproval";

export default function ConfirmEMailForm(props: {}) {
  let [isLoading, setIsLoading] = useState<boolean>(true);
  let [isSaveError, setIsSaveError] = useState<boolean>(false);
  let [isSaveSuccessfulRequireApproval, setIsSaveSuccessfulRequireApproval] = useState<boolean>(false);
  let [isSaveSuccessfulNoApproval, setIsSaveSuccessfulNoApproval] = useState<boolean>(false);

  const onInitialize = async () => {
    let params = new URL(document.location.href).searchParams;
    let userId = params.get("userId");
    let code = params.get("code");

    let confirmUrl = `/api/Account/ConfirmEmail?userId=${userId}&code=${code}`;

    try {
      let wasAccepted: boolean = await httpPostNoBodyWithReturn<boolean>(confirmUrl);
      if (wasAccepted) {
        setIsSaveSuccessfulNoApproval(true);
       }
      else {
        setIsSaveSuccessfulRequireApproval(true);
      }
    } catch (e) {
      setIsSaveError(true);
    }

    setIsLoading(false);
  };

  const effectRan = useRef(false);

  useEffect(() => {
    if (!effectRan.current) {
      effectRan.current = true;
      onInitialize();
    }
  }, []);

  return (
    <>
      <div className="container" style={{ maxWidth: 720 }}>
        <div className="content-wrapper">
          <h1 className="border-bottom pb-2 mt-4 mb-5">
            Potwierdzenie adresu e-mail
          </h1>
          <LoadingContentWrapper isLoading={isLoading} isFullWidth={true}>
            {isSaveError && <SaveError />}
            {isSaveSuccessfulRequireApproval && <SaveSuccessRequireApproval />}
            {isSaveSuccessfulNoApproval && <SaveSuccessNoApproval />}
          </LoadingContentWrapper>
        </div>
      </div>
    </>
  );
}
