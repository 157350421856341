import { ComplaintItemViewModel } from "CustomerComplaint/New/ViewModel";
import getComplaintTypes from "CustomerComplaint/New/getComplaintTypes";
import { formatDecimalNumber } from "Common/DetailsForm/Presenter/Helpers/formatDecimalNumber";
import { limitedText } from "Common/DetailsForm/Presenter/Helpers/limitedText";
import { useEffect, useState } from "react";
import { complaintItemDisplayVM } from "./complaintItemDisplayVM";
import "./ComplaintItemDisplayFragment.scss";

export default function ComplaintItemDisplayFragment(props: {
  items: complaintItemDisplayVM[];
}) {
  return (
    <>
      {props.items.length > 0 && (
        <table className="table">
          <thead>
            <tr>
              <th>Poz</th>
              <th>Kod pozycji</th>
              <th>Nazwa towaru</th>
              <th>Numer katalogowy</th>
              <th className="text-center">Ilość do reklamacji</th>
              <th>Rodzaj reklamacji</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props.items.map((item, index) => (
              <>
                <tr className={rowColorClass(index)}>
                  <td className="text-end">{index + 1}</td>
                  <td>{item.lineCode}</td>
                  <td>{item.productName}</td>
                  <td>{item.catalogNumber}</td>
                  <td className="text-end">
                    {formatDecimalNumber(item.amount, 4)}
                  </td>
                  <td>{item.complaintType}</td>
                </tr>
                {item.comments && (
                  <tr className={rowColorClass(index)}>
                    <td></td>
                    <td colSpan={5}><b>Uwagi:</b> {item.comments}</td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
}

function rowColorClass(index: number) {
  return index % 2 == 0 ? "-app-row-odd" : "-app-row-even";
}
