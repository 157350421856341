import { ChangePasswordServerVM } from "./changePasswordServerVM";

export interface ViewModel {
  currentPassword: string;
  newPassword: string;
  repeatedNewPassword: string;
}

export function toServerVM(vm: ViewModel): ChangePasswordServerVM {
  let r: ChangePasswordServerVM = {
    currentPassword: vm.currentPassword,
    newPassword: vm.newPassword,
  };

  return r;
}

export const viewModelNewValues: ViewModel = {
  currentPassword: "",
  newPassword: "",
  repeatedNewPassword: "",
};
