import escapeHTML from "escape-html";

/**
 * Replaces new line characters (\r\n or \n) with `<br/>` tags. The provided text is first html-escaped by default.
 * @param s - the input text
 * @param shouldEscapeHtmlFirst - whether to escape html before conversion
 * @returns The string with `<br/>` tags
 */
export function multilineTextToHtml(
  s: string | undefined | null,
  shouldEscapeHtmlFirst: boolean = true
): string | null {
  if (s) {
    if (shouldEscapeHtmlFirst) {
      s = escapeHTML(s);
    }
    s = s.replace(/\r?\n/g, "<br/>");
  } else {
    s = null;
  }

  return s;
}
