import { ColDef, ICellRendererParams } from "ag-grid-community";
import booleanValueFormatter from "Common/AgGridHelpers/BooleanColumn/booleanColumnFormatter";
import booleanCustomMatcher from "Common/AgGridHelpers/BooleanColumn/booleanCustomMatcher";
import { isoDateColumnComparator } from "Common/AgGridHelpers/DateColumn/isoDateColumnComparator";
import isoDateDateOnlyColumnFormatter from "Common/AgGridHelpers/DateColumn/isoDateDateOnlyColumnFormatter";
import nativeDateDateOnlyColumnFormatter from "Common/AgGridHelpers/DateColumn/nativeDateDateOnlyColumnFormatter";
import { truncateString } from "Common/Helpers/truncateString";
import { remPixelSize } from "Layout/LayoutConsts";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";

const linkCellRenderer = (props: ICellRendererParams) => {
  return (
    <Link to={"./../details/" + props.data.id}>
      {props.data.returnRequestCode}
    </Link>
  );
};

const contactCellRenderer = (props: ICellRendererParams) => {
  return (
    <span title={props.data.contact}>
      {truncateString(props.data.contact, 40)}
    </span>
  );
};

export const defaultColDef: ColDef = {
  sortable: true,
  filter: true,
};

export const columnDefs: ColDef[] = [
  {
    headerName: "Numer",
    field: "returnRequestCode",
    cellRenderer: linkCellRenderer,
    maxWidth: 16 * remPixelSize,
    minWidth: 10 * remPixelSize,
  },
  {
    headerName: "Utworzono",
    field: "dateCreatedAsJsDateOnly", // this is a native JS date
    maxWidth: 16 * remPixelSize,
    minWidth: 12 * remPixelSize,
    valueFormatter: nativeDateDateOnlyColumnFormatter,
    cellClass: ["text-center"],
    headerClass: "-app-centered-header",
    filter: "agDateColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
      browserDatePicker: false,
      filterOptions: [
        "equals",
        "lessThan",
        "lessThanOrEqual",
        "greaterThan",
        "greaterThanOrEqual",
      ],
    },
    getQuickFilterText: (params) =>
      params.value
        ? DateTime.fromJSDate(params.value).toFormat("yyyy-MM-dd")
        : "",
    initialSort: "desc",
  },
  {
    headerName: "Status",
    field: "status",
    maxWidth: 14 * remPixelSize,
    minWidth: 20 * remPixelSize,
  },
  {
    headerName: "Data transportu",
    field: "transportDateAsJsDateOnly", // this is a native JS date
    maxWidth: 16 * remPixelSize,
    minWidth: 12 * remPixelSize,
    valueFormatter: nativeDateDateOnlyColumnFormatter,
    cellClass: ["text-center"],
    headerClass: "-app-centered-header",
    filter: "agDateColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
      browserDatePicker: false,
      filterOptions: [
        "equals",
        "lessThan",
        "lessThanOrEqual",
        "greaterThan",
        "greaterThanOrEqual",
      ],
    },
    getQuickFilterText: (params) =>
      params.value
        ? DateTime.fromJSDate(params.value).toFormat("yyyy-MM-dd")
        : "",
  },
  {
    headerName: "Data zamknięcia",
    field: "dateClosedJsDateOnly", // this is a native JS date
    maxWidth: 16 * remPixelSize,
    minWidth: 12 * remPixelSize,
    valueFormatter: nativeDateDateOnlyColumnFormatter,
    cellClass: ["text-center"],
    headerClass: "-app-centered-header",
    filter: "agDateColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
      browserDatePicker: false,
      filterOptions: [
        "equals",
        "lessThan",
        "lessThanOrEqual",
        "greaterThan",
        "greaterThanOrEqual",
      ],
    },
    getQuickFilterText: (params) =>
      params.value
        ? DateTime.fromJSDate(params.value).toFormat("yyyy-MM-dd")
        : "",
  },
  {
    headerName: "Kontakt",
    field: "contact",
    cellRenderer: contactCellRenderer,
    minWidth: 16 * remPixelSize,
  },
];
