import ChangePasswordForm from "Account/ChangePassword/ChangePasswordForm";
import ChangeAccountDataForm from "Account/ChangeAccountData/ChangeAccountDataForm";
import MyAccountDetails from "Account/MyAccount/Details";
import NoRouteErrorPage from "Navigation/no-route-error-page"

import {
  GenericWrapperWithIdNavigateAndBackUrl,
  GenericWrapperWithIdNavigateIsNewAndBackUrl,
  GenericWrapperWithNavigate,
  GenericWrapperWithNavigateAndBackUrl,
} from "../genericWrappers";

const accountRoutes = [
  {
    path: "InnerAccount",
    children: [
      {
        path: "ChangePassword",
        element: (
          <GenericWrapperWithNavigateAndBackUrl
            key={"InnerAccount/ChangePassword"}
            component={ChangePasswordForm}
          />
        ),
      },
      {
        path: "MyAccount",
        element: (
          <GenericWrapperWithNavigateAndBackUrl
            key={"InnerAccount/MyAccount"}
            component={MyAccountDetails}
          />
        ),
      },
      {
        path: "ChangeAccountData",
        element: (
          <GenericWrapperWithNavigateAndBackUrl
            key={"InnerAccount/ChangeAccountData"}
            component={ChangeAccountDataForm}
          />
        ),
      },
      {
        path: "*",
        element: (
          <NoRouteErrorPage/>
        ),
      }
    ],
  },
];

export default accountRoutes;
