import {
  Control,
  FieldValues,
  UseControllerProps,
  useFormState,
} from "react-hook-form";
import TextArea, { TextAreaProps } from "./TextArea";
import Description from "../Description";
import FieldError from "../FieldError";
import Label from "../Label";
import SimplifiedFormRowProps from "../SimplifiedFormRowProps";

/**
 * Displays a edit form section with a TextArea
 * @param props - SimplifiedFormRowProps, just id and what to display: label, inlineDescription, longDescription
 * @returns
 */
export default function TextAreaSection<T extends FieldValues>(
  props: SimplifiedFormRowProps &
    TextAreaProps &
    UseControllerProps<T> & { control: Control<T> }
) {
  const { errors, touchedFields } = useFormState({
    control: props.control,
    name: props.name,
    exact: true,
  });
  const isTouched = touchedFields[props.name];
  const error = errors[props.name];

  return (
    <div className="row mb-3">
      <label
        className={`
        form-label col-form-label ${
          !!error /* && !!isTouched */ && "text-danger"
        }`}
        htmlFor={props.id}
        title={props.longDescription}
      >
        <h2>
          {props.label}
          {!!props.longDescription && (
            <sup><i className="bi bi-question-circle -app-h2-section-label-help"></i></sup>
          )}
        </h2>
      </label>

      <TextArea {...props}></TextArea>
      <Description description={props.inlineDescription}></Description>
      <FieldError
        error={error?.message as string | null | undefined}
      ></FieldError>
    </div>
  );
}
