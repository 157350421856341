import { createRef, useContext, useEffect, useState } from "react";
import { ViewModel, serverVMToClientVM, toServerVM, viewModelNewValues } from "./viewModel";
import { FormProvider, useForm } from "react-hook-form";
import { httpPost } from "Common/Http/httpPost";
import getClassMetadata from "Common/classMetadataService";
import LoadingContentWrapper from "Common/Loading/LoadingContentWrapper";
import TextInputFormRow from "Common/EditForm/TextInput/TextInputFormRow";
import getTextMetadata from "Common/EditForm/ClassMetadata/getTextMetadata";
import SaveSuccess from "./SaveSuccess";
import FormButtons from "Common/EditForm/formButtons";
import { useNavigate } from "react-router-dom";
import SaveError from "Common/EditForm/SaveError";
import { ChangeAccountDataServerVM } from "./changeAccountDataServerVM";
import { httpPostNoReturn } from "Common/Http/httpPostNoReturn";
import httpGet from "Common/Http/httpGet";

export default function ChangeAccountDataForm(props: {backUrl?: string}) {
  let [isLoading, setIsLoading] = useState<boolean>(true);
  let [isSaveError, setIsSaveError] = useState<boolean>(false);
  let [isSaveSuccessful, setIsSaveSuccessful] = useState<boolean>(false);
  let [metadata, setMetadata] = useState<{ [key: string]: any }>({});

  const navigate = useNavigate();

  const methods = useForm<ViewModel>({
    mode: "onTouched",
    defaultValues: viewModelNewValues,
  });

  const { handleSubmit, formState, control, getValues, watch, trigger, reset } =
    methods;

  const { isSubmitting } = formState;

  const onSubmit = async (values: ViewModel) => {
    // let vmToSave = Object.assign({}, viewModel, values); // if values contain all the necessary data, this step will be unnecessary
    let data = toServerVM(values);

    try {
      await httpPostNoReturn<ChangeAccountDataServerVM>(
        "/api/Account/ChangeAccountData",
        data
      );

      setIsSaveSuccessful(true);
    } catch (e) {
      setIsSaveError(true);
    }

    return;
  };

  const onInitialize = async () => {
    // load form values
    // fetch existing data for the form
    // load data for selects, etc.
    // this method is async so we can use await here

    let metadata_response = await getClassMetadata("ChangeAccountDataVM");
    setMetadata(metadata_response);

    let data: ChangeAccountDataServerVM = await httpGet<ChangeAccountDataServerVM>("/api/Account/GetAccountData");

    let initialValues = serverVMToClientVM(data);
    reset(initialValues);

    setIsLoading(false);
  };

  useEffect(() => {
    onInitialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <h1 className="border-bottom pb-2 mt-4 mb-5">Zmiana danych konta</h1>
        <LoadingContentWrapper isLoading={isLoading} isFullWidth={true}>
          {!isLoading && !isSaveError && !isSaveSuccessful && (
            <FormProvider {...methods}>
              <form noValidate>
                <TextInputFormRow
                  {...getTextMetadata<ViewModel>(
                    "phoneNumber",
                    metadata["phoneNumber"]
                  )}
                  className="-app-medium-control"
                  label="Numer telefonu"
                  control={control}
                />

                <input type="text" name="technical_input" style={{display: "none"}}/>

                <div className="row pt-2 pb-5">
                  <div className="offset-sm-4 col-sm-8">
                    <FormButtons
                      onSave={(e) => handleSubmit(onSubmit)(e)}
                      onCancel={(e) => navigate(props.backUrl || "/")}
                      isSubmitting={isSubmitting}
                    ></FormButtons>
                  </div>
                </div>
              </form>
            </FormProvider>
          )}
          {isSaveError && (
            <SaveError navigate={(url: string) => navigate(url)} backUrl={props.backUrl || "/"} />
          )}
          {isSaveSuccessful && <SaveSuccess />}
        </LoadingContentWrapper>
      </div>
    </>
  );
}
