import { ViewModel } from "./ViewModel";
import { userInfo } from "../../repositories/companyRepository";

export default function getViewModelNewValues(): ViewModel {
  let r: ViewModel = {
    customerRepName: "",
    customerRepEMail: userInfo.eMail,
    customerRepPhone: userInfo.phoneNumber,
    salesDocumentNumber: "",
    complaintDocumentTypeId: null,
    salesDate: null,
    complaintItems: [],
    attachments: [],
    };
    
  return r;
}
