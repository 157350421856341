import BooleanDetailsFormRow from "Common/DetailsForm/Presenter/BooleanDetailsFormRow";
import DetailsFormRow from "Common/DetailsForm/Presenter/DetailsFormRow";
import MultilineDetailsFormRow from "Common/DetailsForm/Presenter/MultilineDetailsFormRow";
import { complaintDisplayVM } from "./complaintDisplayVM";
import DateTimeDetailsFormRow, {
  date_format_dateOnly,
  date_format_dateTime,
} from "Common/DetailsForm/Presenter/DateTimeDetailsFormRow";
import ComplaintItemDisplayFragment from "./ComplaintItemDisplayFragment";
import ComplaintAttachmentDisplayFragment from "./ComplaintAttachmentDisplayFragment";

export function DetailsPresenter(props: { object: complaintDisplayVM }) {
  return (
    <>
      <div className="-app-display-section">
        <h2>Dane podstawowe</h2>
        <DateTimeDetailsFormRow
          label="Utworzono"
          value={props.object.dateCreated}
          format={date_format_dateTime}
        ></DateTimeDetailsFormRow>
        <DetailsFormRow
          label="Stan wniosku"
          value={props.object.jobRouterStatus}
        ></DetailsFormRow>
        <DetailsFormRow
          label="Imię i nazwisko"
          value={props.object.customerRepName}
        ></DetailsFormRow>
        <DetailsFormRow
          label="Adres e-mail"
          value={props.object.customerRepEMail}
        ></DetailsFormRow>
        <DetailsFormRow
          label="Numer telefonu"
          value={props.object.customerRepPhone}
        ></DetailsFormRow>
        <DetailsFormRow
          label="Rodzaj dokumentu sprzedaży"
          value={props.object.complaintDocumentType}
        ></DetailsFormRow>
        <DetailsFormRow
          label="Numer dokumentu sprzedaży"
          value={props.object.salesDocumentNumber}
        ></DetailsFormRow>
        <DateTimeDetailsFormRow
          label="Data sprzedaży"
          value={props.object.salesDate}
          format={date_format_dateOnly}
        ></DateTimeDetailsFormRow>
      </div>
      <div className="-app-display-section">
        <h2>Pozycje</h2>
        <ComplaintItemDisplayFragment
          items={props.object.complaintItems}
        ></ComplaintItemDisplayFragment>
      </div>
      {props.object.complaintAttachments.length > 0 && (
        <div className="-app-display-section">
          <h2>Załączniki</h2>
          <ComplaintAttachmentDisplayFragment
            items={props.object.complaintAttachments}
          ></ComplaintAttachmentDisplayFragment>
        </div>
      )}
    </>
  );
}

export default DetailsPresenter;
