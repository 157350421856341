import { motion } from "framer-motion";

export default function StartScreen(props: { open: boolean }) {
  return !props.open ? null : (
    <motion.div
      key="loading"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0.3 }}
      exit={{ opacity: 0, transition: { duration: 0 } }}
    >
      <div className="container">
        <div className="row my-5">
          <div className="col-sm-8 offset-sm-2">
            <div
              className="alert alert-primary d-flex align-items-center"
              role="alert"
            >
              <div
                className="spinner-border text-primary flex-shrink-0 me-3"
                role="status"
              >
                <span className="visually-hidden">Wczytywanie...</span>
              </div>
              <div>
                Aplikacja ładuje się i powinna być gotowa za kilka sekund.
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
