import { FieldPath, FieldValues } from "react-hook-form";
import getBasicMetadata, { BasicMetadata } from "./getBasicMetadata";

export interface DecimalMetadata<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends BasicMetadata<TFieldValues, TName> {
  decimalPlaces?: number;
  greaterThanOrEqual?: number;
  greaterThan?: number;
  lessThanOrEqual?: number;
  lessThan?: number;
}

export default function getDecimalMetadata<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  name: TName,
  metadata: { [key: string]: any }
): DecimalMetadata<TFieldValues, TName> {
  let props: DecimalMetadata<TFieldValues, TName> = getBasicMetadata(
    name,
    metadata
  ) as DecimalMetadata<TFieldValues, TName>;

  props.decimalPlaces = metadata.maxDecimalPlaces;
  props.greaterThan = metadata.greaterThan;
  props.greaterThanOrEqual = metadata.greaterThanOrEqual;
  props.lessThan = metadata.lessThan;
  props.lessThanOrEqual = metadata.lessThanOrEqual;

  return props as DecimalMetadata<TFieldValues, TName>;
}
