import { viewModel } from "./viewModel";

export function getViewModelNewValues(): viewModel {
  let r: viewModel = {
    transportDate: null,
    contact: "",
    location: "",
    returnSitePhoneNumber: "",
    returnSiteEMail: "",
    requestLoadService: false,
    notes: "",
  };
  return r;
}