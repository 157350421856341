import { AnimatePresence } from "framer-motion";
import { ReactNode } from "react";
import AnimatedLoadingRow from "./AnimatedLoadingRow";
import { ContentAnimation } from "./ContentAnimation";

/** Displays the actual content of a form when isLoading is false, otherwise an animated loading indicator is displayed.
 * The wrapped content must be protected against undefined references.
 */
export function LoadingContentWrapper(props: {
  children: ReactNode;
  isLoading: boolean;
  isFullWidth?: boolean;
}) {
  return (
    <AnimatePresence mode="wait">
      {props.isLoading && <AnimatedLoadingRow isFullWidth={props.isFullWidth} />}
      {!props.isLoading && (
        <ContentAnimation>{props.children}</ContentAnimation>
      )}
    </AnimatePresence>
  );
}

export default LoadingContentWrapper;
