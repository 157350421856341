import { ConfigFromServerContext } from "App/configFromServerContext";
import { useContext, useEffect, useState } from "react";
import "./Contact.scss";
import httpGet from "Common/Http/httpGet";
import LoadingContentWrapper from "Common/Loading/LoadingContentWrapper";
import BackButtonRow from "Common/DetailsForm/Fragments/BackButtonRow";
import { CompanyContext } from "App/companyContext";
import { companyInfoMap } from "repositories/companyRepository";

export function Contact(props: { navigate: (url: string) => void }) {
  const configFromServer = useContext(ConfigFromServerContext);

  const [dataObject, setDataObject] = useState<string | undefined>(undefined);

  const companyId = useContext(CompanyContext);
  const companyInfo = companyInfoMap.get(companyId ?? 0);
  if (companyInfo === undefined) throw new Error("Config error.");

  useEffect(() => {
    const setData = async () => {
      setDataObject(undefined);

      let response = await fetch(companyInfo.aboutFilePath);
      if (!response.ok) throw new Error("Response error.");
      let r = await response.text();

      setDataObject(r);
    };

    setData();
  }, []);

  return (
    <div>
      <h1>Kontakt</h1>
      <LoadingContentWrapper isLoading={!dataObject}>
        {!!dataObject && (
          <>
            <div className="-mko-white-info-pane">
              <div dangerouslySetInnerHTML={{ __html: dataObject }}></div>
              <p>
                Zobacz{" "}
                <a href={configFromServer?.privacyPolicyUrl} target="_blank">
                  politykę prywatności i ochrony danych osobowych.
                </a>
              </p>
            </div>
            <BackButtonRow
              onClick={() => {
                props.navigate("/");
              }}
            />
          </>
        )}
      </LoadingContentWrapper>
    </div>
  );
}
