import { ColDef, ICellRendererParams } from "ag-grid-community";
import booleanValueFormatter from "Common/AgGridHelpers/BooleanColumn/booleanColumnFormatter";
import booleanCustomMatcher from "Common/AgGridHelpers/BooleanColumn/booleanCustomMatcher";
import { isoDateColumnComparator } from "Common/AgGridHelpers/DateColumn/isoDateColumnComparator";
import isoDateDateOnlyColumnFormatter from "Common/AgGridHelpers/DateColumn/isoDateDateOnlyColumnFormatter";
import nativeDateDateOnlyColumnFormatter from "Common/AgGridHelpers/DateColumn/nativeDateDateOnlyColumnFormatter";
import { remPixelSize } from "Layout/LayoutConsts";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";

const linkCellRenderer = (props: ICellRendererParams) => {
  return (
    <Link to={"./../details/" + props.data.id}>
      {props.data.salesDocumentNumber}
    </Link>
  );
};

export const defaultColDef: ColDef = {
  sortable: true,
  filter: true,
};

export const columnDefs: ColDef[] = [
  {
    headerName: "Dokument",
    field: "salesDocumentNumber",
    cellRenderer: linkCellRenderer,
    minWidth: 14 * remPixelSize,
  },
  {
    headerName: "Utworzony",
    field: "dateCreatedAsDateOnly", // this is a native JS date
    maxWidth: 16 * remPixelSize,
    minWidth: 12 * remPixelSize,
    valueFormatter: nativeDateDateOnlyColumnFormatter,
    cellClass: ["text-center"],
    headerClass: "-app-centered-header",
    filter: "agDateColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
      browserDatePicker: false,
      filterOptions: [
        "equals",
        "lessThan",
        "lessThanOrEqual",
        "greaterThan",
        "greaterThanOrEqual",
      ],
    },
    getQuickFilterText: (params) =>
      params.value
        ? DateTime.fromJSDate(params.value).toFormat("yyyy-MM-dd")
        : "",
    initialSort: "desc",
  },
  {
    headerName: "Stan", field: "jobRouterStatus",
    maxWidth: 26 * remPixelSize,
    minWidth: 16 * remPixelSize,
   },
  {
    headerName: "Utworzony przez", field: "customerRepName",
    maxWidth: 26 * remPixelSize,
    minWidth: 16 * remPixelSize,
   },
];
