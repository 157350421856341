export function truncateString(str: string, nCharacters: number): string {
  if (str === null || str === undefined) return str;
  // Check if the string length is less than or equal to nCharacters
  if (str.length <= nCharacters) {
    // If so, return the string unchanged
    return str;
  } else {
    // If the string length is greater than nCharacters, truncate it and append "..."
    return str.substring(0, nCharacters) + "...";
  }
}
