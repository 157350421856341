import httpGet from "Common/Http/httpGet";
import { IdNameObject } from "../../Common/Helpers/IdNameObject";

let cachedComplaintTypes: IdNameObject[] | undefined = undefined;

/** Retrieves and caches complaint types */
export default async function getComplaintTypes(): Promise<IdNameObject[]> {
  if (cachedComplaintTypes === undefined) {
    let retrievedComplaintTypes = await httpGet<IdNameObject[]>(
      "/api/Complaints/ComplaintType"
    );
    cachedComplaintTypes = retrievedComplaintTypes;
  }

  return Promise.resolve<IdNameObject[]>(cachedComplaintTypes!);
}
