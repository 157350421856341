import { FieldPath, FieldValues } from "react-hook-form";

export interface BasicMetadata<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  id: TName;
  name: TName;
  required?: boolean;
}

export default function getBasicMetadata<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  name: TName,
  metadata: { [key: string]: any }
): BasicMetadata<TFieldValues, TName> {
  let props: { [key: string]: any } = {};
  props.name = name;
  props.id = name;
  props.required = metadata.required;

  return props as BasicMetadata<TFieldValues, TName>;
}
