import { CompanyContext } from "App/companyContext";
import { useContext } from "react";
import { companyInfoMap } from "repositories/companyRepository";
import "./TermsAndConditions.scss";
import { ConfigFromServerContext } from "App/configFromServerContext";

export default function TermsAndConditions() {
  const companyId = useContext(CompanyContext);
  const companyInfo = companyInfoMap.get(companyId ?? 0);
  if (companyInfo === undefined) throw new Error("Config error.");
  const configFromServer = useContext(ConfigFromServerContext);

  return (
    <div className="-app-footer clearfix">
      <span className="float-start">
        © Copyright 2023 {companyInfo.companyName} Wszelkie prawa zastrzeżone.
      </span>
      <span className="float-end">
        <a href={configFromServer?.termsAndConditionsUrl} target="_blank">
          Warunki korzystania z serwisu
        </a>{" "}
        |{" "}
        <a href={configFromServer?.privacyPolicyUrl} target="_blank">
          Polityka prywatności
        </a>
      </span>
    </div>
  );
}
