import { newReturnRequestServerVM } from "./newReturnRequestServerVM";
import { viewModel } from "./viewModel";

export function newReturnRequestViewModelToServerVM(
  vm: viewModel,
  selectedCableReelIds: number[]
): newReturnRequestServerVM {
  let r: newReturnRequestServerVM = {
    transportDate: vm.transportDate!,
    contact: vm.contact,
    location: vm.location,
    returnSitePhoneNumber: vm.returnSitePhoneNumber,
    returnSiteEMail: vm.returnSiteEMail,
    requestLoadService: vm.requestLoadService,
    notes: vm.notes,
    selectedCableReelIds: selectedCableReelIds,
  };

  return r;
}
