import App from "App/App";
import ErrorPage from "Navigation/error-page";
import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import StartPage from "Layout/StartPage/StartPage";
import {
  GenericWrapperWithIdNavigateAndBackUrl,
  GenericWrapperWithIdNavigateIsNewAndBackUrl,
  GenericWrapperWithNavigate,
  GenericWrapperWithNavigateAndBackUrl,
} from "./genericWrappers";
import { GenericIdWithOnBackWrapper } from "./legacyWrappers";

import customerComplaintRoutes from "./routes/customerComplaintRoutes";
import CustomerComplaintForm from "CustomerComplaint/New/CustomerComplaintForm";
import Main from "App/Main";
import { anonymousRoutes } from "./routes/anonymousRoutes";
import accountRoutes from "./routes/accountRoutes";
import NoRouteErrorPage from "Navigation/no-route-error-page"
import ComplaintIndex from "CustomerComplaint/Index/index";
import cableReelRoutes from "./routes/cableReelRoutes";
import { Index } from "StartPage";
import aboutRoutes from "./routes/aboutRoutes";

export const router = createBrowserRouter([
  {
    element: <Main />,
    errorElement: <ErrorPage />,

    children: [
      ...anonymousRoutes,
      {
        path: "*",
        element: <App isAnonymous={false} />,
        children: [
          {
            index: true,
            element: (
              <GenericWrapperWithNavigateAndBackUrl
                key={"StartPage/index"}
                component={Index}
              />
            ),
            // element: <Navigate key={"CustomerComplaint/index"} to="CustomerComplaint/index" replace />
          },

          ...customerComplaintRoutes,
          ...accountRoutes,
          ...cableReelRoutes,
          ...aboutRoutes,

          {
            path: "*",
            element: (
              <NoRouteErrorPage/>
            ),
          },
        ],
      },
    ],
  },
]);
