import { userInfo } from "./companyRepository";
import { getUserInfo } from "./companyRepository";

export interface ICredentials {
  userName: string;
  password: string;
  rememberMe: boolean;
}

export type whoIAmFunction = () => Promise<string | null>;
export type logoutFunction = () => Promise<void>;
export type loginFunction = (credentials: ICredentials) => Promise<string | null>;

/** Retuns the user name or null if the user is not logged in */
export async function whoIAm(): Promise<string | null> {
  let response = await fetch("/api/Infrastructure/Login/WhoIAm");
  if (!response.ok) throw new Error("Response error.");
  let r = await response.json();
  return r;
}

/** Logs out the current user */
export async function logout(): Promise<void> {
  let response = await fetch("/api/Infrastructure/Login/Logout", {
    method: "POST",
  });
  if (!response.ok) throw new Error("Response error.");
}

/** Logs in with the specified credentials. Returns the user name or null if the user has not been logged in. */
export async function login(credentials: ICredentials): Promise<string | null> {
  let response = await fetch("/api/Infrastructure/Login/Login", {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(credentials),
  });

  let r = null;

  try {
    if (!response.ok) return null;

    response = await fetch("/api/Infrastructure/Login/WhoIAm");

    if (!response.ok) return null;

    r = await response.json();
  } catch {
    r = null;
  }

  await getUserInfo();

  return r;
}
