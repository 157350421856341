/**
 * Performs a HTTP post, without body.
 * Returns object of `TReturn` type.
 * @param url the complete url to the web service
 */
export async function httpPostNoBodyWithReturn<TReturn>(url: string): Promise<TReturn> {
  let response = await fetch(url, {
    method: "POST",
  });
  if (!response.ok) throw new Error("Response error.");
  let r = (await response.json()) as TReturn;
  return r;
}
