export function appendValidOrInvalidClassName(
  baseClass: string,
  isError: any | undefined,
  isTouched: boolean | undefined
): string {
  if (!!isError /* && !!isTouched */) {
    baseClass += " is-invalid";
  }

  if (!isError && !!isTouched) {
    baseClass += " is-valid";
  }

  return baseClass;
}

export function appendTextDangerClassName(
  baseClass: string,
  isError: any | undefined,
  isTouched: boolean | undefined
): string {
  if (!!isError && !!isTouched) {
    baseClass += " text-danger";
  }

  return baseClass;
}
