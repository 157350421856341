import { multilineTextTransformEscapeHtml } from "Common/DetailsForm/Presenter/Helpers/multilineTextTransform";

export function limitedText(s: string, count: number): string { 
    s = s ?? "";
    
    if (s.length > count) {
        s = s.slice(0, count - 1);
        s += "...";
    }

    return s;
}