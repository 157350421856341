import { FieldError } from "react-hook-form";

export default function ErrorMessage(props: { error: FieldError | undefined }) {

    let errorNormalized = props.error || null;

    return errorNormalized && (
        <div className="text-danger mt-1 small">
          {errorNormalized?.message}
        </div>
      )
}
