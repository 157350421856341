import { AccountCreationDataServerVM } from "./accountCreationDataServerVM";

export interface ViewModel {
  email: string;
  daxCode: string;
  nip: string;
  phoneNumber: string;
  registrationBranchId: number | null;
  password: string;
  repeatedPassword: string;
  invoiceNumber: string | null,
  invoiceAmount: number | null
}

export function toServerVM(vm: ViewModel): AccountCreationDataServerVM {
  let r: AccountCreationDataServerVM = {
    email: vm.email,
    daxCode: vm.daxCode,
    nip: vm.nip,
    phoneNumber: vm.phoneNumber,
    registrationBranchId: vm.registrationBranchId,
    password: vm.password,
    invoiceNumber: vm.invoiceNumber,
    invoiceAmount: vm.invoiceAmount,
    captchaValue: null
  };

  return r;
}

export const viewModelNewValues: ViewModel = {
  email: "",
  daxCode: "",
  nip: "",
  phoneNumber: "",
  registrationBranchId: null,
  password: "",
  repeatedPassword: "",
  invoiceNumber: "",
  invoiceAmount: null
}