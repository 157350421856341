import {
  GenericWrapperWithIdNavigateAndBackUrl,
  GenericWrapperWithIdNavigateIsNewAndBackUrl,
  GenericWrapperWithNavigate,
  GenericWrapperWithNavigateAndBackUrl,
} from "../genericWrappers";
import CustomerComplaintForm from "CustomerComplaint/New/CustomerComplaintForm";
import Index from "CustomerComplaint/Index/index";
import NoRouteErrorPage from "Navigation/no-route-error-page"
import Details from "CustomerComplaint/Details/Details";

const customerComplaintRoutes = [
  {
    path: "CustomerComplaint",
    children: [
      {
        path: "index",
        element: (
          <GenericWrapperWithNavigate
            key={"CustomerComplaintForm/index"}
            component={Index}
          />
        ),
      },
      {
        path: "details/:id",
        element: (
          <GenericWrapperWithIdNavigateAndBackUrl
            key={"CustomerComplaintForm/details/:id"}
            component={Details}
          />
        ),
      },
      // {
      //   path: "edit/:id",
      //   element: (
      //     <GenericWrapperWithIdNavigateAndBackUrl
      //       key={"AttachmentEdit/edit/:id"}
      //       component={AttachmentEdit}
      //     />
      //   ),
      // },
      {
        path: "new",
        element: (
          <GenericWrapperWithNavigateAndBackUrl
            key={"CustomerComplaint/new"}
            component={CustomerComplaintForm}
          />
        ),
      },
      {
        path: "*",
        element: (
          <NoRouteErrorPage/>
        ),
      },
    ],
  },
];

export default customerComplaintRoutes;
