import React, { forwardRef, Ref } from 'react';

interface VhMeasureDivProps {
  // Add any other props you need
}

const VhMeasureDiv = forwardRef<HTMLDivElement, VhMeasureDivProps>(
  (props, ref) => {
    return (
      <div
        ref={ref}
        style={{
          position: 'fixed',
          height: '100vh',
          width: 0,
          // visibility: 'hidden',
        }}
      ></div>
    );
  }
);

export default VhMeasureDiv;
