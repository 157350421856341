import parseDecimalNumber from "parse-decimal-number";
import { roundTo } from "round-to";

export const separators = " ,";

// Validators

/**
 * Validator that checks whether the value is a valid number in the locale.
 * @param message Optional custom message to display
 * @returns The error message or undefined if there's no error
 */
export function numberValidator(
  message: string
): (value: number | null) => string | undefined {
  return (value) => {
    let r = undefined;

    if (value !== null && isNaN(value)) {
      r = message || "Wartość musi być liczbą.";
    }

    return r;
  };
}

/**
 * Validator that enforces the maximum number of the decimal places
 * @param decimalPlaces How many decimal places are allowed
 * @param message Optional custom message to display
 * @returns The error message or undefined if there's no error
 */
export function decimalPlacesValidator(
  decimalPlaces: number,
  message?: string
): (value: number | null) => string | undefined {
  return (value) => {
    let r: string | undefined = undefined;

    if (value !== null) {
      if (isNaN(value) === false) {
        let rounded = roundTo(value, decimalPlaces);
        if (rounded !== value) {
          r =
            message ||
            `Możesz podać najwyżej ${decimalPlaces} miejsca po przecinku.`;
        }
      }
    }

    return r;
  };
}

/**
 * Validator that enforces the minimum value, exclusive.
 * @param minValue The minimum value, exclusive.
 * @param message Optional custom message to display
 * @returns The error message or undefined if there's no error
 */
export function greaterThanValidator(
  minValue: number,
  message?: string
): (value: number | null) => string | undefined {
  return (value) => {
    let r: string | undefined = undefined;

    if (value !== null && value <= minValue) {
      r =
        message ||
        `Wartość musi być większa od ${plainNumberToString(minValue)}.`;
    }
    return r;
  };
}

/**
 * Validator that enforces the minimum value, inclusive.
 * @param minValue The minimum value, inclusive.
 * @param message Optional custom message to display
 * @returns The error message or undefined if there's no error
 */
export function greaterThanOrEqualValidator(
  minValue: number,
  message?: string
): (value: number | null) => string | undefined {
  return (value) => {
    let r: string | undefined = undefined;

    if (value !== null && value < minValue) {
      r =
        message ||
        `Wartość musi być większa lub równa ${plainNumberToString(minValue)}.`;
    }
    return r;
  };
}

/**
 * Validator that enforces the maximum value, exclusive.
 * @param maxValue The minimum value, exclusive.
 * @param message Optional custom message to display
 * @returns The error message or undefined if there's no error
 */
export function lessThanValidator(
  maxValue: number,
  message?: string
): (value: number | null) => string | undefined {
  return (value) => {
    let r: string | undefined = undefined;

    if (value !== null && value >= maxValue) {
      r =
        message ||
        `Wartość musi być mniejsza od ${plainNumberToString(maxValue)}.`;
    }
    return r;
  };
}

/**
 * Validator that enforces the maximum value, inclusive.
 * @param maxValue The minimum value, inclusive.
 * @param message Optional custom message to display
 * @returns The error message or undefined if there's no error
 */
export function lessThanOrEqualValidator(
  maxValue: number,
  message?: string
): (value: number | null) => string | undefined {
  return (value) => {
    let r: string | undefined = undefined;

    if (value !== null && value > maxValue) {
      r =
        message ||
        `Wartość musi być mniejsza lub równa ${plainNumberToString(maxValue)}.`;
    }
    return r;
  };
}

function plainNumberToString(v: number) {
  return v.toString().replace(".", separators[1]);
}
