import { motion } from "framer-motion";

export default function SaveSuccess(props: { done: () => void }) {
  return (
    <motion.div
      key="loading"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0 }}
      exit={{ opacity: 0, transition: { duration: 0.2 } }}
    >
      <div className="row my-5">
        <div className="col-sm-8 offset-sm-2">
          <div className="alert alert-success" role="alert">
            <div className="mb-3">
              <h2>
                <i className="bi bi-check-circle text-success me-2"></i>{" "}
                Formularz został wysłany
              </h2>
            </div>
            <div>
              <p>Formularz został został pomyśnie wysłany.</p>
              <p>
                Kliknij łącze poniżej aby przejść do listy reklamacji.
              </p>
              <p className="ms-5">
                <a
                  onClick={(e) => {
                    props.done();
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  href="#"
                  className="alert-link"
                >
                  Lista reklamacji
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
