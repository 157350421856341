export function validateDocumentNumber(
  value: any,
  companyId: number | null,
  documentTypeId: number | null
): string | undefined {
  if (!value) return undefined;
  // no validation till the document type and company is available
  if (documentTypeId === null) return undefined;
  if (companyId === null) return undefined;

  const regexString = getDocumentNumberValidationRegex(
    companyId,
    documentTypeId
  );
  const regex = new RegExp(regexString);
  if (!regex.test(value)) {
    return getDocumentNumberErrorMessage(companyId, documentTypeId);
  } else {
    return undefined;
  }
}

const regexesForCompaniesAndDocumentTypes: [string, string][] = [
  ["1,1", "^FV3.+"],
  ["1,2", "^WZ3.+"],
  ["2,1", "^FV2.+"],
  ["2,2", "^WZ2.+"],
  ["3,1", "^FV4.+"],
  ["3,2", "^WZ4.+"],
];
const regexMap = new Map(regexesForCompaniesAndDocumentTypes);

function getDocumentNumberValidationRegex(
  companyId: number,
  documentTypeId: number
): string {
  const key: string = `${companyId},${documentTypeId}`;
  let r: string | undefined = regexMap.get(key);
  if (r === undefined) throw new Error(`The key ${key} is not available.`);
  return r;
}

const errorMessagesForCompaniesAndDocumentTypes: [string, string][] = [
  ["1,1", "FV3"],
  ["1,2", "WZ3"],
  ["2,1", "FV2"],
  ["2,2", "WZ2"],
  ["3,1", "FV4"],
  ["3,2", "WZ4"],
];
const errorMessageMap = new Map(errorMessagesForCompaniesAndDocumentTypes);

function getDocumentNumberErrorMessage(
  companyId: number,
  documentTypeId: number
): string {
  const key: string = `${companyId},${documentTypeId}`;
  let r: string | undefined = errorMessageMap.get(key);
  if (r === undefined) throw new Error(`The key ${key} is not available.`);
  r = `Numer dokumentu musi zaczynać się od ${r}.`;
  return r;
}
