import { DateTime } from "luxon";

/**
 * Validator that enforces the maximum date value, inclusive.
 * @param maxDate The maximum date value, inclusive.
 * @param message Optional custom message to display.
 * @returns The error message or undefined if there's no error.
 */
export function maxDateValidator(
  maxDate: Date,
  message?: string
): (value: Date | null) => string | undefined {
  return (value) => {
    let errorMessage: string | undefined = undefined;

    if (value !== null && value > maxDate) {
      const dateString: string =
        DateTime.fromJSDate(maxDate).toFormat("yyyy-MM-dd");
      errorMessage = message || `Data musi wypadać najpóźniej w ${dateString}.`;
    }

    return errorMessage;
  };
}

export function minDateValidator(
  minDate: Date,
  message?: string
): (value: Date | null) => string | undefined {
  return (value) => {
    let error = undefined;

    if (value !== null && value < minDate) {
      const dateString: string =
        DateTime.fromJSDate(minDate).toFormat("yyyy-MM-dd");
      error = message || `Data musi wypadać najwcześniej w ${dateString}.`;
    }

    return error;
  };
}
