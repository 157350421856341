/**
 * Performs a simple HTTP get and returns an object of `T` type
 * @param url url to the web service without trailing slash and id
 * @param id id of the object, if not specified, the id is not appended to url
 * @returns
 */
export async function httpGet<T>(url: string, id?: number): Promise<T> {
  let effectiveUrl = id ? url + "/" + id.toString() : url;
  let response = await fetch(effectiveUrl);
  if (!response.ok) throw new Error("Response error.");
  let r = (await response.json()) as T;
  return r;
}

export default httpGet;
