import postBlob from "./postBlob";

const defaultChunkSize = 1 * 1024 * 1024;

/**
* Performs a series of POST request with the specified blob divided into chungs
 * @param uri The complete URI
 * @param file The blob to send
 * @param progressCallback The callback that is assigned to upload.onprogress
 * @param chunkSize - the size of a chunk send in a single post, 1MB by default
 */
export async function postBloblInChunks(
  uri: string,
  file: Blob,
  progressCallback?: (percentageCompleted: number) => void,
  chunkSize: number = defaultChunkSize
) {
  let position: number = 0;
  let length: number = file.size;

  if (progressCallback) progressCallback(0);

  let isFinal: boolean = false;
  do {
    let currentChunkSize = chunkSize;

    if (position + currentChunkSize > length) {
      currentChunkSize = length - position;
    }

    let chunk = file.slice(
      position,
      position + currentChunkSize,
      "application/octet-stream"
    );

    position += currentChunkSize;
    isFinal = position >= length;

    if (progressCallback) progressCallback((position * 100) / length);

    await postBlob(uri, chunk);
  } while (isFinal === false);
}

export default postBloblInChunks;
