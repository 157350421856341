import { AlertDialog } from "Common/Dialog/AlertDialog";
import ErrorMessage from "Common/EditForm/ErrorMessage";
import TextInput from "Common/EditForm/TextInput/TextInput";
import httpPostNoBody from "Common/Http/httpPostNoBody";
import postBloblInChunks from "Common/Http/postBloblInChunks";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import "./attachmentGrid.scss";
import getClassMetadata from "Common/classMetadataService";
import getTextMetadata from "Common/EditForm/ClassMetadata/getTextMetadata";
import { AttachmentViewModel } from "CustomerComplaint/New/ViewModel";

const maxAttachmentNameLength = 49;
const maxAttachmentCount = 5;

export function AttachmentUpload(props: {
  parent: string;
  isTransportDamageAttachmentRequired: boolean;
}) {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<any>();

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: props.parent,
    rules: {},
  });

  let [uploadProgress, setUploadProgress] = useState<number>(0);
  let [isAttachmentBeingUploaded, setIsAttachmentBeingUploaded] =
    useState<boolean>(false);
  const isAddAttachmentEnabled =
    isAttachmentBeingUploaded == false && fields.length < maxAttachmentCount;
  let [metadata, setMetadata] = useState<{ [key: string]: any }>({});
  let [isLoading, setIsLoading] = useState<boolean>(true);

  const onInitialize = async () => {
    let metadata_response = await getClassMetadata("ComplaintAttachmentVM");
    setMetadata(metadata_response);
    setIsLoading(false);
  };

  useEffect(() => {
    onInitialize();
  }, []);

  const attachmentRemoved = async (index: number, fileId: string) => {
    remove(index);
    await httpPostNoBody(`/api/Complaints/AttachmentUpload/removeUploadedFile/${fileId}`);
    // even if removing the attachment fails, we don't have to inform the user about it; the uploaded file will be eventually removed by the server
  };

  const fileSelected: React.ChangeEventHandler<HTMLInputElement> = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!isAddAttachmentEnabled || isAttachmentBeingUploaded) return;

    if (!e.target.files || e.target.files.length === 0) return;

    let file = e.target.files[0];

    if (file.size > 20 * 1024 * 1024) {
      await AlertDialog(
        "Zbyt duży plik",
        "Wybrany plik jest zbyt duży i nie można go przesłać."
      );
      e.target.value = "";
      return;
    }

    setIsAttachmentBeingUploaded(true);
    setUploadProgress(0);

    let id = uuidv4(); // this is the server file name of the attachment

    try {
      e.target.disabled = true;

      /*
        // Posting a file in single upload
        let progress = (event: ProgressEvent<EventTarget>) => {
          if (event.lengthComputable) {
            setUploadProgress((event.loaded * 100) / event.total);
          }
        };
  
        let response = await postData("/api/Complaints/AttachmentUpload/upload/" + id, file, progress);
        */

      // Posting a file in chunks
      // note that the progress is different

      let progress = (percentageCompleted: number) => {
        setUploadProgress(percentageCompleted);
      };

      await postBloblInChunks(
        "/api/Complaints/AttachmentUpload/uploadChunk/" + id,
        file,
        progress
      );

      let fileNameWithoutExtension =
        file.name.split(".").slice(0, -1).join(".") || file.name;

      // export it to a const, use in max length
      fileNameWithoutExtension = fileNameWithoutExtension.slice(
        0,
        maxAttachmentNameLength - 1
      );

      append({
        id: id,
        name: fileNameWithoutExtension,
        originalFileName: file.name,
      });
    } catch (e) {
      await AlertDialog(
        "Nie udało się przesłać",
        "Nie udało się przesłać tego pliku. Jeżeli problem będzie się powtarzał, skontaktuj się z pomocą techniczną."
      );
    } finally {
      e.target.disabled = false;
    }

    setIsAttachmentBeingUploaded(false);
    setUploadProgress(0);
    e.target.value = "";
  };

  if (isLoading) return null;

  return (
    <>
      <AnimatePresence mode="wait">
        {fields.length === 0 && (
          <motion.div
            key="alert"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0, transition: { duration: 0.3 } }}
          >
            <div className="row mb-3">
              <div className="col-sm-12">
                <div
                  className="alert alert-primary d-flex align-items-center"
                  role="alert"
                >
                  <div className="flex-shrink-0 ms-3 me-4">
                    <i className="bi bi-lightbulb"></i>
                  </div>
                  <div>
                    Do zgłoszenia możesz dodać do 15 załączników.
                    <br />
                    Maksymalny rozmiar jednego załącznika to 20MB. Dopuszczalne
                    są wyłącznie pliki JPEG i PDF.
                    <br />
                    Możesz podać własny opis dla każdego z załączników,
                    domyślnie będzie to nazwa pliku.
                  </div>
                </div>
              </div>
            </div>

            {fields.length === 0 && props.isTransportDamageAttachmentRequired && (
              <div className="row mb-3">
                <div className="col-sm-12">
                  <div
                    className="alert alert-warning d-flex align-items-center"
                    role="alert"
                  >
                    <div className="flex-shrink-0 ms-3 me-4">
                      <i className="bi bi-exclamation-triangle-fill"></i>
                    </div>
                    <div>
                      Wybrałeś rodzaj reklamacji <b>Szkoda transportowa</b>.
                      Dodaj załącznik z protokołem szkody.
                    </div>
                  </div>
                </div>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence mode="wait">
        <div className="multipleAttachmentSelect">
          {fields.map((item, index) => {
            return (
              <motion.div
                key={item.id}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                exit={{ opacity: 0, transition: { duration: 0.3 } }}
                className="attachmentRow"
                style={{ display: "contents" }}
              >
                <div className="attachmentNumber"> {index + 1}.</div>
                <div className="attachmentName">
                  <TextInput
                    {...getTextMetadata<any>(
                      `${props.parent}.${index}.name`,
                      metadata["attachmentName"]
                    )}
                    control={control}
                    placeholder="Opis załącznika"
                  ></TextInput>
                  <ErrorMessage
                    error={(errors as any)[props.parent]?.[index]?.name}
                  ></ErrorMessage>
                </div>
                <div className="attachmentRemove">
                  <button
                    type="button"
                    className="btn py-1 ps-4 pe-5 btn-danger"
                    onClick={() => {
                      attachmentRemoved(index, item.id);
                    }}
                  >
                    <i className="bi bi-trash3 ps-2 me-2"></i>
                    Usuń
                  </button>
                </div>
              </motion.div>
            );
          })}

          <div></div>

          <div className="progressBar">
            <AnimatePresence mode="wait">
              {isAttachmentBeingUploaded && (
                <motion.div
                  key="progress"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3, delay: 0.1 }}
                  exit={{ opacity: 0, transition: { duration: 0.2 } }}
                >
                  <ProgressBar
                    striped
                    variant={uploadProgress >= 100 ? "success" : "info"}
                    now={uploadProgress}
                  />
                </motion.div>
              )}
              {!isAttachmentBeingUploaded && (
                <motion.div
                  key="info"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3, delay: 0.2 }}
                  exit={{ opacity: 0, transition: { duration: 0.1 } }}
                >
                  {isAddAttachmentEnabled ? (
                    <span>Kliknij obok aby dodać nowy załącznik...</span>
                  ) : (
                    <span>Nie można dodać więcej załączników.</span>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <div className="attachmentAdd">
            <input
              style={{ display: "none" }}
              id="selectFile"
              type="file"
              accept="application/pdf, image/jpeg"
              disabled={!isAddAttachmentEnabled}
              onChange={(e) => {
                fileSelected(e);
              }}
            />
            <label htmlFor="selectFile">
              <span
                onClick={(e) => {
                  if (!isAddAttachmentEnabled) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                // note: below we force pe-auto (enable pointer events) so that we can cancel a click
                className={`btn btn-success py-1 ps-4 pe-5 pe-auto ${
                  isAddAttachmentEnabled ? "" : "disabled"
                }`}
              >
                <i className="bi bi-plus-circle ps-2 me-2"></i> Dodaj
              </span>
            </label>
          </div>
        </div>
      </AnimatePresence>
    </>
  );
}
