export default function FormButtons(props: {
  onSave: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCancel: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isSubmitting: boolean;
  saveText?: string;
  isOkDisabled?: boolean;
  okButtonIconClass?: string;
}) {
  let saveText = props.saveText ?? "Zapisz";
  let okButtonIconClass = props.okButtonIconClass ?? "bi-save";
  return (
    <>
      <button
        type="button"
        name="save"
        className="btn btn-primary me-5 mt-4 ps-4 pe-5"
        onClick={(e) => props.onSave(e)}
        disabled={!!props.isOkDisabled || props.isSubmitting}
      >
        <span style={{ minWidth: "3em", display: "inline-block" }}>
          <i
            className={`bi ${okButtonIconClass} ps-2 me-3 ${
              props.isSubmitting ? "d-none" : ""
            }`}
          ></i>
          <span
            className={`spinner-border spinner-border-sm ps-2 me-3 ${
              !props.isSubmitting ? "d-none" : ""
            }`}
            role="status"
          ></span>
        </span>
        {saveText}
      </button>
      <button
        type="button"
        name="cancel"
        className="btn btn-secondary mt-4 ps-4 pe-5"
        onClick={(e) => props.onCancel(e)}
        disabled={props.isSubmitting}
      >
        <span style={{ minWidth: "3em", display: "inline-block" }}>
          <i className={`bi bi-x-octagon ps-2 me-3`}></i>
        </span>
        Anuluj
      </button>
    </>
  );
}
