import React, { createContext, useState, useContext, ReactNode } from "react";

import "./BackgroundOperationContext.scss";
export interface UseBackgroundOperationMethods {
  startOperation: () => void;
  stopOperation: () => void;
}

const notInitializedErrorMessage = "BackgroundOperationProvider is not set up.";

/**
 * Dummy methods to avoid undefined in hook.
 */
const dummyUseBackgroundOperationMethods: UseBackgroundOperationMethods = {
  startOperation: () => {
    console.log(notInitializedErrorMessage);
  },
  stopOperation: () => {
    console.log(notInitializedErrorMessage);
  },
};

export const BackgroundOperationContext =
  createContext<UseBackgroundOperationMethods>(
    dummyUseBackgroundOperationMethods
  );

export function useBackgroundOperation() {
  return useContext(BackgroundOperationContext);
}

// preOpening is just when the elemenent is added to DOM
type backdropState = "preOpening" | "opening" | "quickOpening" | "open" | "closing" | "closed";


// within this time the backdrop transition must close it or the backdrop will be closed forcefully
const maxBackdropCloseDuration = 201;

export function BackgroundOperationProvider(props: {
  children: ReactNode;
  zIndex?: number;
  message?: string;
}) {
  const defaultBackdrop = {
    zIndex: props.zIndex || 10000,
  };

  const message = props.message || "Trwa operacja, proszę czekać...";

  const [state, setState] = useState<backdropState>("closed");

  const displayBackdrop = () => {
    setState("preOpening"); // transitions aren't run when the element is added to DOM, so that we have the first state to fix display
    window.setTimeout(() => { 
      setState("opening"); // and now we can run our transition
    }, 1);
  };

  const closeBackdrop = () => {
    setState("closing");
    window.setTimeout(() => { 
      setState("closed"); // if the transition didn't run, we close forcibly
    }, maxBackdropCloseDuration);
  };

  const onTransitionEnd = (e: React.TransitionEvent<HTMLDivElement>) => {
    const element = e.target as HTMLElement;

    if (state === "opening") {
      setState("open");
    }

    if (state === "quickOpening") {
      setState("open");
    }

    if (state === "closing") {
      setState("closed");
    }

    e.stopPropagation();
  };

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (state === "opening") {
      setState("quickOpening");
    }
  };

  return (
    <BackgroundOperationContext.Provider
      value={{
        startOperation: displayBackdrop,
        stopOperation: closeBackdrop,
      }}
    >
      {props.children}
      {state !== "closed" && (
        <div
          style={defaultBackdrop}
          className={`-app-backgroundOperation-backdrop ${state}`}
          onTransitionEnd={onTransitionEnd}
          onClick={onClick}
        >
          <h1>{message}</h1>
        </div>
      )}
    </BackgroundOperationContext.Provider>
  );
}
