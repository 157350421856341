import { useState } from "react";
import "./CookieConsent.scss";

export default function CookieConsent() {
  let [hasCookieConsent, setHasCookieConsent] = useState<boolean>(
    getCookieConsentValueInLocalStorage()
  );

  if (hasCookieConsent) {
    return null;
  }

  const onAcceptCookie = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    setCookieConsentInLocalStorage();
    setHasCookieConsent(true);
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className="-app-cookie-consent">
      Ta strona używa ciasteczek (cookies), dzięki którym może działać.
      Korzystając ze strony zgadzasz się na zapisywanie prywatnych danych
      zawartych w plikach cookie i podobnych technologiach.
      <a
        className="btn btn-success"
        href="#"
        onClick={(e) => onAcceptCookie(e)}
      >
        Rozumiem
      </a>
    </div>
  );
}

const cookieConsentLocalStorageKey =
  "1BDFAFEA-3DF0-48CF-9BCB-8DD8B5BE5707-cookieconsent";

function getCookieConsentValueInLocalStorage(): boolean {
  return !!localStorage.getItem(cookieConsentLocalStorageKey);
}

function setCookieConsentInLocalStorage() {
  localStorage.setItem(cookieConsentLocalStorageKey, "accepted");
}
