export default function SaveErrorWithOnClose(props: {
  onClose: () => void;
}) {
  return (
    <div className="row my-5">
      <div className="col-sm-8 offset-sm-2">
        <div className="alert alert-danger" role="alert">
          <div className="mb-3">
            <h2>
              <i className="bi bi-exclamation-octagon-fill text-danger me-2"></i>{" "}
              Nie udało się zapisać formularza
            </h2>
          </div>
          <div>
            <p>W trakcie zapisywania formularza wystąpił błąd.</p>
            <p>
              Jeśli błąd będzie się powtarzał, skontaktuj się z administratorem
              systemu.
            </p>
            <p className="ms-5">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.onClose();
                }}
                href="#"
                className="alert-link"
              >
                Wróć
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
