import { Link } from "react-router-dom";

/** Renders a link with the specified content in h4 */
export function HeaderLink(props: { to: string; children: React.ReactNode }) {
  return (
    <Link to={props.to} className="list-group-item -mko-menu-header ">
      <h4>{props.children}</h4>
    </Link>
  );
}

export default HeaderLink;
