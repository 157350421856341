import BackButtonRow from "Common/DetailsForm/Fragments/BackButtonRow";
import EditButton from "Common/DetailsForm/Fragments/EditButton";
import RemoveButton from "Common/DetailsForm/Fragments/RemoveButton";
import { AlertDialog } from "Common/Dialog/AlertDialog";
import { ConfirmDialog } from "Common/Dialog/ConfirmDialog";
import httpDelete from "Common/Http/httpDelete";
import { httpGet } from "Common/Http/httpGet";
import LoadingContentWrapper from "Common/Loading/LoadingContentWrapper";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DetailsPresenter from "./DetailsPresenter";
import { cableReelDisplayVM } from "./cableReelDisplayVM";


export default function Details(props: {
  id: number;
  navigate: (url: string) => void;
  backUrl?: string;
}) {
  let backUrl = props.backUrl || "./../../index";

  const [dataObject, setDataObject] =
    useState<cableReelDisplayVM | undefined>(undefined);

  const [isRemoving, setIsRemoving] = useState<boolean>(false);

  useEffect(() => {
    const setData = async () => {
      setDataObject(undefined);
      let a = await httpGet<cableReelDisplayVM>(
        "/api/CableReels/CableReel/GetCableReel",
        props.id
      );
      setDataObject(a);
    };

    setData();
  }, [props.id]);

  return (
    <>
      <h1 className="border-bottom pb-2 mt-4 mb-4">
        Karta bębna{" "}
        <b>{dataObject?.reelId}</b>
      </h1>
      <LoadingContentWrapper isLoading={!dataObject}>
        {!!dataObject && (
          <>
            <DetailsPresenter
              object={dataObject!}
            ></DetailsPresenter>
            <BackButtonRow
              onClick={() => {
                props.navigate(backUrl);
              }}
              disabled={isRemoving}
            />
          </>
        )}
      </LoadingContentWrapper>
    </>
  );
}
