import { Index } from "CableReel/StartPage";
import CableReelIndex from "CableReel/CableReel/Index/index";
import CableReelDetails from "CableReel/CableReel/Details/Details";
import ReturnRequestIndex from "CableReel/ReturnRequest/Index/index";
import ReturnRequestDetails from "CableReel/ReturnRequest/Details/Details";
import {
  GenericWrapperWithIdNavigateAndBackUrl,
  GenericWrapperWithIdNavigateIsNewAndBackUrl,
  GenericWrapperWithNavigate,
  GenericWrapperWithNavigateAndBackUrl,
} from "../genericWrappers";
import NoRouteErrorPage from "Navigation/no-route-error-page";
import NewReturnRequest from "CableReel/ReturnRequest/New/NewReturnRequest";

const cableReelRoutes = [
  {
    path: "CableReel",
    children: [
      {
        path: "index",
        element: (
          <GenericWrapperWithNavigate
            key={"CableReel/index"}
            component={Index}
          />
        ),
      },
      {
        path: "CableReel",
        children: [
          {
            path: "index",
            element: (
              <GenericWrapperWithNavigate
                key={"CableReel/CableReel/index"}
                component={CableReelIndex}
              />
            ),
          },
          {
            path: "details/:id",
            element: (
              <GenericWrapperWithIdNavigateAndBackUrl
                key={"CableReel/CableReel/details/:id"}
                component={CableReelDetails}
              />
            ),
          },
        ],
      },
      {
        path: "ReturnRequest",
        children: [
          {
            path: "index",
            element: (
              <GenericWrapperWithNavigate
                key={"CableReel/ReturnRequest/index"}
                component={ReturnRequestIndex}
              />
            ),
          },
          {
            path: "details/:id",
            element: (
              <GenericWrapperWithIdNavigateAndBackUrl
                key={"CableReel/ReturnRequest/details/:id"}
                component={ReturnRequestDetails}
              />
            ),
          },
          {
            path: "new",
            element: (
              <GenericWrapperWithNavigateAndBackUrl
                key={"CableReel/ReturnRequest/new"}
                component={NewReturnRequest}
              />
            ),
          },
        ],
      },
      // {
      //   path: "details/:id",
      //   element: (
      //     <GenericWrapperWithIdNavigateAndBackUrl
      //       key={"CustomerComplaintForm/details/:id"}
      //       component={Details}
      //     />
      //   ),
      // },
      // {
      //   path: "edit/:id",
      //   element: (
      //     <GenericWrapperWithIdNavigateAndBackUrl
      //       key={"AttachmentEdit/edit/:id"}
      //       component={AttachmentEdit}
      //     />
      //   ),
      // },
      // {
      //   path: "new",
      //   element: (
      //     <GenericWrapperWithNavigateAndBackUrl
      //       key={"CustomerComplaint/new"}
      //       component={CustomerComplaintForm}
      //     />
      //   ),
      // },
      {
        path: "*",
        element: <NoRouteErrorPage />,
      },
    ],
  },
];

export default cableReelRoutes;
