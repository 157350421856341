import BooleanDetailsFormRow from "Common/DetailsForm/Presenter/BooleanDetailsFormRow";
import DetailsFormRow from "Common/DetailsForm/Presenter/DetailsFormRow";
import MultilineDetailsFormRow from "Common/DetailsForm/Presenter/MultilineDetailsFormRow";
import DateTimeDetailsFormRow, {
  date_format_dateOnly,
  date_format_dateTime,
} from "Common/DetailsForm/Presenter/DateTimeDetailsFormRow";
import { returnRequestDisplayVM } from "./returnRequestDisplayVM";
import DescriptionSection from "Common/DetailsForm/Presenter/DescriptionSection";
import ReturnRequestCableReelDisplayFragment from "./ReturnRequestCableReelDisplayFragment";
import NumberDetailsFormRow from "Common/DetailsForm/Presenter/NumberDetailsFormRow";

export function DetailsPresenter(props: { object: returnRequestDisplayVM }) {
  return (
    <>
      <div className="-app-display-section">
        <h2>Dane podstawowe</h2>
        <DetailsFormRow
          label="Numer zgłoszenia"
          value={props.object.returnRequestCode}
        ></DetailsFormRow>
        <DateTimeDetailsFormRow
          label="Data utworzenia"
          value={props.object.dateCreated}
        ></DateTimeDetailsFormRow>
        <DetailsFormRow
          label="Numer klienta"
          value={props.object.clientId}
        ></DetailsFormRow>
        <DetailsFormRow
          label="Status"
          value={props.object.status}
        ></DetailsFormRow>
        <DateTimeDetailsFormRow
          label="Data transportu"
          value={props.object.transportDate}
          format={date_format_dateOnly}
        ></DateTimeDetailsFormRow>
        <DateTimeDetailsFormRow
          label="Data zamknięcia"
          value={props.object.dateClosed}
          format={date_format_dateOnly}
          nullElement={<small>brak</small>}
        ></DateTimeDetailsFormRow>
        <DetailsFormRow
          label="Zgłaszający"
          value={props.object.creatorEmail}
        ></DetailsFormRow>
        <DetailsFormRow
          label="Nr tel. zgłaszającego"
          value={props.object.creatorPhoneNumber}
        ></DetailsFormRow>
        <DetailsFormRow
          label="Osoba kontaktowa"
          value={props.object.contact}
        ></DetailsFormRow>
        <DetailsFormRow
          label="Punkt odbioru"
          value={props.object.location}
        ></DetailsFormRow>
        <DetailsFormRow
          label="Telefon w punkcie odbioru"
          value={props.object.returnSitePhoneNumber}
        ></DetailsFormRow>
        <DetailsFormRow
          label="E-mail w punkcie odbioru"
          value={props.object.returnSiteEMail}
        ></DetailsFormRow>
        <BooleanDetailsFormRow
          label="Auto z windą"
          value={props.object.requestLoadService}
        ></BooleanDetailsFormRow>
        <NumberDetailsFormRow
          label="Koszt"
          value={props.object.cost}
          format="%s PLN"
        ></NumberDetailsFormRow>
      </div>
      <div className="-app-display-section">
        <h2>Zwracane bębny</h2>
        <ReturnRequestCableReelDisplayFragment
          items={props.object.returnRequestCableReelItemVMs}
        ></ReturnRequestCableReelDisplayFragment>
      </div>
      <DescriptionSection
        title="Uwagi"
        text={props.object.notes}
      ></DescriptionSection>
    </>
  );
}

export default DetailsPresenter;
