import ConfirmEMailForm from "Account/ConfirmMail/ConfirmEMailForm";
import ForgotPasswordForm from "Account/ForgotPassword/ForgotPasswordForm";
import RegisterForm from "Account/Register/RegisterForm";
import ResetPasswordForm from "Account/ResetPassword/ResetPasswordForm";
import SetPasswordForm from "Account/SetPassword/SetPasswordForm";
import App from "App/App";
import NoRouteErrorPage from "Navigation/no-route-error-page";

export const anonymousRoutes = [
  {
    path: "Account",
    element: <App isAnonymous={true} />,
    children: [
      {
        path: "Register",
        element: <RegisterForm key={"Account/Register"}></RegisterForm>,
      },
      {
        path: "ConfirmEMail",
        element: (
          <ConfirmEMailForm key={"Account/ConfirmEMail"}></ConfirmEMailForm>
        ),
      },
      {
        path: "ForgotPassword",
        element: (
          <ForgotPasswordForm
            key={"Account/ForgotPassword"}
          ></ForgotPasswordForm>
        ),
      },
      {
        path: "ResetPassword",
        element: (
          <ResetPasswordForm key={"Account/ResetPassword"}></ResetPasswordForm>
        ),
      },
      {
        path: "SetPassword",
        element: (
          <SetPasswordForm key={"Account/SsetPassword"}></SetPasswordForm>
        ),
      },
      {
        path: "*",
        element: <NoRouteErrorPage />,
      },
    ],
  },
];
