
/** Retuns the id of the company the application is serving, based on from where the request comes */
export async function getCompany(): Promise<number> {
  let response = await fetch("/api/Infrastructure/Login/GetCompanyId");
  if (!response.ok) throw new Error("Response error.");
  let r = await response.json();
  return r;
}

export interface CompanyInfo {
  id: number;
  companyName: string;
  companyCode: string;
  className: string;
  companyLogo: string;
  registrationUrl: string;
  resetPasswordUrl: string;
  aboutFilePath: string;
}

export const companyInfoMap: Map<number, CompanyInfo> = new Map([
  [
    1,
    {
      id: 1,
      companyName: "Enexon sp. z o.o.",
      companyCode: "Enexon",
      className: "-app-company-enexon",
      companyLogo: "EnexonLogo.png",
      registrationUrl: "https://bebny.enexon.pl/Account/Register?isBok=true",
      resetPasswordUrl: "https://bebny.enexon.pl/Account/ForgotPassword?isBok=true",
      aboutFilePath: "/contact/enexon.html"
    },
  ],
  [
    2,
    {
      id: 2,
      companyName: "Fega Poland Sp. z o.o.",
      companyCode: "Fega",
      className: "-app-company-fega",
      companyLogo: "FegaLogo.png",
      registrationUrl: "https://bebny.fega.pl/Account/Register?isBok=true",
      resetPasswordUrl: "https://bebny.fega.pl/Account/ForgotPassword?isBok=true",
      aboutFilePath: "/contact/fega.html"
    },
  ],
  [
    3,
    {
      id: 3,
      companyName: "Kaczmarek Electric SA",
      companyCode: "KE",
      className: "-app-company-ke",
      companyLogo: "KELogo.png",
      registrationUrl: "https://bebny.ke.pl/Account/Register?isBok=true",
      resetPasswordUrl: "https://bebny.ke.pl/Account/ForgotPassword?isBok=true",
      aboutFilePath: "/contact/ke.html"
    },
  ],
]);

/** Represents the current user data */
export interface UserInfo {
  companyId: number;
  eMail: string;
  phoneNumber: string;
  companyDaxCode: string;
}

/** Keeps the current user data */
export let userInfo: UserInfo;

/** Retuns the data of the current user, based on the user id in the authentication cookie. Updates the global userInfo object. */
export async function getUserInfo(): Promise<UserInfo> {
  let response = await fetch("/api/Infrastructure/Login/GetUserInfo");
  if (!response.ok) throw new Error("Response error.");
  let r = await response.json();
  userInfo = r;
  return r;
}
