import { createRef, useContext, useEffect, useState } from "react";
import { ViewModel, viewModelNewValues } from "./viewModel";
import { FormProvider, useForm } from "react-hook-form";
import { httpPost } from "Common/Http/httpPost";
import getClassMetadata from "Common/classMetadataService";
import LoadingContentWrapper from "Common/Loading/LoadingContentWrapper";
import TextInputFormRow from "Common/EditForm/TextInput/TextInputFormRow";
import getTextMetadata from "Common/EditForm/ClassMetadata/getTextMetadata";
import SelectFormRow from "Common/EditForm/Select/SelectFormRow";
import SaveError from "./SaveError";
import SaveSuccess from "./SaveSuccess";
import { ResetPasswordServerVM } from "./ResetPasswordServerVM";
import { CompanyContext } from "App/companyContext";
import { companyInfoMap } from "repositories/companyRepository";
import ReCAPTCHA from "react-google-recaptcha";
import httpGet from "Common/Http/httpGet";
import FormButtons from "Common/EditForm/formButtons";
import { useNavigate } from "react-router-dom";

export default function ResetPasswordForm(props: {}) {
  let [isLoading, setIsLoading] = useState<boolean>(true);
  let [isSaveError, setIsSaveError] = useState<boolean>(false);
  let [isSaveSuccessful, setIsSaveSuccessful] = useState<boolean>(false);
  let [metadata, setMetadata] = useState<{ [key: string]: any }>({});
  let [serverSideError, setServerSideError] = useState<string>("");

  const companyId = useContext(CompanyContext);
  const companyInfo = companyInfoMap.get(companyId ?? 0);
  if (companyInfo === undefined) throw new Error("Config error.");

  const navigate = useNavigate();

  const methods = useForm<ViewModel>({
    mode: "onTouched",
    defaultValues: viewModelNewValues,
  });

  const { handleSubmit, formState, control, getValues, watch, trigger, reset } =
    methods;

  const { isSubmitting } = formState;

  const onSubmit = async (values: ViewModel) => {
    let params = new URL(document.location.href).searchParams;

    let userId = params.get("userId");
    let code = params.get("code");
    let password = values.password;

    let data: ResetPasswordServerVM = {
      password: password,
      userId: userId!,
      code: code!,
    };

    try {
      let error = await httpPost<ResetPasswordServerVM, string>(
        "/api/Account/ResetPassword",
        data
      );

      setServerSideError(error);

      if (error === "") {
        setIsSaveSuccessful(true);
      }
    } catch (e) {
      setIsSaveError(true);
    }

    return;
  };

  const onInitialize = async () => {
    // load form values
    // fetch existing data for the form
    // load data for selects, etc.
    // this method is async so we can use await here

    let metadata_response = await getClassMetadata("ResetPasswordVM");
    setMetadata(metadata_response);

    let initialValues = Object.assign({}, viewModelNewValues);

    reset(initialValues);

    setIsLoading(false);
  };

  useEffect(() => {
    onInitialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container" style={{ maxWidth: 720 }}>
        <div className="content-wrapper">
          <h1 className="border-bottom pb-2 mt-4 mb-5">Nowe hasło</h1>
          <LoadingContentWrapper isLoading={isLoading} isFullWidth={true}>
            {!isLoading && !isSaveError && !isSaveSuccessful && (
              <FormProvider {...methods}>
                <form noValidate>
                  <TextInputFormRow
                    {...getTextMetadata<ViewModel>(
                      "password",
                      metadata["password"]
                    )}
                    password={true}
                    label="Hasło"
                    control={control}
                    inlineDescription="Hasło musi mieć co najmniej 6 znaków oraz zawierać przynajmniej jedną wielką i jedną małą literę."
                  />

                  <TextInputFormRow
                    {...getTextMetadata<ViewModel>(
                      "repeatedPassword",
                      metadata["password"]
                    )}
                    password={true}
                    label="Powtórz hasło"
                    control={control}
                    rules={{
                      validate: {
                        passwordMatch: (v: any) => {
                          // validaton between fields - with useForm/getValues()
                          const password = getValues("password");
                          const repeatedPassword = v;
                          return password != repeatedPassword
                            ? "Hasło i powtórzone hasło nie zgadzają się."
                            : undefined;
                        },
                      },
                    }}
                  />

                  {serverSideError !== "" && (
                    <div className="row pt-2">
                      <div className="offset-sm-4 col-sm-8">
                        <span className="text-danger mt-1">
                          {serverSideError}
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="row pt-2 pb-5">
                    <div className="offset-sm-4 col-sm-8">
                      <FormButtons
                        onSave={(e) => handleSubmit(onSubmit)(e)}
                        onCancel={(e) => navigate("/")}
                        isSubmitting={isSubmitting}
                      ></FormButtons>
                    </div>
                  </div>
                </form>
              </FormProvider>
            )}
            {isSaveError && <SaveError />}
            {isSaveSuccessful && <SaveSuccess />}
          </LoadingContentWrapper>
        </div>
      </div>
    </>
  );
}
