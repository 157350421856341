import BackButtonRow from "Common/DetailsForm/Fragments/BackButtonRow";
import EditButton from "Common/DetailsForm/Fragments/EditButton";

import { httpGet } from "Common/Http/httpGet";
import LoadingContentWrapper from "Common/Loading/LoadingContentWrapper";
import { useEffect, useState } from "react";
import DetailsPresenter from "./DetailsPresenter";
import { viewModel } from "./viewModel";

export default function Details(props: {
  navigate: (url: string) => void;
  backUrl?: string;
}) {
  let backUrl = props.backUrl || "/";

  const [dataObject, setDataObject] =
    useState<viewModel | undefined>(undefined);

  useEffect(() => {
    const setData = async () => {
      setDataObject(undefined);
      let a = await httpGet<viewModel>("/api/Account/GetAccountDataForDisplay");
      setDataObject(a);
    };

    setData();
  }, []);

  return (
    <>
      <h1 className="border-bottom pb-2 mt-4 mb-4">Moje konto</h1>
      <LoadingContentWrapper isLoading={!dataObject}>
        {!!dataObject && (
          <>
            <div className="mb-2" style={{ marginTop: "-8px" }}>
              <button
                className="btn btn-primary py-1 me-2 ps-4 pe-5"
                style={{ marginTop: "8px" }}
                type="button"
                onClick={() => {
                  props.navigate("./../ChangePassword?backUrl=../MyAccount");
                }}
              >
                <i className="bi bi-key ps-2 me-3"></i> Zmień hasło
              </button>
              <EditButton
                onClick={() => {
                  props.navigate("./../ChangeAccountData?backUrl=../MyAccount");
                }}
                text="Zmień dane"
              />
            </div>
            <DetailsPresenter object={dataObject!}></DetailsPresenter>
            <BackButtonRow
              onClick={() => {
                props.navigate(backUrl);
              }}
            />
          </>
        )}
      </LoadingContentWrapper>
    </>
  );
}
