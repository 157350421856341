/**
 * Performs a HTTP post, sending the specified object in the message body (of `TSend` type).
 * Returns nothing
 * @param url the complete url to the web service
 * @param data the object to send
 */
export async function httpPostNoReturn<TSend>(
  url: string,
  data: TSend
): Promise<void> {
  let response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) throw new Error("Response error.");
}
