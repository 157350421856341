import BooleanDetailsFormRow from "Common/DetailsForm/Presenter/BooleanDetailsFormRow";
import DetailsFormRow from "Common/DetailsForm/Presenter/DetailsFormRow";
import MultilineDetailsFormRow from "Common/DetailsForm/Presenter/MultilineDetailsFormRow";
import DateTimeDetailsFormRow, {
  date_format_dateOnly,
  date_format_dateTime,
} from "Common/DetailsForm/Presenter/DateTimeDetailsFormRow";
import { cableReelDisplayVM } from "./cableReelDisplayVM";
import NumberDetailsFormRow from "Common/DetailsForm/Presenter/NumberDetailsFormRow";
import GenericDetailsFormRow from "Common/DetailsForm/Presenter/GenericDetailsFormRow";
import { Link } from "react-router-dom";
import { getCanBeReturnedString } from "../getCanBeReturnedString";

export function DetailsPresenter(props: { object: cableReelDisplayVM }) {
  return (
    <>
      <div className="-app-display-section">
        <h2>Dane podstawowe</h2>
        <DetailsFormRow
          label="Nr bębna"
          value={props.object.reelId}
        ></DetailsFormRow>
        <GenericDetailsFormRow label="Status">
          <span title={props.object.statusTitle}>
            {props.object.status}&nbsp;
            <i className="bi bi-info-circle-fill" aria-hidden="true"></i>
          </span>
        </GenericDetailsFormRow>
        <DateTimeDetailsFormRow
          label="Data wydania"
          value={props.object.invoiceDate}
        ></DateTimeDetailsFormRow>
        <DetailsFormRow
          label="Faktura"
          value={
            props.object.invoiceId + " / pozycja " + props.object.invoiceItemId
          }
        ></DetailsFormRow>
        <DetailsFormRow
          label="Nazwa towaru"
          value={props.object.productName}
        ></DetailsFormRow>
        <DetailsFormRow
          label="Indeks towaru"
          value={props.object.productId}
        ></DetailsFormRow>
        <NumberDetailsFormRow
          label="Cena"
          value={props.object.price}
          format="%s PLN"
        ></NumberDetailsFormRow>
        {!!props.object.returnRequestId && (
          <GenericDetailsFormRow label="Zlecenie zwrotu">
            <Link
              to={"../../ReturnRequest/Details/" + props.object.returnRequestId}
            >
              {props.object.returnRequestCode} -{" "}
              {props.object.returnRequestStatus}
            </Link>
          </GenericDetailsFormRow>
        )}
        {!props.object.returnRequestId && (
          <GenericDetailsFormRow label="Możliwość zwrotu">
            {getCanBeReturnedString(
              !!props.object.returnRequestId,
              props.object.daysForReturn
            )}
          </GenericDetailsFormRow>
        )}
      </div>
    </>
  );
}

export default DetailsPresenter;
