import { ReactNode } from "react";
import format from "format-number";
import { sprintf } from "sprintf-js";

/** Renders a Bootstrap row for a details form with label and a number, formatted with the specifed number of decimal places.
 * No value (null or undefined) is displayed with nullElement
 * The thousands separator is "\xa0" and the decimal separator is a comma.
 * The final string can be formatted with spritf, e.g. to add a currency symbol.
 */
export function NumberDetailsFormRow(props: {
  /** The label to display on the left */
  label: string;
  /** The value to display, a number */
  value: number | null | undefined;
  /** Format string to apply to the result, sprintf style, must contain %s for the formatted value */
  format?: string;
  /** The number of decimal places to display, the value is rounded "away-from-zero", 2 by default */
  decimalPlaces?: number;
  /** The element to replace null/undefined values, a small tag with "not specified" text by default */
  nullElement?: ReactNode;
}) {
  let nullElement = props.nullElement || <small>Nie podano</small>;
  let decimalPlaces = props.decimalPlaces!=undefined ? props.decimalPlaces : 2;

  let r: ReactNode;

  if (props.value === null || props.value === undefined) {
    r = nullElement;
  } else {
    let formatted = internalFormatNumber(
      props.value,
      decimalPlaces,
      props.format
    );
    r = <>{formatted}</>;
  }

  return (
    <div className="row mb-3">
      <div className="col-sm-4 -app-display-label">{props.label}</div>
      <div className="col-sm-8">{r}</div>
    </div>
  );
}

/**
 * Internal formatting function, extracted for unit tests.
 * @param value
 * @param decimalPlaces
 * @param format
 * @returns
 */
export function internalFormatNumber(
  value: number,
  decimalPlaces: number,
  sprintfFormatString?: string
): string {
  let formatter = format({
    integerSeparator: "\xa0",
    round: decimalPlaces,
    decimal: ",",
    padRight: decimalPlaces,
  });
  let formatted = formatter(value);

  if (sprintfFormatString !== undefined) {
    formatted = sprintf(sprintfFormatString, formatted);
  }

  return formatted;
}

export default NumberDetailsFormRow;
