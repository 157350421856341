import {
  Control,
  FieldValues,
  UseControllerProps,
  useFormState,
} from "react-hook-form";
import DecimalInput, {
  DecimalInputProps,
} from "./DecimalInputReactNumberFormat";
import Description from "../Description";
import FieldError from "../FieldError";
import Label from "../Label";
import SimplifiedFormRowProps from "../SimplifiedFormRowProps";

/**
 * Displays a edit form row with a DecimalInput from DecimalInputReactNumberFormat
 * @param props - SimplifiedFormRowProps, just id and what to display: label, inlineDescription, longDescription
 * @returns
 */
export default function DecimalInputFormRow<T extends FieldValues>(
  props: SimplifiedFormRowProps &
    DecimalInputProps &
    UseControllerProps<T> & { control: Control<T> }
) {
  const { errors, touchedFields } = useFormState({
    control: props.control,
    name: props.name,
    exact: true,
  });
  const isTouched = touchedFields[props.name];
  const error = errors[props.name];

  return (
    <div className="row mb-3">
      <Label
        label={props.label}
        id={props.id}
        isError={!!error /*&& !!isTouched*/}
        description={props.longDescription}
      ></Label>
      <div className="col-sm-8">
        <DecimalInput {...props}></DecimalInput>
        <Description description={props.inlineDescription}></Description>
        <FieldError
          error={error?.message as string | null | undefined}
        ></FieldError>
      </div>
    </div>
  );
}
