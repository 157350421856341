import { ComplaintItemViewModel } from "CustomerComplaint/New/ViewModel";
import getComplaintTypes from "CustomerComplaint/New/getComplaintTypes";
import { formatDecimalNumber } from "Common/DetailsForm/Presenter/Helpers/formatDecimalNumber";
import { limitedText } from "Common/DetailsForm/Presenter/Helpers/limitedText";
import { useEffect, useState } from "react";
import { returnRequestCableReelItemVM } from "./returnRequestCableReelItemVM";

export default function ReturnRequestCableReelDisplayFragment(props: {
  items: returnRequestCableReelItemVM[];
}) {
  return (
    <>
      {props.items.length > 0 && (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>lp</th>
              <th>Nr bębna</th>
              <th>Status</th>
              <th>Nazwa towaru</th>
              <th>Indeks towaru</th>
              <th className="text-center">Cena</th>
            </tr>
          </thead>
          <tbody>
            {props.items.map((item, index) => (
              <tr key={index}>
                <td className="text-end">{index + 1}.</td>
                <td>{item.reelId}</td>
                <td>
                  {
                    <span title={item.statusTitle}>
                      {item.status}&nbsp;
                      <i
                        className="bi bi-info-circle-fill"
                        aria-hidden="true"
                      ></i>
                    </span>
                  }
                </td>
                <td>{item.productName}</td>
                <td>{item.productId}</td>
                <td className="text-end">
                  {formatDecimalNumber(item.price, 2)}&nbsp;PLN
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
}
