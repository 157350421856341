import { useContext } from "react";
import "./App.scss";
import Layout from "../Layout/Layout";
import LoginFrom from "./LoginForm/LoginFormRHF";
import { CompanyContext } from "./companyContext";
import { UserNameContext } from "./userNameContext";
import { LoginContext } from "./loginContext";
import { Outlet } from "react-router-dom";
import AnonymousLayout from "Layout/AnonymousLayout";

function App(props: { isAnonymous: boolean }) {
  const userName = useContext(UserNameContext)!;
  const { login, logout, onLoggedIn } = useContext(LoginContext)!;
  const companyId = useContext(CompanyContext);

  if (userName === null && !props.isAnonymous) {
    return <LoginFrom onLoggedIn={onLoggedIn} onLogin={login}></LoginFrom>;
  }

  if (!props.isAnonymous) {
    return <Layout userName={userName} onLogoutClick={logout}></Layout>;
  } else {
    return <AnonymousLayout />;
  }
}

export default App;
