import { FieldPath, FieldValues } from "react-hook-form";
import getBasicMetadata, { BasicMetadata } from "./getBasicMetadata";

export interface DateMetadata<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends BasicMetadata<TFieldValues, TName> {
  noEarlierThan?: number;
}

export default function getDateMetadata<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  name: TName,
  metadata: { [key: string]: any }
): DateMetadata<TFieldValues, TName> {
  let props: DateMetadata<TFieldValues, TName> = getBasicMetadata(
    name,
    metadata
  ) as DateMetadata<TFieldValues, TName>;

  props.noEarlierThan = metadata.noEarlierThan;

  return props as DateMetadata<TFieldValues, TName>;
}
