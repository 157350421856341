import { Popover } from "react-bootstrap";

export const helpPopover = (
  <Popover id="popover-help">
    <Popover.Header as="h3">Wybierz bębny do zwrotu</Popover.Header>
    <Popover.Body>
      <p>
        <b>Zakres bębnów</b>
        <br />
        Możesz zaznaczyć szereg bębnów jeśli przy zaznaczaniu drugiego
        przytrzymasz klawisz Shift.
      </p>
      <p>
        <b>Wszystkie bębny</b>
        <br />
        Kliknij na zaznaczenie w nagłówku pierwszej kolumny aby zaznaczyć
        wszystkie bębny.
        <br />
        Jeżeli włączyłeś filtrowanie, zaznaczone w ten sposób zostaną tylko
        aktualnie widoczne bębny.
      </p>
      <p>
        <b>Porządkowanie</b>
        <br />
        Kliknij raz lub dwa razy na nagłówek aby uporządkować tabelę w/g
        zawartości kolumny.
      </p>
      <p>
        <b>Filtrowanie</b>
        <br />
        Możesz wpisać w polu "znajdź..." dowolną frazę, by wyszukać bębny.
        <br />
        Możesz wyszukiwać również w/g poszczególnych kolumn, kliknij symbol po
        prawej stronie nagłówka kolumny.
      </p>
      <p>
        <b>Ukryj pomoc</b>
        <br />
        Kliknij jeszcze raz na przycisk pomoc by ją ukryć.
      </p>
    </Popover.Body>
  </Popover>
);
