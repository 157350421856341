import { ReactNode } from "react";

/**
 * Renders a Bootstrap row for a details form with label / value.
 * Value can be of any type including null and undefined.
 * If a transform function is specified, the value is first transformed using this function.
 * Otherwise value is transformed to string and null and undefined values are normalized to null.
 * Then, if showNotSpecifiedForNull is true, null values are replaced with <small>Not specified</small>
 * Eventually, if displayAsHtml is specified as true, the value is displayed directly, otherwise it is escaped in the regular React way.
 * Currently label / value classes are fixed col-sm-4 / col-sm-8. When needed, this should be set for the whole application.
 * @param props
 * @returns
 */
export default function DetailsFormRow(props: {
  /** Label to display on the left */
  label: string;
  /** Value for this row, displayed on the right.
   * Null and undefined are considered empty values, an empty string not.
   * The value will be transformed using the transform function, if one is specified.  */
  value: any;
  /** The optional function to transform the value into string. */
  transform?: (argument: any) => string | null;
  /** Whether, null / undefined values are replaced with Not specified in the small tag. Default is true. */
  showNotSpecifiedForNull?: boolean;
  /** Whether to display the value as html, without escaping it. Default is true. */
  displayAsHtml?: boolean;
  /** The element to use to replace null/undefined value. The defalut is a small tag with the "not specified" text .*/
  nullReplacementElement?: ReactNode;
}) {
  let showNotSpecifiedForNull =
    props.showNotSpecifiedForNull == undefined
      ? true
      : props.showNotSpecifiedForNull;

  let value;

  let nullReplacementElement: ReactNode = props.nullReplacementElement || (
    <small>Nie podano.</small>
  );

  if (props.transform) {
    value = props.transform(props.value);
  } else {
    value =
      props.value !== null && props.value !== undefined
        ? props.value.toString()
        : null;
  }

  let content;
  if (value === null && showNotSpecifiedForNull) {
    content = <div className="col-sm-8">{nullReplacementElement}</div>;
  } else {
    if (props.displayAsHtml) {
      content = (
        <div
          className="col-sm-8"
          dangerouslySetInnerHTML={{ __html: value }}
        ></div>
      );
    } else {
      content = <div className="col-sm-8">{value}</div>;
    }
  }

  const r = (
    <div className="row mb-3">
      <div className="col-sm-4 -app-display-label">{props.label}</div>
      {content}
    </div>
  );

  return r;
}
