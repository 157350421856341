/**
 * Validator that checks whether value has at most the speicified number of characters.
 * @param message Optional custom message to display
 * @returns The error message or undefined if there's no error
 */
export function maxLengthValidator(
  maxLenght: number,
  message?: string
): (value: string | null) => string | undefined {
  return (value) => {
    let r = undefined;

    if (typeof value === "string" && value.length > maxLenght) {
      r = message || `Możesz podać co najwyżej ${maxLenght} znaków.`;
    }

    return r;
  };
}

/**
 * Validator that checks whether value has at least the specified number of characters but an empty string is allowed. Use a separate required validator if needed.
 * @param minLength The minimum required length
 * @param message Optional custom message to display
 * @returns The error message or undefined if there's no error
 */
export function minLengthValidator(
  minLength: number,
  message?: string
): (value: string | null) => string | undefined {
  return (value) => {
    let errorMessage = undefined;

    if (
      typeof value === "string" &&
      value.length > 0 &&
      value.length < minLength
    ) {
      errorMessage = message || `Podaj przynajmniej ${minLength} znaki(ów).`;
    }

    return errorMessage;
  };
}

/**
 * Validator that checks whether value and its trimmed version are the same.
 * @param message Optional custom message to display
 * @returns The error message or undefined if there's no error
 */
export function trimmedStringValidator(
  message?: string
): (value: string | null) => string | undefined {
  return (value) => {
    let errorMessage = undefined;

    if (typeof value === "string" && value.trim() !== value) {
      errorMessage =
        message || `Nie można używać początkowych i końcowych spacji.`;
    }

    return errorMessage;
  };
}


/**
 * Validator that checks whether value doesn't contain whitespaces only.
 * @param message Optional custom message to display
 * @returns The error message or undefined if there's no error
 */
export function noWhitespacesOnlyValidator(
  message?: string
): (value: string | null) => string | undefined {
  return (value) => {
    let errorMessage = undefined;

    if (typeof value === "string" && value !== "" && value.trim() === "") {
      errorMessage =
        message || `Nie można podać samych spacji.`;
    }

    return errorMessage;
  };
}