import { ChangeAccountDataServerVM } from "./changeAccountDataServerVM";

export interface ViewModel {
  phoneNumber: string;
}

export function toServerVM(vm: ViewModel): ChangeAccountDataServerVM {
  let r: ChangeAccountDataServerVM = {
    phoneNumber: vm.phoneNumber,
  };

  return r;
}

export function serverVMToClientVM(vm: ChangeAccountDataServerVM): ViewModel {
  let r: ChangeAccountDataServerVM = {
    phoneNumber: vm.phoneNumber,
  };

  return r;
}

export const viewModelNewValues: ViewModel = {
  phoneNumber: "",
};
