import {
  ColDef,
  ICellRendererParams,
  ValueGetterParams,
} from "ag-grid-community";
import { getCanBeReturnedString } from "CableReel/CableReel/getCanBeReturnedString";
import booleanValueFormatter from "Common/AgGridHelpers/BooleanColumn/booleanColumnFormatter";
import booleanCustomMatcher from "Common/AgGridHelpers/BooleanColumn/booleanCustomMatcher";
import { isoDateColumnComparator } from "Common/AgGridHelpers/DateColumn/isoDateColumnComparator";
import isoDateDateOnlyColumnFormatter from "Common/AgGridHelpers/DateColumn/isoDateDateOnlyColumnFormatter";
import nativeDateDateOnlyColumnFormatter from "Common/AgGridHelpers/DateColumn/nativeDateDateOnlyColumnFormatter";
import {
  numberValueToTwoDecimalDigitsString,
  twoFractionDigitsColumnFormatter,
} from "Common/AgGridHelpers/DecimalColumn/twoFractionDigitsColumnFormatter";
import { remPixelSize } from "Layout/LayoutConsts";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";

const canBeReturnedFormatter = (data: any): string => {
  return getCanBeReturnedString(false, data.daysForReturn);
};

const canBeReturnedCellRenderer = (props: ICellRendererParams) => {
  return canBeReturnedFormatter(props.data);
};

export const defaultColDef: ColDef = {
  sortable: true,
  filter: true,
};

export const columnDefs: ColDef[] = [
  {
    headerName: "Nr bębna",
    field: "reelId",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    maxWidth: 16 * remPixelSize,
    minWidth: 14 * remPixelSize,
  },
  {
    headerName: "Data wydania",
    field: "invoiceDateAsDateOnly", // this is a native JS date
    maxWidth: 16 * remPixelSize,
    minWidth: 10 * remPixelSize,
    valueFormatter: nativeDateDateOnlyColumnFormatter,
    cellClass: ["text-center"],
    headerClass: "-app-centered-header",
    filter: "agDateColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
      browserDatePicker: false,
      filterOptions: [
        "equals",
        "lessThan",
        "lessThanOrEqual",
        "greaterThan",
        "greaterThanOrEqual",
      ],
    },
    getQuickFilterText: (params) =>
      params.value
        ? DateTime.fromJSDate(params.value).toFormat("yyyy-MM-dd")
        : "",
    initialSort: "desc",
  },
  {
    headerName: "Nr faktury",
    field: "invoiceId",
    maxWidth: 10 * remPixelSize,
    minWidth: 10 * remPixelSize,
  },
  {
    headerName: "Nazwa towaru",
    field: "productName",
    cellClass: ["text-start"],
    minWidth: 20 * remPixelSize,
  },
  {
    headerName: "Indeks towaru",
    field: "productId",
    cellClass: ["text-center"],
    maxWidth: 10 * remPixelSize,
    minWidth: 8 * remPixelSize,
  },
  {
    headerName: "Cena",
    field: "price",
    cellClass: ["text-end"],
    filter: "agNumberColumnFilter",
    maxWidth: 8 * remPixelSize,
    minWidth: 8 * remPixelSize,
    valueFormatter: twoFractionDigitsColumnFormatter,
    getQuickFilterText: (params) =>
      numberValueToTwoDecimalDigitsString(params.value),
    filterParams: {
      suppressAndOrCondition: true,
      filterOptions: [
        "equals",
        "lessThan",
        "lessThanOrEqual",
        "greaterThan",
        "greaterThanOrEqual",
      ],
    },
  },
  {
    headerName: "Możliwość zwrotu",
    field: "daysForReturn",
    cellRenderer: canBeReturnedCellRenderer,
    minWidth: 8 * remPixelSize,
    getQuickFilterText: (params) => canBeReturnedFormatter(params.data),
    // filterValueGetter - provides the value for the filter, here the whole data object
    filterValueGetter: (params: ValueGetterParams<any>) => params.data,
    filterParams: {
      // textFormatter - applied both for the text to search (to provide the output text) and the object (to provide the output text)
      textFormatter: (from: any) => {
        if (typeof from === "string" || from instanceof String) {
          return from.toLocaleUpperCase();
        } else {
          return canBeReturnedFormatter(from).toLocaleUpperCase();
        }
      },
    },
  },
];
