import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export default function SaveSuccessNoApproval() {
  return (
    <motion.div
      key="loading"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0 }}
      exit={{ opacity: 0, transition: { duration: 0.2 } }}
    >
      <div className="row my-5">
        <div className="col-sm-12">
          <div className="alert alert-success" role="alert">
            <div className="mb-3">
              <h2>
                <i className="bi bi-check-circle text-success me-2"></i> E-mail
                został potwierdzony
              </h2>
            </div>
            <div>
              <p>Adres e-mail twojego konta został potwierdzony, a konto zatwierdzone.</p>
              <p>
                Możesz sie teraz zalogować: <Link to="/" className="alert-link">Zaloguj</Link>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
