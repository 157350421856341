import { Link } from "react-router-dom";

/** Renders a plain sidebar link with the specified content */
export function PlainLink(props: { to: string; children: React.ReactNode }) {
  return (
    <Link to={props.to} className="list-group-item -mko-menu-item">
      {props.children}
    </Link>
  );
}

export default PlainLink;
