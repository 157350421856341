/**
 * Performs a single POST request with the specified blob
 * @param uri The complete URI
 * @param blob The blob to send
 * @param progressCallback The callback that is assigned to upload.onprogress
 * @returns Promise that represents the post operation.
 */
export function postBlob(
  uri: string,
  blob: Blob,
  progressCallback?: (event: ProgressEvent) => void
): Promise<any> {
  let promise = new Promise<any>((resolve, reject) => {
    let request: XMLHttpRequest = new XMLHttpRequest();
    request.onreadystatechange = function () {
      if (request.readyState === 4) {
        if (request.status === 200) {
          let response = JSON.parse(request.responseText);
          resolve(response);
        } else {
          reject();
        }
      }
    };
    // the progressCallback must be set before request.open()!
    if (progressCallback) {
      request.upload.onprogress = progressCallback;
    }
    request.open("POST", uri);
    request.setRequestHeader("content-type", "application/octet-stream");
    request.send(blob);
  });
  return promise;
}

export default postBlob;
