import { useEffect, useState } from "react";
import { getViewModelNewValues } from "./getViewModelNewValues";
import { FormProvider, useForm } from "react-hook-form";
import { httpPost } from "Common/Http/httpPost";
import getClassMetadata from "Common/classMetadataService";
import LoadingContentWrapper from "Common/Loading/LoadingContentWrapper";
import TextInputFormRow from "Common/EditForm/TextInput/TextInputFormRow";
import getTextMetadata from "Common/EditForm/ClassMetadata/getTextMetadata";
import DateInputInputFormRow from "Common/EditForm/Date/DateInputFormRow";
import FormButtons from "Common/EditForm/formButtons";
import { ConfirmDialog } from "Common/Dialog/ConfirmDialog";
import { viewModel } from "./viewModel";
import { newReturnRequestViewModelToServerVM } from "./newReturnRequestViewModelToServerVM";
import { newReturnRequestServerVM } from "./newReturnRequestServerVM";
import CheckBoxFormRow from "Common/EditForm/CheckBox/CheckBoxFormRow";
import TextAreaFormRow from "Common/EditForm/TextArea/TextAreaFormRow";
import SaveErrorWithOnClose from "Common/EditForm/SaveErrorWithOnClose";
import SaveSuccess from "./SaveSuccess";
import getDateMetadata from "Common/EditForm/ClassMetadata/getDateMetadata";

export default function Form(props: {
  onOk: () => void;
  onCancel: () => void;
  onError: () => void;
  selectedCableReelIds: number[];
}) {
  let [isLoading, setIsLoading] = useState<boolean>(true);
  let [isSaveError, setIsSaveError] = useState<boolean>(false);
  let [isSaveSuccessful, setIsSaveSuccessful] = useState<boolean>(false);
  let [metadata, setMetadata] = useState<{ [key: string]: any }>({});
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset
  } = useForm<viewModel>({
    mode: "onTouched",
    defaultValues: getViewModelNewValues(),
  });

  const onSubmit = async (values: viewModel) => {
    // confirm submission

    let confirm = await ConfirmDialog(
      "Potwierdzenie wysyłania formularza",
      "Czy chcesz zakończyć wypełnianie formularza zwrotu bębnów i wysłać go?\r\nWysłanego zgłoszenia nie można wycofać ani edytować."
    );

    if (confirm === false) return;

    // let vmToSave = Object.assign({}, viewModel, values); // if values contain all the necessary data, this step will be unnecessary
    let data = newReturnRequestViewModelToServerVM(
      values,
      props.selectedCableReelIds
    );

    try {
      /* let o = */ await httpPost<newReturnRequestServerVM, any>(
        "/api/CableReels/ReturnRequest/NewReturnRequest",
        data
      );
      setIsSaveSuccessful(true);
    } catch (e) {
      setIsSaveError(true);
    }

    return;
  };

  const onSaveSuccess = async () => {
    props.onOk();
  };

  const onInitialize = async () => {
    // load form values
    // fetch existing data for the form
    // load data for selects, etc.
    // this method is async so we can use await here

    let metadata_response = await getClassMetadata("NewReturnRequestVM");
    setMetadata(metadata_response);

    reset(getViewModelNewValues());

    setIsLoading(false);
  };

  useEffect(() => {
    onInitialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancel = async () => {
    let r = await ConfirmDialog(
      "Anulowanie wysyłania",
      "Czy na pewno chcesz porzucić ten formularz?\r\nWybierz OK aby porzucić formularz."
    );
    props.onCancel();
  };
  return (
    <div className="content-wrapper">
      <h1 className="border-bottom pb-2 mt-4 mb-5">
        Nowe zgłoszenie odbioru - dane zgłoszenia
      </h1>
      <LoadingContentWrapper isLoading={isLoading}>
        {!isLoading && !isSaveError && !isSaveSuccessful && (
            <form noValidate>
              <DateInputInputFormRow
                {...getDateMetadata<viewModel>(
                  "transportDate",
                  metadata["transportDate"]
                )}
                label="Data odbioru"
                longDescription={undefined}
                control={control}
                inlineDescription="Wybierz preferowaną datę odbioru bębnów."
              />

              <TextInputFormRow
                {...getTextMetadata<viewModel>("contact", metadata["contact"])}
                label="Osoba kontaktowa"
                control={control}
                inlineDescription={undefined}
              />

              <TextInputFormRow
                {...getTextMetadata<viewModel>(
                  "location",
                  metadata["location"]
                )}
                label="Lokalizacja odbioru"
                control={control}
                inlineDescription={undefined}
              />

              <TextInputFormRow
                {...getTextMetadata<viewModel>(
                  "returnSitePhoneNumber",
                  metadata["returnSitePhoneNumber"]
                )}
                label="Telefon w punkcie odbioru"
                control={control}
                inlineDescription={undefined}
                className="-app-medium-control"
              />

              <TextInputFormRow
                {...getTextMetadata<viewModel>(
                  "returnSiteEMail",
                  metadata["returnSiteEMail"]
                )}
                label="E-mail w punkcie odbioru"
                control={control}
                inlineDescription={undefined}
              />

              <CheckBoxFormRow
                {...getTextMetadata<viewModel>(
                  "requestLoadService",
                  metadata["requestLoadService"]
                )}
                label="Auto z windą"
                longDescription={undefined}
                inlineDescription={undefined}
                bodyText="Zaznacz, aby zamówić auto z windą."
                control={control}
              ></CheckBoxFormRow>

              <TextAreaFormRow
                {...getTextMetadata<viewModel>("notes", metadata["notes"])}
                label="Uwagi"
                control={control}
                inlineDescription={undefined}
              />

              <div className="row pt-2">
                <div className="offset-sm-4 col-sm-8">
                  <FormButtons
                    onSave={(e) => handleSubmit(onSubmit)(e)}
                    onCancel={(e) => onCancel()}
                    isSubmitting={isSubmitting}
                  ></FormButtons>
                </div>
              </div>
            </form>
        )}
        {isSaveError && (
          <SaveErrorWithOnClose
            onClose={() => props.onError()}
          ></SaveErrorWithOnClose>
        )}
        {isSaveSuccessful && <SaveSuccess done={() => onSaveSuccess()} />}
      </LoadingContentWrapper>
    </div>
  );
}
