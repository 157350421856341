import { multilineTextToHtml } from "./multilineTextToHtml";

export function multilineTextTransformBase(
  s: any,
  escapeHtml: boolean
): string | null {
  if (s === null || s === undefined) return null;
  let r: string | undefined | null = "";
  if (s && typeof s === "string") {
    r = multilineTextToHtml(s, escapeHtml);
  }
  return r;
}

/** A transform function for `DetailsFormRow` that replaces new lines with `<br/>` but doesn't escape html */
export function multilineTextTransform(s: any): string | null {
  return multilineTextTransformBase(s, false);
}

/** A transform function `DetailsFormRow` that replaces new lines with `<br/> and escapes html first*/
export function multilineTextTransformEscapeHtml(s: any): string | null {
  return multilineTextTransformBase(s, true);
}
