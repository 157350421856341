import "./Sidebar.scss";
import { Link, Route, Routes } from "react-router-dom";
import { StartFragment } from "./StartFragment";
import { CustomerComplaintFragment } from "./CustomerComplaintFragment";
import { CableReelFragment } from "./CableReelFragment";

export default function Sidebar() {
  return (
    <div id="sidebar">
      <div className="list-group">
        <Link to="/" className="list-group-item -mko-menu-header ">
          <h4>Start</h4>
        </Link>
        <hr />
        <Routes>
          <Route index element={<StartFragment />} />
          {/* <Route index element={<CustomerComplaintFragment />} /> */}
          <Route path="CustomerComplaint">
            <Route index element={<CustomerComplaintFragment />} />
            <Route path="*" element={<CustomerComplaintFragment />} />
          </Route>
          <Route path="CableReel">
            <Route index element={<CableReelFragment />} />
            <Route path="*" element={<CableReelFragment />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
}
