export default function SaveError(props: {}) {
  return (
    <div className="row my-5">
      <div className="col-sm-12">
        <div className="alert alert-danger" role="alert">
          <div className="mb-3">
            <h2>
              <i className="bi bi-exclamation-octagon-fill text-danger me-2"></i>{" "}
              Nie udało się ustawić hasła
            </h2>
          </div>
          <div>
            <p>W trakcie ustawiania hasła wystąpił błąd.</p>
            <p>
              Zamknij okno przeglądarki, kliknij ponownie na łącze w e-mailu i spróbuj ponownie zmienić hasło.
            </p>
            <p>
              Jeśli błąd będzie się powtarzał, skontaktuj się z administratorem
              systemu.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
