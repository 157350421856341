/**
 * Performs a HTTP post, without body
 * Doesn't return anything.
 * @param url the complete url to the web service
 */
export async function httpPostNoBody(uri: string): Promise<void> {
  let response = await fetch(uri, {
    method: "POST",
  });
  if (!response.ok) throw new Error("Response error.");
}

export default httpPostNoBody;
