import { createRef, useContext, useEffect, useState } from "react";
import { ViewModel, toServerVM, viewModelNewValues } from "./viewModel";
import { FormProvider, useForm } from "react-hook-form";
import { httpPost } from "Common/Http/httpPost";
import getClassMetadata from "Common/classMetadataService";
import LoadingContentWrapper from "Common/Loading/LoadingContentWrapper";
import TextInputFormRow from "Common/EditForm/TextInput/TextInputFormRow";
import getTextMetadata from "Common/EditForm/ClassMetadata/getTextMetadata";
import SaveSuccess from "./SaveSuccess";
import FormButtons from "Common/EditForm/formButtons";
import { useNavigate } from "react-router-dom";
import { ChangePasswordServerVM } from "./changePasswordServerVM";
import SaveError from "Common/EditForm/SaveError";

export default function ChangePasswordForm(props: {backUrl?: string}) {
  let [isLoading, setIsLoading] = useState<boolean>(true);
  let [isSaveError, setIsSaveError] = useState<boolean>(false);
  let [isSaveSuccessful, setIsSaveSuccessful] = useState<boolean>(false);
  let [metadata, setMetadata] = useState<{ [key: string]: any }>({});
  let [serverSideError, setServerSideError] = useState<string>("");

  const navigate = useNavigate();

  const methods = useForm<ViewModel>({
    mode: "onTouched",
    defaultValues: viewModelNewValues,
  });

  const { handleSubmit, formState, control, getValues, watch, trigger, reset } =
    methods;

  const { isSubmitting } = formState;

  const onSubmit = async (values: ViewModel) => {
    // let vmToSave = Object.assign({}, viewModel, values); // if values contain all the necessary data, this step will be unnecessary
    let data = toServerVM(values);

    try {
      let error = await httpPost<ChangePasswordServerVM, string>(
        "/api/Account/ChangePassword",
        data
      );

      setServerSideError(error);

      if (error === "") {
        setIsSaveSuccessful(true);
      }
    } catch (e) {
      setIsSaveError(true);
    }

    return;
  };

  const onInitialize = async () => {
    // load form values
    // fetch existing data for the form
    // load data for selects, etc.
    // this method is async so we can use await here

    let metadata_response = await getClassMetadata("ChangePasswordVM");
    setMetadata(metadata_response);

    let initialValues = Object.assign({}, viewModelNewValues);

    reset(initialValues);

    setIsLoading(false);
  };

  useEffect(() => {
    onInitialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <h1 className="border-bottom pb-2 mt-4 mb-5">Zmiana hasła</h1>
        <LoadingContentWrapper isLoading={isLoading} isFullWidth={true}>
          {!isLoading && !isSaveError && !isSaveSuccessful && (
            <FormProvider {...methods}>
              <form noValidate>
                <TextInputFormRow
                  {...getTextMetadata<ViewModel>(
                    "currentPassword",
                    metadata["currentPassword"]
                  )}
                  className="-app-medium-control"
                  password={true}
                  label="Dotychczasowe hasło"
                  control={control}
                />

                <TextInputFormRow
                  {...getTextMetadata<ViewModel>(
                    "newPassword",
                    metadata["newPassword"]
                  )}
                  className="-app-medium-control"
                  password={true}
                  label="Nowe hasło"
                  inlineDescription="Hasło musi mieć co najmniej 6 znaków oraz zawierać przynajmniej jedną wielką i jedną małą literę."
                  control={control}
                />

                <TextInputFormRow
                  {...getTextMetadata<ViewModel>(
                    "repeatedNewPassword",
                    metadata["newPassword"]
                  )}
                  className="-app-medium-control"
                  password={true}
                  label="Powtórz nowe hasło"
                  control={control}
                  rules={{
                    validate: {
                      passwordMatch: (v: any) => {
                        // validaton between fields - with useForm/getValues()
                        const password = getValues("newPassword");
                        const repeatedPassword = v;
                        return password != repeatedPassword
                          ? "Nowe hasło i powtórzone nowe hasło nie zgadzają się."
                          : undefined;
                      },
                    },
                  }}
                />

                {serverSideError !== "" && (
                  <div className="row pt-2">
                    <div className="offset-sm-4 col-sm-8">
                      <span className="text-danger mt-1">
                        {serverSideError}
                      </span>
                    </div>
                  </div>
                )}

                <div className="row pt-2 pb-5">
                  <div className="offset-sm-4 col-sm-8">
                    <FormButtons
                      onSave={(e) => handleSubmit(onSubmit)(e)}
                      onCancel={(e) => navigate(props.backUrl || "/")}
                      isSubmitting={isSubmitting}
                    ></FormButtons>
                  </div>
                </div>
              </form>
            </FormProvider>
          )}
          {isSaveError && (
            <SaveError navigate={(url: string) => navigate(url)} backUrl={props.backUrl || "/"} />
          )}
          {isSaveSuccessful && <SaveSuccess />}
        </LoadingContentWrapper>
      </div>
    </>
  );
}
