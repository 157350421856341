import React, { useState, useEffect } from "react";
import "./App.scss";
import Layout from "../Layout/Layout";
import StartScreen from "./StartScreen";
import LoginFrom from "./LoginForm/LoginFormRHF";
import { cleanupPinging, startPinging } from "./pinger";
import {
  logout,
  whoIAm,
  whoIAmFunction,
  logoutFunction,
  loginFunction,
  login,
} from "../repositories/userRepository";
import { BackgroundOperationProvider } from "Common/Backdrop/BackgroundOperationContext";
import { CompanyContext } from "./companyContext";
import { getCompany, getUserInfo } from "repositories/companyRepository";
import { Outlet } from "react-router-dom";
import { UserNameContext } from "./userNameContext";
import { LoginContext, LoginMethods } from "./loginContext";
import {
  clientSideCompanyConfig,
  getClientSideCompanyConfig,
} from "repositories/clientSideCompanyConfigRepository";
import { ConfigFromServerContext } from "./configFromServerContext";

function Main(props: {}) {
  // userName undefined - unknown yet, null - not logged in, string - user name
  let [userName, setUser] = useState<string | null | undefined>(undefined);
  const [companyId, setCompanyId] = useState<number | undefined>(undefined);
  const [configFromServer, setConfigFromServer] =
    useState<clientSideCompanyConfig | undefined>(undefined);

  const onLoggedIn = (userName: string) => {
    setUser(userName);
  };

  useEffect(() => {
    let testIfUserIsLoggedIn = async () => {
      const companyId = await getCompany();
      setCompanyId(companyId);

      const configFromServer = await getClientSideCompanyConfig();
      setConfigFromServer(configFromServer);

      let user: string | null = await whoIAm();
      if (user) {
        await getUserInfo();
      }
      setUser(user);
    };

    testIfUserIsLoggedIn();
    startPinging();

    return cleanupPinging;
  }, []);

  async function AppLogout() {
    await logout();
    setUser(null);
  }

  const loginMethods: LoginMethods = {
    login: login,
    logout: AppLogout,
    onLoggedIn: onLoggedIn,
  };

  return (
    <>
      <BackgroundOperationProvider>
        <CompanyContext.Provider value={companyId}>
          <ConfigFromServerContext.Provider value={configFromServer}>
            <UserNameContext.Provider value={userName}>
              <LoginContext.Provider value={loginMethods}>
                <StartScreen open={userName === undefined} />
                {userName !== undefined && <Outlet />}
              </LoginContext.Provider>
            </UserNameContext.Provider>
          </ConfigFromServerContext.Provider>
        </CompanyContext.Provider>
      </BackgroundOperationProvider>
    </>
  );
}

export default Main;
