import { Pane } from "../Common/FrontPage/pane";
import "../Common/FrontPage/frontPage.scss";
import "../Common/FrontPage/frontPagePerCompany.scss";
import { useContext } from "react";
import { CompanyContext } from "App/companyContext";
import { companyInfoMap } from "repositories/companyRepository";

export function Index() {

  const companyId = useContext(CompanyContext);
  const companyInfo = companyInfoMap.get(companyId ?? 0);
  if (companyInfo === undefined) throw new Error("Config error.");
  
  return (
    <div className="-mko-location-front-page">
      <h1>Biuro obsługi klienta {companyInfo.companyName}</h1>

      <div className="-mko-lfp-panes-container">
        <Pane link="/CustomerComplaint/index" text="Reklamacje" />
        <Pane link="/CableReel/index" text="Zwroty bębnów" />
        <Pane link="/InnerAccount/MyAccount" text="Moje konto" />
        <Pane link="/About/contact" text="Kontakt" />
      </div>
    </div>
  );
}
