import AppNavbar from "./Navbar/Navbar";
import { Outlet } from "react-router-dom";
import "./Layout.scss";
import Sidebar from "./Sidebar/Sidebar";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useContext } from "react";
import { CompanyContext } from "App/companyContext";
import { companyInfoMap } from "repositories/companyRepository";


export default function Layout(props: {
  userName: string;
  onLogoutClick: () => void;
}) {

  const companyId = useContext(CompanyContext);
  const companyInfo = companyInfoMap.get(companyId ?? 0);
  if (companyInfo === undefined) throw new Error("Config error.");

  
  return (
    <div className={`-app-main-layout ${companyInfo.className}`}>
      <AppNavbar
        onLogout={props.onLogoutClick}
        userName={props.userName}
      ></AppNavbar>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9 col-lg-10 order-md-2 -app-background-holder" id="content">
              <div id="content-innerWrapper" className="px-2 pb-3">
                <Outlet />
                <ToastContainer position="bottom-right" hideProgressBar={true}/>
              </div>
            </div>
            <div className="col-md-3 col-lg-2 order-md-1 g-0 pe-2">
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
