import { motion } from "framer-motion";
import LoadingRow from "./LoadingRow";

/**
 * Displays an animated loading indicator used in display and edit forms.
 * @returns 
 */
export function AnimatedLoadingRow(props: { isFullWidth?: boolean }) {
    return (<motion.div key="loading"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.5 }}
        exit={{ opacity: 0, transition: { duration: 0.1 } }}
    >
        <LoadingRow isFullWidth={props.isFullWidth} />
    </motion.div>);
}

export default AnimatedLoadingRow;
