import { MouseEventHandler } from "react";

/** Renders a bottom row of a details dialog, with the back button */
export function BackButtonRow(props: {
    /** onClick - when the back button is clicked */
    onClick: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
}) {
    const disabled: boolean = props.disabled === undefined ? false : props.disabled;
    return (
        <div className="row mt-5">
            <div className="col-sm-4 col-lg-2 offset-sm-4 mt-2">
                <button
                    className="btn btn-secondary py-2 px-4"
                    type="button"
                    onClick={props.onClick}
                    disabled={disabled}
                >
                    <i className="bi bi-box-arrow-right me-3"></i>
                    Wróć
                </button>
            </div>
        </div>
    );
}

export default BackButtonRow;
