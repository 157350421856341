export interface ViewModel {
  customerRepName: string;
  customerRepEMail: string;
  customerRepPhone: string;
  salesDocumentNumber: string;
  complaintDocumentTypeId: number | null;
  salesDate: Date | null;
  complaintItems: ComplaintItemViewModel[];
  attachments: AttachmentViewModel[];
}

export interface ComplaintServerVM {
  customerRepName: string;
  customerRepEMail: string;
  customerRepPhone: string;
  salesDocumentNumber: string;
  complaintDocumentTypeId: number | null;
  salesDate: Date | null;
  complaintItemVMs: ServerComplaintItemVM[];
  complaintAttachmentVMs: ServerAttachmentVM[];
}

export function complaintViewModelToServerModel(vm: ViewModel): ComplaintServerVM {
  const r: ComplaintServerVM = {
    customerRepName: vm.customerRepName,
    customerRepEMail: vm.customerRepEMail,
    customerRepPhone: vm.customerRepPhone,
    salesDocumentNumber: vm.salesDocumentNumber,
    complaintDocumentTypeId: vm.complaintDocumentTypeId,
    salesDate: vm.salesDate,
    complaintItemVMs: vm.complaintItems.map((x) =>
      complaintItemViewModelToServerModel(x)
    ),
    complaintAttachmentVMs: vm.attachments.map((x) =>
      attachmentViewModelToServerModel(x)
    ),
  };

  return r;
}

export interface AttachmentViewModel {
  id: string;
  originalFileName: string;
  name: string;
}

export interface ComplaintItemViewModel {
  lineCode: string| null;
  productName: string | null;
  catalogNumber: string | null;
  amount: number | null;
  complaintTypeId: number | null;
  comments: string | null;
}

/** The client and server VMs are the same */
type ServerComplaintItemVM = ComplaintItemViewModel;

function complaintItemViewModelToServerModel(
  item: ComplaintItemViewModel
): ServerComplaintItemVM {
  return item;
}

export interface ServerAttachmentVM {
  attachmentId: string;
  originalFileName: string;
  attachmentName: string;
}

function attachmentViewModelToServerModel(
  avm: AttachmentViewModel
): ServerAttachmentVM {
  const r: ServerAttachmentVM = {
    attachmentId: avm.id,
    originalFileName: avm.originalFileName,
    attachmentName: avm.name,
  };

  return r;
}
