import {
  Control,
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";
import Description from "../Description";
import FieldError from "../FieldError";
import SimplifiedFormRowProps from "../SimplifiedFormRowProps";

export interface CheckBoxProps {
  bodyText: string;
}

/**
 * Displays a edit form row with a input[type=checkbox]
 * @param props - SimplifiedFormRowProps, just id and what to display: label, inlineDescription, longDescription
 * @returns
 */
export default function CheckBoxFormRow<T extends FieldValues>(
  props: SimplifiedFormRowProps &
    CheckBoxProps &
    UseControllerProps<T> & { control: Control<T> }
) {
  const {
    field,
    fieldState: { isTouched, error },
  } = useController(props);

  return (
    <div className="row mb-3">
      <label
        className={`
        form-label  text-sm-end col-sm-4 ${!!error && "text-danger"}`}
        htmlFor={props.id}
        title={props.longDescription}
      >
        {props.label}
        {!!props.longDescription && (
          <i className="bi bi-question-circle -app-label-help"></i>
        )}
      </label>
      <div className="col-sm-8">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id={props.id}
            onChange={(e) => field.onChange(e.target.checked)} // send value to hook form
            onBlur={field.onBlur} // notify when input is touched/blur
            checked={field.value} // input value
            name={field.name} // send down the input name
            ref={field.ref} // send input ref, so we can focus on input when error appear
          />
          <label className="form-check-label" htmlFor={props.id}>
            {props.bodyText}
          </label>
        </div>
        <Description description={props.inlineDescription}></Description>
        <FieldError
          error={error?.message as string | null | undefined}
        ></FieldError>
      </div>
    </div>
  );
}
