import NoRouteErrorPage from "Navigation/no-route-error-page";
import {
  GenericWrapperWithIdNavigateAndBackUrl,
  GenericWrapperWithIdNavigateIsNewAndBackUrl,
  GenericWrapperWithNavigate,
  GenericWrapperWithNavigateAndBackUrl,
} from "../genericWrappers";
import { Contact } from "About/Contact";

const aboutRoutes = [
  {
    path: "About",
    children: [
      {
        path: "Contact",
        element: (
          <GenericWrapperWithNavigate
            key={"About/Contact"}
            component={Contact}
          />
        )
      },
      {
        path: "*",
        element: (
          <NoRouteErrorPage/>
        ),
      }
    ],
  },
];

export default aboutRoutes;
