import BooleanDetailsFormRow from "Common/DetailsForm/Presenter/BooleanDetailsFormRow";
import DetailsFormRow from "Common/DetailsForm/Presenter/DetailsFormRow";
import MultilineDetailsFormRow from "Common/DetailsForm/Presenter/MultilineDetailsFormRow";
import DateTimeDetailsFormRow, {
  date_format_dateOnly,
  date_format_dateTime,
} from "Common/DetailsForm/Presenter/DateTimeDetailsFormRow";
import NumberDetailsFormRow from "Common/DetailsForm/Presenter/NumberDetailsFormRow";
import GenericDetailsFormRow from "Common/DetailsForm/Presenter/GenericDetailsFormRow";
import { Link } from "react-router-dom";
import { viewModel } from "./viewModel";

export function DetailsPresenter(props: { object: viewModel }) {
  return (
    <>
      <div className="-app-display-section">
        <h2>Dane podstawowe</h2>
        <DetailsFormRow
          label="E-mail"
          value={props.object.email}
        ></DetailsFormRow>
        <DetailsFormRow
          label="Telefon"
          value={props.object.phoneNumber}
        ></DetailsFormRow>
        <DetailsFormRow
          label="Nazwa"
          value={props.object.clientName}
        ></DetailsFormRow>
        <DetailsFormRow
          label="Kod firmy"
          value={props.object.clientDaxCode}
        ></DetailsFormRow>
        <DetailsFormRow label="NIP" value={props.object.nip}></DetailsFormRow>
      </div>
    </>
  );
}

export default DetailsPresenter;
