import {
  Control,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { appendValidOrInvalidClassName } from "Common/EditForm/appendErrorClassNames";
import {
  maxLengthValidator,
  minLengthValidator,
  noWhitespacesOnlyValidator,
  trimmedStringValidator,
} from "../Validators/stringValidations";
import {
  multipleMailValidator,
  singleMailValidator,
} from "../Validators/eMailValidations";
import { patternValidator } from "../Validators/patternValidation";
import { CSSProperties } from "react";

export interface TextInputProps {
  required?: boolean;
  requiredErrorMessage?: string;
  /** The maximum length, there's no maxLenght error message  */
  maxLength: number;
  /** The minimum length, there's no maxLenght error message  */
  minLength?: number;
  minLengthErrorMessage?: string;
  /** Whether to allow strings with whitespaces on start and on end */
  trimmedString?: boolean;
  trimmedStringErrorMessage?: string;
  /* no whitespaces only */
  noWhitespacesOnly?: boolean;
  noWhitespacesOnlyErrorMessage?: string;
  /** Additional class namesto add to the input, other classes remain */
  className?: string;
  placeholder?: string;
  /** When set to true, an empty string will be automatically transformed to null, so that model will be `string | null` */
  transformEmptyStringToNull?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  email?: "single" | "multiple";
  emailErrorMessage?: string;
  /* A regex to validate the value */
  pattern?: string;
  patternErrorMessage?: string;
  /* Transforms value to uppercase, both when displaying and when returning. */
  uppercase?: boolean;
  /* Renders a password input instead of text */
  password?: boolean;
}

export default function TextInput<T extends FieldValues>(
  props: UseControllerProps<T> & TextInputProps
) {
  let localProps = { ...props };

  localProps.rules = { ...props.rules };
  if (!!props.required) {
    localProps.rules.required =
      props.requiredErrorMessage ?? "Pole jest wymagane.";
  }

  localProps.rules.validate = { ...props.rules?.validate };

  if (props.maxLength !== undefined) {
    localProps.rules.validate.maxLength = maxLengthValidator(props.maxLength);
  }

  if (props.minLength !== undefined) {
    localProps.rules.validate.minLenght = minLengthValidator(
      props.minLength,
      props.minLengthErrorMessage
    );
  }

  if (props.trimmedString !== undefined) {
    localProps.rules.validate.trimmedString = trimmedStringValidator(
      props.trimmedStringErrorMessage
    );
  }

  if (props.noWhitespacesOnly !== undefined) {
    localProps.rules.validate.noWhitespacesOnly = noWhitespacesOnlyValidator(
      props.noWhitespacesOnlyErrorMessage
    );
  }

  if (props.email === "single") {
    localProps.rules.validate.email = singleMailValidator(
      props.emailErrorMessage
    );
  }

  if (props.email === "multiple") {
    localProps.rules.validate.email = multipleMailValidator(
      props.emailErrorMessage
    );
  }

  if (props.pattern !== undefined) {
    localProps.rules.validate.pattern = patternValidator(
      props.pattern,
      props.patternErrorMessage
    );
  }

  let inputType: string = "text";

  if (props.password === true) {
    inputType = "password";
  }

  let style: CSSProperties = {};

  if (props.uppercase) {
    style = { textTransform: "uppercase" };
  }

  const {
    field,
    fieldState: { isTouched, error },
  } = useController(localProps);

  function outputTransform(value: string): string | null {
    let r: string | null = value;

    if (value === "" && props.transformEmptyStringToNull) r = null;

    if (value !== null && props.uppercase) {
      r = value.toLocaleUpperCase();
    }

    return r;
  }

  return (
    <input
      id={field.name}
      className={appendValidOrInvalidClassName(
        `form-control ${props.className ?? ""}`,
        error,
        isTouched
      )}
      style={style}
      maxLength={props.maxLength}
      type={inputType}
      placeholder={props.placeholder}
      disabled={props.disabled}
      readOnly={props.readOnly}
      onChange={(e) => field.onChange(outputTransform(e.target.value))} // send value to hook form
      onBlur={field.onBlur} // notify when input is touched/blur
      value={field.value === null ? "" : field.value} // input value
      name={field.name} // send down the input name
      ref={field.ref} // send input ref, so we can focus on input when error appear
    />
  );
}
