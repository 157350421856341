import httpGet from "./Http/httpGet";

let classMetadataCache: Map<string, object> = new Map<string, object>();

export default async function getClassMetadata(
  simpleTypeName: string
): Promise<object> {
  let r: object;

  if (classMetadataCache.has(simpleTypeName)) {
    r = classMetadataCache.get(simpleTypeName)!;
  } else {
    r = await httpGet<object>("/api/Infrastructure/ClassMetadata/" + simpleTypeName);
    classMetadataCache.set(simpleTypeName, r);
  }

  return r;
}
