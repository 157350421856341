import { multilineTextToHtml } from "Common/DetailsForm/Presenter/Helpers/multilineTextToHtml";
import Modal from "react-bootstrap/Modal";
import { confirmable, createConfirmation } from "react-confirm";

const Confirmation = (props: {
  okLabel: string;
  title: string;
  messageHTML: string;
  show: boolean;
  proceed: (isOk: boolean) => void;
  enableEscape: boolean;
}) => {
  return (
    <div className="static-modal">
      <Modal
        animation={true}
        show={props.show}
        onHide={() => props.proceed(false)}
        backdrop={props.enableEscape ? true : "static"}
        keyboard={props.enableEscape}
      >
        <Modal.Header>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body dangerouslySetInnerHTML={{ __html: props.messageHTML }}></Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            name="ok"
            className="btn btn-primary px-3 py-1"
            onClick={() => props.proceed(true)}
          >
            {props.okLabel}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

/**
 * Displays awaitable alert dialog.
 * @param title The dialog title
 * @param message The message inside, can contain newline sequence (\r\n).
 * @param okLabel 
 * @param cancelLabel 
 * @param options 
 * @returns Promise<boolean> resolved with true (ok) or false (dialog is dismissed).
 */
export function AlertDialog(
  title: string,
  message: string,
  okLabel: string = "OK",
  options: any = {}
): Promise<boolean> {
  let messageHTML = multilineTextToHtml(message) || "";
  return createConfirmation(confirmable(Confirmation))({
    title,
    messageHTML,
    okLabel,
    ...options,
  }) as Promise<boolean>;
}
