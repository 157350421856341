/** Retuns the client-side configuration for  the company the application is serving, based on from where the request comes */
export async function getClientSideCompanyConfig(): Promise<clientSideCompanyConfig> {
  let response = await fetch("/api/ClientConfig");
  if (!response.ok) throw new Error("Response error.");
  let r = <clientSideCompanyConfig>await response.json();
  return r;
}

export interface clientSideCompanyConfig {
  termsAndConditionsUrl: string;
  privacyPolicyUrl: string;
  isTestEnvironment: boolean;
}
