const EmailRegexPattern: RegExp =
  /^(?![_.-])((?![_.-][_.-])[a-zA-Z\d_.-]){0,63}[a-zA-Z\d]@((?!-)((?!--)[a-zA-Z\d-]){0,63}[a-zA-Z\d]\.){1,2}([a-zA-Z]{2,14}\.)?[a-zA-Z]{2,14}$/;

export function validateEmail(email: string | null): boolean {
  if (email === null) return true;
  return EmailRegexPattern.test(email);
}

export function validateMultipleEmails(emails: string | null): boolean {
  if (emails === null) return true;
  if (emails !== emails.trim()) return false;
  if (emails.endsWith(",")) return false;

  const emailArray: string[] = emails.split(",");

  if (emailArray.some((x) => !x.trim())) return false;

  if (emailArray.length === 0) return false;

  let result = true;

  for (const email of emailArray) {
    result = result && validateEmail(email);
  }

  return result;
}

/**
 * Validator that checks whether the value is a valid e-mail address.
 * @param message Optional custom message to display
 * @returns The error message or undefined if there's no error
 */
export function singleMailValidator(
  message?: string
): (value: string | null) => string | undefined {
  return (value) => {
    let errorMessage = undefined;

    if (typeof value === "string" && !validateEmail(value)) {
      errorMessage = message || `Podaj poprawny adres e-mail.`;
    }

    return errorMessage;
  };
}

/**
 * Validator that checks whether the value is a a serie of comma-separated e-mail addresses, without spaces between.
 * @param message Optional custom message to display
 * @returns The error message or undefined if there's no error
 */
export function multipleMailValidator(
    message?: string
  ): (value: string | null) => string | undefined {
    return (value) => {
      let errorMessage = undefined;
  
      if (typeof value === "string" && !validateMultipleEmails(value)) {
        errorMessage = message || `Podaj poprawny adres e-mail lub wiele adresów, rozdzielonych przecinkiem bez spacji.`;
      }
  
      return errorMessage;
    };
  }
  