import HeaderLink from "./HeaderLink";
import PlainLink from "./PlainLink";

export function CustomerComplaintFragment() {
  return (
    <>
      <HeaderLink to="/CustomerComplaint/index">Reklamacje</HeaderLink>

      <PlainLink to="/CustomerComplaint/new">Nowa reklamacja</PlainLink>
    </>
  );
}
