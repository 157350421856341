import { ComplaintItemViewModel } from "CustomerComplaint/New/ViewModel";
import getComplaintTypes from "CustomerComplaint/New/getComplaintTypes";
import { formatDecimalNumber } from "Common/DetailsForm/Presenter/Helpers/formatDecimalNumber";
import { limitedText } from "Common/DetailsForm/Presenter/Helpers/limitedText";
import { useEffect, useState } from "react";

export default function ComplaintItemDisplayFragment(props: {
  items: ComplaintItemViewModel[];
  onRemoveItem: (index: number) => void;
    onEditItem: (index: number) => void;
    onNewItem: () => void;
}) {
  let [complaintTypeIdToNameMap, setComplaintTypeIdToNameMap] = useState<
    Map<number, string>
  >(new Map<number, string>());

  const onInitialize = async () => {
    let retrievedComplaintTypes = await getComplaintTypes();
    setComplaintTypeIdToNameMap(
      new Map(retrievedComplaintTypes.map((x) => [x.id, x.name]))
    );
  };

  useEffect(() => {
    onInitialize();
  }, []);

  return (
    <>
      {props.items.length > 0 && (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Poz</th>
              <th>Kod pozycji</th>
              <th>Nazwa towaru</th>
              <th>Numer katalogowy</th>
              <th className="text-center">Ilość do reklamacji</th>
              <th>Rodzaj reklamacji</th>
              <th>Uwagi</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props.items.map((item, index) => (
              <tr key={index}>
                <td className="text-end">{index + 1}</td>
                <td>{(item as ComplaintItemViewModel).lineCode}</td>
                <td>{(item as ComplaintItemViewModel).productName}</td>
                <td>{(item as ComplaintItemViewModel).catalogNumber}</td>
                <td className="text-end">
                  {formatDecimalNumber(
                    (item as ComplaintItemViewModel).amount!,
                    4
                  )}
                </td>
                <td>
                  {complaintTypeIdToNameMap.get(
                    (item as ComplaintItemViewModel).complaintTypeId!
                  ) ?? ""}
                </td>
                <td>
                  {limitedText(
                    (item as ComplaintItemViewModel).comments || "",
                    30
                  )}
                </td>
                <td>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      props.onRemoveItem(index);
                    }}
                    href="#"
                    className="link-danger me-3 small text-decoration-none"
                    title="Kliknij, aby usunąć tą pozycję z formularza."
                  >
                    <i className="bi bi-trash3 ps-2"></i> Usuń
                  </a>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      props.onEditItem(index);
                    }}
                    href="#"
                    className="link-primary small text-decoration-none"
                    title="Kliknij, aby zmienić zawartość tej pozycji."
                  >
                    <i className="bi bi-pencil-square"></i> Edytuj
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {props.items.length === 0 && (
        <div className="row mb-3">
          <div className="col-sm-12">
            <div
              className="alert alert-primary d-flex align-items-center"
              role="alert"
            >
              <div className="flex-shrink-0 ms-3 me-4">
                <i className="bi bi-lightbulb"></i>
              </div>
              <div>
                <b>Brak pozycji</b>
                <br />
                Przed wysłaniem zgłoszenia musisz dodać co najmniej jedną
                pozycję, której dotyczy zgłoszenie.
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row mb-3">
        <div className="col-sm-12">
          <button
            type="button"
            onClick={(e) => {
              props.onNewItem();
            }}
            className={`btn btn-success py-1 ps-4 pe-5`}
          >
            <i className="bi bi-plus-circle ps-2 me-2"></i> Dodaj pozycję
          </button>
        </div>
      </div>
    </>
  );
}
