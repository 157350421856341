import { motion } from "framer-motion";
import { ReactNode } from "react";

/**
 * Animates the actual content of a form.
 * @param props 
 * @returns 
 */
export function ContentAnimation(props: { children: ReactNode }) {
  return (
    <motion.div
      key="data"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2, delay: 0.1 }}
      exit={{ opacity: 0, transition: { duration: 0.0 } }}
    >
      {props.children}
    </motion.div>
  );
}
