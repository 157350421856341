import {
  Control,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { appendValidOrInvalidClassName } from "Common/EditForm/appendErrorClassNames";
import DatePicker from "react-date-picker";
import { maxDateValidator, minDateValidator } from "../Validators/dateTimeValidations";
import { DateTime } from "luxon";

export interface DateInputProps {
  required?: boolean;
  requiredErrorMessage?: string;
  /** The maximum date, there's no customized error message because the error should never appear.  */
  maxDate?: Date;
  /** The minimum date, there's no customized error message because the error should never appear.  */
  minDate?: Date;
  disabled?: boolean;
  /** No earlier than the specified number of days from Today */
  noEarlierThan?: number;
}

export default function DateInput<T extends FieldValues>(
  props: UseControllerProps<T> & DateInputProps
) {
  let localProps = { ...props };

  localProps.rules = { ...props.rules };
  if (!!props.required) {
    localProps.rules.required =
      props.requiredErrorMessage ?? "Pole jest wymagane.";
  }



  localProps.rules.validate = { ...props.rules?.validate };

  if (props.maxDate !== undefined) {
    localProps.rules.validate.maxDate = maxDateValidator(props.maxDate);
  }


  let actualMinDate = getEffectiveMinDate(props.minDate, props.noEarlierThan, DateTime.now().startOf('day'));

  if (actualMinDate !== undefined) {
    localProps.rules.validate.minDate = minDateValidator(actualMinDate);
  }

  const {
    field,
    fieldState: { isTouched, error },
  } = useController(localProps);

  return (
    <DatePicker
      disabled={props.disabled}
      onChange={(date: Date) => {
        field.onChange(date);
        field.onBlur();
      }}
      minDate={actualMinDate}
      maxDate={props.maxDate}
      value={field.value}
      format="yyyy-MM-dd"
      name={field.name}
      inputRef={field.ref}
      required={props.required}
      clearIcon={props.required ? null : undefined}
    />
    /*
    <input
      id={field.name}
      className={appendValidOrInvalidClassName(
        `form-control ${props.className ?? ""}`,
        error,
        isTouched
      )}
      maxLength={props.maxLength}
      type="text"
      placeholder={props.placeholder}
      onChange={field.onChange} // send value to hook form
      onBlur={field.onBlur} // notify when input is touched/blur
      value={field.value} // input value
      name={field.name} // send down the input name
      ref={field.ref} // send input ref, so we can focus on input when error appear
    /> */
  );
}

/** Calculates the larger from minDate or noEarlierThanNDays (from today). Today is luxon DateTime the current date without the time part. */
export function getEffectiveMinDate(minDate: Date | undefined, noEarlierThanNDays: number | undefined, today: DateTime): Date | undefined {
  let r = minDate;
  // override minDate if needed
  if (noEarlierThanNDays !== undefined) {
    let tmpMinDate = today.plus({ days: noEarlierThanNDays }).toJSDate();
    if (!r || r < tmpMinDate) {
      r = tmpMinDate;
    }

    return r;
  }
}