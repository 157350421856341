import { createRef, useContext, useEffect, useRef, useState } from "react";
import { ViewModel, toServerVM, viewModelNewValues } from "./viewModel";
import { FormProvider, useForm } from "react-hook-form";
import { httpPost } from "Common/Http/httpPost";
import getClassMetadata from "Common/classMetadataService";
import LoadingContentWrapper from "Common/Loading/LoadingContentWrapper";
import TextInputFormRow from "Common/EditForm/TextInput/TextInputFormRow";
import getTextMetadata from "Common/EditForm/ClassMetadata/getTextMetadata";
import SelectFormRow from "Common/EditForm/Select/SelectFormRow";
import SaveError from "./SaveError";
import SaveSuccess from "./SaveSuccess";
import { AccountCreationDataServerVM } from "./accountCreationDataServerVM";
import getRegistrationBranches, {
  GetRegistrationBranchesResult,
} from "./getRegistrationBranches";
import { CompanyContext } from "App/companyContext";
import { companyInfoMap } from "repositories/companyRepository";
import ReCAPTCHA from "react-google-recaptcha";
import httpGet from "Common/Http/httpGet";
import FormButtons from "Common/EditForm/formButtons";
import { useNavigate } from "react-router-dom";
import Label from "Common/EditForm/Label";
import TextInput from "Common/EditForm/TextInput/TextInput";
import FieldError from "Common/EditForm/FieldError";
import DecimalInput from "Common/EditForm/DecimalInput/DecimalInputReactNumberFormat";
import getDecimalMetadata from "Common/EditForm/ClassMetadata/getDecimalMetadata";
import { validateDocumentNumber } from "CustomerComplaint/New/helpers/documentNumberValidation";

export default function RegisterForm(props: {}) {
  let [isLoading, setIsLoading] = useState<boolean>(true);
  let [isSaveError, setIsSaveError] = useState<boolean>(false);
  let [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
  let [isSaveSuccessful, setIsSaveSuccessful] = useState<boolean>(false);
  let [registrationBranches, setRegistrationBranches] =
    useState<GetRegistrationBranchesResult | null>(null);
  let [metadata, setMetadata] = useState<{ [key: string]: any }>({});
  let [serverSideError, setServerSideError] = useState<string>("");
  let [captchaValue, setCaptchaValue] = useState<string | null>(null);
  let [captchaSiteKey, setCaptchaSiteKey] = useState<string>("");

  const companyId = useContext(CompanyContext);
  const companyInfo = companyInfoMap.get(companyId ?? 0);
  if (companyInfo === undefined) throw new Error("Config error.");

  const navigate = useNavigate();
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const methods = useForm<ViewModel>({
    mode: "onTouched",
    defaultValues: viewModelNewValues,
  });

  const { handleSubmit, formState, control, getValues, watch, trigger, reset } =
    methods;

  const { errors, isSubmitting } = formState;

  const onSubmit = async (values: ViewModel) => {
    // let vmToSave = Object.assign({}, viewModel, values); // if values contain all the necessary data, this step will be unnecessary
    let data = toServerVM(values);

    data.captchaValue = captchaValue;

    try {
      let error = await httpPost<AccountCreationDataServerVM, string>(
        "/api/Account/Register",
        data
      );

      setServerSideError(error);

      if (error === "") {
        setIsSaveSuccessful(true);
      } else {
        recaptchaRef.current?.reset();
      }
    } catch (e) {
      setIsSaveError(true);
    }

    return;
  };

  const onInitialize = async () => {
    // load form values
    // fetch existing data for the form
    // load data for selects, etc.
    // this method is async so we can use await here

    let retrievedRegistrationBranches = await getRegistrationBranches();
    setRegistrationBranches(retrievedRegistrationBranches);

    let metadata_response = await getClassMetadata("AccountCreationDataVM");
    setMetadata(metadata_response);

    let captchaKey = await httpGet<string>("/api/Account/GetCaptchaSiteKey");
    setCaptchaSiteKey(captchaKey);

    let initialValues = Object.assign({}, viewModelNewValues);

    if (retrievedRegistrationBranches.branches.length == 1) {
      initialValues.registrationBranchId =
        retrievedRegistrationBranches.branches[0].value;
    }

    reset(initialValues);

    setIsLoading(false);
  };

  useEffect(() => {
    onInitialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCaptchaChange = (token: string | null) => {
    setCaptchaValue(token);
    setIsSaveEnabled(!!token);
  };

  // we want to trigger revalidation of invoiceAmount on every change of invoiceCode and other way round
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "invoiceNumber") {
        // only if the field is touched or already submitted: this makes sense only when it is a new form
        // Note: isSubmitted becomes true after the user attempts to submit, even if the validation fails; after that the validation messages are displayed and the field shoud be revalidated
        if (formState.touchedFields.invoiceAmount || formState.isSubmitted) {
          trigger("invoiceAmount");
        }
      }

      if (name === "invoiceAmount") {
        // only if the field is touched or already submitted: this makes sense only when it is a new form
        // Note: isSubmitted becomes true after the user attempts to submit, even if the validation fails; after that the validation messages are displayed and the field shoud be revalidated
        if (formState.touchedFields.invoiceNumber || formState.isSubmitted) {
          trigger("invoiceNumber");
        }
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, formState]);

  return (
    <>
      <div className="container" style={{ maxWidth: 720 }}>
        <div className="content-wrapper">
          <h1 className="border-bottom pb-2 mt-4 mb-5">
            Rejestracja w {companyInfo.companyName}
          </h1>
          <LoadingContentWrapper isLoading={isLoading} isFullWidth={true}>
            {!isLoading && !isSaveError && !isSaveSuccessful && (
              <FormProvider {...methods}>
                <form noValidate>
                  <TextInputFormRow
                    {...getTextMetadata<ViewModel>("email", metadata["email"])}
                    label="Adres e-mail"
                    control={control}
                  />

                  <TextInputFormRow
                    {...getTextMetadata<ViewModel>(
                      "daxCode",
                      metadata["daxCode"]
                    )}
                    label="Numer klienta"
                    control={control}
                    pattern="\d+"
                    patternErrorMessage="Numer klienta składa się wyłacznie z cyfr."
                  />

                  <TextInputFormRow
                    {...getTextMetadata<ViewModel>(
                      "nip",
                      metadata["phoneNumber"]
                    )}
                    label="Nip"
                    control={control}
                    pattern="\d{10}"
                    patternErrorMessage="Podaj numer NIP jako 10 cyfr, bez pauz i innych znaków."
                  />

                  <TextInputFormRow
                    {...getTextMetadata<ViewModel>(
                      "phoneNumber",
                      metadata["phoneNumber"]
                    )}
                    label="Numer telefonu"
                    control={control}
                  />

                  {registrationBranches?.showSelect && (
                    <SelectFormRow
                      {...getTextMetadata<ViewModel>(
                        "registrationBranchId",
                        metadata["registrationBranchId"]
                      )}
                      label="Oddział"
                      control={control}
                      options={registrationBranches?.branches}
                      addNullOption={true}
                      valueIsNumber={true}
                    />
                  )}

                  <div className="row mb-1">
                    {/* We have mb-1, unlike in other rows, because alert at the bottom adds its own margin. */}
                    <Label
                      label="Numer / kwota faktury"
                      id="invoiceNumber"
                      isError={
                        !!errors["invoiceNumber"] || !!errors["invoiceAmount"]
                      }
                    ></Label>
                    <div className="col-sm-8">
                      <div className="row">
                        <div className="col-sm-6">
                          <TextInput
                            {...getTextMetadata<ViewModel>(
                              "invoiceNumber",
                              metadata["invoiceNumber"]
                            )}
                            control={control}
                            className=""
                            placeholder="FV..."
                            rules={{
                              validate: {
                                bothNumberAndAmount: (v: any) => {
                                  // validaton between fields - with useForm/getValues()
                                  const invoiceNumber =
                                    getValues("invoiceNumber");
                                  const invoiceAmount =
                                    getValues("invoiceAmount");
                                  const invoiceNumberHasValue =
                                    !!invoiceNumber && !!invoiceNumber.trim();
                                  const invoiceAmountHasValue =
                                    invoiceAmount !== null;
                                  return !invoiceNumberHasValue &&
                                    invoiceAmountHasValue
                                    ? "Jeśli podałeś kwotę faktury, musisz również jej numer."
                                    : undefined;
                                },
                                numberingSchemaMatchesDocumentAndCompany: (
                                  v: any
                                ) => {
                                  // shortcut for empty field
                                  if (!v) return undefined;
                                  // validaton between fields - with useForm/getValues()
                                  return validateDocumentNumber(
                                    v,
                                    companyId!,
                                    1
                                  );
                                },
                              },
                            }}
                          />
                          <FieldError
                            error={
                              errors["invoiceNumber"]?.message as
                                | string
                                | null
                                | undefined
                            }
                          ></FieldError>
                        </div>
                        <div className="col-sm-6 gy-2 gy-sm-0">
                          <DecimalInput
                            {...getDecimalMetadata<ViewModel>(
                              "invoiceAmount",
                              metadata["invoiceAmount"]
                            )}
                            control={control}
                            className=""
                            maxLength={18}
                            rules={{
                              validate: {
                                bothNumberAndAmount: (v: any) => {
                                  // validaton between fields - with useForm/getValues()
                                  const invoiceNumber =
                                    getValues("invoiceNumber");
                                  const invoiceAmount =
                                    getValues("invoiceAmount");
                                  const invoiceNumberHasValue =
                                    !!invoiceNumber && !!invoiceNumber.trim();
                                  const invoiceAmountHasValue =
                                    invoiceAmount !== null;
                                  return invoiceNumberHasValue &&
                                    !invoiceAmountHasValue
                                    ? "Jeśli podałeś numer faktury, musisz również podać kwotę."
                                    : undefined;
                                },
                              },
                            }}
                          />
                          <FieldError
                            error={
                              errors["invoiceAmount"]?.message as
                                | string
                                | null
                                | undefined
                            }
                          ></FieldError>
                        </div>
                      </div>
                      <div className="mt-3">
                        <div
                          className="alert alert-primary d-flex align-items-center"
                          role="alert"
                        >
                          <div className="flex-shrink-0 ms-3 me-4">
                            <i className="bi bi-lightbulb"></i>
                          </div>
                          <div>
                            <small>
                              Aby zweryfikować tożsamość, podaj numer i kwotę
                              netto dowolnej faktury od nas z ostatniego roku,
                              lecz nie dzisiejszej.
                              <br />
                              Podanie tych danych nie jest wymagane, jednak bez
                              nich Twoje zgłoszenie będzie musiało czekać na
                              zweryfikowanie przez naszego pracownika.
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <TextInputFormRow
                    {...getTextMetadata<ViewModel>(
                      "password",
                      metadata["password"]
                    )}
                    password={true}
                    label="Hasło"
                    control={control}
                    inlineDescription="Hasło musi mieć co najmniej 6 znaków oraz zawierać przynajmniej jedną wielką i jedną małą literę."
                  />

                  <TextInputFormRow
                    {...getTextMetadata<ViewModel>(
                      "repeatedPassword",
                      metadata["password"]
                    )}
                    password={true}
                    label="Powtórz hasło"
                    control={control}
                    rules={{
                      validate: {
                        passwordMatch: (v: any) => {
                          // validaton between fields - with useForm/getValues()
                          const password = getValues("password");
                          const repeatedPassword = v;
                          return password != repeatedPassword
                            ? "Hasło i powtórzone hasło nie zgadzają się."
                            : undefined;
                        },
                      },
                    }}
                  />

                  {serverSideError !== "" && (
                    <div className="row pt-2">
                      <div className="offset-sm-4 col-sm-8">
                        <span className="text-danger mt-1">
                          {serverSideError}
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="row pt-3">
                    <div className="offset-sm-4 col-sm-8">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={captchaSiteKey}
                        onChange={onCaptchaChange}
                      />
                    </div>
                  </div>
                  <div className="row pt-2 pb-5">
                    <div className="offset-sm-4 col-sm-8">
                      <FormButtons
                        onSave={(e) => handleSubmit(onSubmit)(e)}
                        onCancel={(e) => navigate("/")}
                        isSubmitting={isSubmitting}
                        isOkDisabled={!isSaveEnabled}
                        saveText="Załóż konto"
                      ></FormButtons>
                    </div>
                  </div>
                </form>
              </FormProvider>
            )}
            {isSaveError && <SaveError />}
            {isSaveSuccessful && <SaveSuccess />}
          </LoadingContentWrapper>
        </div>
      </div>
    </>
  );
}
