import HeaderLink from "./HeaderLink";
// import PlainLink from "./PlainLink";

export function StartFragment() {
  return (
    <>
      <HeaderLink to="/CustomerComplaint/index">Reklamacje</HeaderLink>
      <HeaderLink to="/CableReel/index">Zwroty bębnów</HeaderLink>
      <HeaderLink to="/InnerAccount/myAccount">Moje konto</HeaderLink>
      <HeaderLink to="/About/contact">Kontakt</HeaderLink>
    </>
  );
}
