import {
  Control,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { appendValidOrInvalidClassName } from "Common/EditForm/appendErrorClassNames";
import {
  decimalPlacesValidator,
  greaterThanOrEqualValidator,
  greaterThanValidator,
  lessThanOrEqualValidator,
  lessThanValidator,
  numberValidator,
  separators,
} from "Common/EditForm/Validators/directNumberValidations";
import { NumericFormat } from "react-number-format";

export interface DecimalInputProps {
  required?: boolean;
  requiredErrorMessage?: string;
  /** Error message displayed when the value is not a number */
  numberErrorMessage?: string;
  decimalPlaces?: number;
  decimalPlacesErrorMessage?: string;
  maxLength?: number;
  greaterThanOrEqual?: number;
  greaterThan?: number;
  lessThanOrEqual?: number;
  lessThan?: number;
  /** Class names to replace the default sizing class -app-number-input, other classes remain */
  className?: string;
  /** Always display the fixed number of decimal places defined in decimalPlaces*/
  fixedDecimalScale?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
}

/**
 * Displays an editor that returns number or null if the value is not present.
 * @param props
 * @returns
 */
export default function DecimalInput<T extends FieldValues>(
  props: UseControllerProps<T> & DecimalInputProps
) {
  let localProps = { ...props };

  localProps.rules = { ...props.rules };
  if (!!props.required) {
    localProps.rules.required =
      props.requiredErrorMessage ?? "Pole jest wymagane.";
  }

  localProps.rules.validate = { ...props.rules?.validate };

  localProps.rules.validate.number = numberValidator(
    props.numberErrorMessage ?? "Musisz wprowadzić liczbę."
  );

  if (props.decimalPlaces !== undefined) {
    localProps.rules.validate.decimalPlaces = decimalPlacesValidator(
      props.decimalPlaces, props.decimalPlacesErrorMessage
    );
  }

  if (props.greaterThanOrEqual !== undefined) {
    localProps.rules.validate.greaterThanOrEqual = greaterThanOrEqualValidator(
      props.greaterThanOrEqual
    );
  }

  if (props.greaterThan !== undefined) {
    localProps.rules.validate.greaterThan = greaterThanValidator(
      props.greaterThan
    );
  }

  if (props.lessThanOrEqual !== undefined) {
    localProps.rules.validate.lessThanOrEqual = lessThanOrEqualValidator(
      props.lessThanOrEqual
    );
  }

  if (props.lessThan !== undefined) {
    localProps.rules.validate.lessThan = lessThanValidator(props.lessThan);
  }

  const {
    field,
    fieldState: { isTouched, error },
  } = useController(localProps);

  let isNegativeAllowed: boolean = true;
  if (
    (props.greaterThan !== undefined && props.greaterThan >= 0) ||
    (props.greaterThanOrEqual !== undefined && props.greaterThanOrEqual >= 0)
  ) {
    isNegativeAllowed = false;
  }

  let sizeClassNames = props.className === undefined ? "-app-number-input" : props.className;

  return (
    <NumericFormat
      id={field.name}
      className={appendValidOrInvalidClassName(
        `form-control ${sizeClassNames}`,
        error,
        isTouched
      )}
      maxLength={props.maxLength || 12}
      onValueChange={(values, sourceInfo) => {
        field.onChange(
          values.floatValue === undefined ? null : values.floatValue
        );
      }}
      onBlur={field.onBlur}
      value={field.value === null ? '' : field.value}
      name={field.name}
      getInputRef={field.ref}
      thousandSeparator={separators[0]}
      decimalSeparator={separators[1]}
      decimalScale={props.decimalPlaces}
      fixedDecimalScale={props.fixedDecimalScale || false}
      allowNegative={isNegativeAllowed}
      disabled={props.disabled}
      readOnly={props.readOnly}
    />
  );
}
