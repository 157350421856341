import { useCallback, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { RowDoubleClickedEvent } from "ag-grid-community";

import "./index.scss";
import { localeText_pl } from "../../../Common/AgGridHelpers/locale_pl";
import { columnDefs, defaultColDef } from "./columnDefinitions";
import GridLoadingIndicator from "Common/AgGridHelpers/GridLoadingIndicator";
import httpGet from "Common/Http/httpGet";

import { DateTime } from "luxon";
import { returnRequestListItemVM } from "./returnRequestListItemVM";
import { useSearchParams } from "react-router-dom";
import { useResizeDetector } from "react-resize-detector";
import { PreventSubmitInputs } from "Common/PreventSubmitInputs";
import VhMeasureDiv from "Common/AgGridHelpers/VhMeasureDiv";

export default function Index(props: { navigate: (url: string) => void }) {
  const gridRef = useRef<any>();

  // #region Grid resizing support

  const gridWraperRef = useRef<HTMLDivElement>(null); // the wrapper div for grid
  const vhMeasureRef = useRef<HTMLDivElement>(null); // the invisible element to measure the screen height
  const { height } = useResizeDetector({
    // refreshMode: "debounce",
    // refreshRate: 200,
    handleWidth: false,
    targetRef: vhMeasureRef,
  }); // watch the sice of the screen height div

  const minGridHeight = 300; // the grid won't be smaller than this
  const gridPixelsToSubstract = 16; // bottom margin to correct imprecise calculations
  let gridHeight = minGridHeight;
  if (!!gridWraperRef.current && !!height) {
    gridHeight =
      height -
      gridPixelsToSubstract -
      gridWraperRef.current.getBoundingClientRect().top;
    if (gridHeight < minGridHeight) gridHeight = minGridHeight;
  }

  // this will be called every time the width of grid changes, except the first time, when the compontent mounts
  const onGridWidthChange = useCallback(() => {
    const { api } = gridRef.current;
    if (api != null) {
      // setTimeout guards us against the warning: Warning: Cannot update a component (`HeaderCellComp`) while rendering a different component...
      setTimeout(() => api.sizeColumnsToFit(), 0);
    }
  }, []);

  // the second resize detector for width
  // note that it has different settings, i.e. it monitors the grid wrapper div and it triggers only on subsequent changes
  useResizeDetector({
    handleHeight: false,
    skipOnMount: true,
    refreshMode: "debounce",
    refreshRate: 500,
    onResize: onGridWidthChange,
    targetRef: gridWraperRef,
  });

  // #endregion

  const [dataObject, setDataObject] =
    useState<returnRequestListItemVM[] | null>(null);
  const [searchExpression, setSearchExpression] = useState<string>("");

  let [searchParams] = useSearchParams();
  let isActiveString = searchParams.get("isActive");
  let isActive: boolean | undefined = undefined;
  if (isActiveString) {
    if (isActiveString.toLocaleLowerCase() === "true") isActive = true;
    if (isActiveString.toLocaleLowerCase() === "false") isActive = false;
  }
  let isOfferRejectedString = searchParams.get("isOfferRejected");
  let isOfferRejected: boolean | undefined = undefined;
  if (isOfferRejectedString) {
    if (isOfferRejectedString.toLocaleLowerCase() === "true")
      isOfferRejected = true;
    if (isOfferRejectedString.toLocaleLowerCase() === "false")
      isOfferRejected = false;
  }

  useEffect(() => {
    let url = "/api/CableReels/ReturnRequest/ClientList";
    if (isActive !== undefined) {
      url += "?isActive=" + isActive;
    }
    if (isOfferRejected !== undefined) {
      url += "?isOfferRejected=" + isOfferRejected;
    }

    const setData = async () => {
      let a = await httpGet<returnRequestListItemVM[]>(url);
      // fill the JS native Date column with the date-only part of the date
      a.forEach((item) => {
        item.dateCreatedAsJsDateOnly = DateTime.fromISO(item.dateCreated)
          .startOf("day")
          .toJSDate();
        item.transportDateAsJsDateOnly = DateTime.fromISO(item.transportDate)
          .startOf("day")
          .toJSDate();
        item.dateClosedJsDateOnly = item.dateClosed
          ? DateTime.fromISO(item.dateClosed).startOf("day").toJSDate()
          : null;
      });
      setDataObject(a);
    };

    setData();
  }, [isActive, isOfferRejected]);

  // if (dataObject.length === 0) return <Loading></Loading>;

  // quick filter, the input is a controlled input
  const onSearchExpressionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchExpression(e.target.value);
    const { api } = gridRef.current;
    if (api == null) {
      return;
    }
    api.setQuickFilter(e.target.value);
  };

  let titleSuffix = "wszystkie";
  if (isActive !== undefined) {
    titleSuffix = isActive ? "aktywne" : "zamknięte";
  }

  if (isOfferRejected === true) {
    titleSuffix = "odrzucone";
  }

  return (
    <>
      <h1 className="border-bottom pb-2 mt-4 mb-4">
        Zgłoszenia zwrotu - {titleSuffix}
      </h1>
      <PreventSubmitInputs />
      <div className="-page-action-form mb-3">
        <div className="-page-actions">
          <button
            type="button"
            className="btn btn-success py-1 me-2 ps-4 pe-5"
            title="Utwórz nowe zgłoszenie zwrotu"
            style={{ marginTop: "8px" }}
            onClick={() => {
              props.navigate("./../new");
            }}
          >
            <i className="bi bi-plus-circle ps-2 me-2"></i>Nowe zgłoszenie
          </button>
        </div>
        <div className="-page-search">
          <div className="input-group">
            <input
              className="form-control border-end-0"
              type="text"
              id="search"
              name="search"
              placeholder="znajdź..."
              value={searchExpression}
              onChange={onSearchExpressionChange}
            />
            <div className="input-group-text bg-white">
              <i className="bi bi-search"></i>
            </div>
          </div>
        </div>
      </div>

      <div
        className="ag-theme-alpine -page-grid"
        style={{ width: "100%", height: gridHeight + "px" }}
        ref={gridWraperRef}
      >
        <AgGridReact
          ref={gridRef}
          rowData={dataObject}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          rowSelection="single"
          animateRows={true}
          localeText={localeText_pl}
          suppressCellFocus={true}
          suppressRowClickSelection={true}
          onRowDoubleClicked={(event: RowDoubleClickedEvent): void =>
            props.navigate("./../details/" + event.data.id)
          }
          loadingOverlayComponent={GridLoadingIndicator}
        ></AgGridReact>
      </div>
      <VhMeasureDiv ref={vhMeasureRef} />
    </>
  );
}

function onGridReady(params: any) {
  params.api.sizeColumnsToFit();
}
