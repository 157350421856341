import { Fade } from "react-bootstrap";

export default function LoadingRow(props: { isFullWidth?: boolean }) {
  let className: string = "col-sm-8 offset-sm-2";
  if (props.isFullWidth === true) className = "col-sm-12";

  return (
    <div className="row my-5">
      <div className={className}>
        <div
          className="alert alert-primary d-flex align-items-center"
          role="alert"
        >
          <div
            className="spinner-border text-primary flex-shrink-0 me-3"
            role="status"
          >
            <span className="visually-hidden">Wczytywanie...</span>
          </div>
          <div>Wczytywanie danych...</div>
        </div>
      </div>
    </div>
  );
}
