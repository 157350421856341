import { formatDecimalNumber } from "Common/DetailsForm/Presenter/Helpers/formatDecimalNumber";
import "./ComplaintItemDisplayFragment.scss";
import { complaintAttachmentDisplayVM } from "./complaintAttachmentDisplayVM";
import { Link } from "react-router-dom";

export default function ComplaintAttachmentDisplayFragment(props: {
  items: complaintAttachmentDisplayVM[];
}) {
  return (
    <>
      <ol>
        {props.items.map((item, index) => (
          <li>
            <a
              href={"/api/Complaints/AttachmentDownload/Open/" + item.id}
              target="_blank"
              title="Otwórz plik"
            >
              {item.attachmentName}
            </a>
            &nbsp;
            <a
              href={"/api/Complaints/AttachmentDownload/Download/" + item.id}
              title="Pobierz plik"
            >
              <i className="bi bi-file-earmark-arrow-down-fill"></i>
            </a>
          </li>
        ))}
      </ol>
    </>
  );
}
