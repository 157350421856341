import { appendTextDangerClassName } from "Common/EditForm/appendErrorClassNames";

export default function Label(props: {
  label: string;
  id: string;
  isError: boolean;
  description?: string;
}) {
  return (
    <label
      className={`
        form-label col-form-label text-sm-end col-sm-4 ${
          props.isError && "text-danger"
        }`}
      htmlFor={props.id}
      title={props.description}
    >
      {props.label}
      {!!props.description && (
        <i className="bi bi-question-circle -app-label-help"></i>
      )}
    </label>
  );
}
