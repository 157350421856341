import "./LoginButton.scss";

export default function LoginButton(props: {
  isLoading: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <button
      className="btn btn-primary btn-large py-2 px-4 -app-4C01AA0B-E9B8-40B9-816B-1DDBE5C049A1"
      type="submit"
      disabled={props.isLoading}
      onClick={props.onClick}
    >
      <i
        className={`bi bi-box-arrow-in-right me-3 ${props.isLoading?"d-none" : ""}`}
      ></i>
      <span
        className={`spinner-border spinner-border-sm me-3 ${!props.isLoading?"d-none" : ""}`}
        role="status"
      ></span>
      Zaloguj
    </button>
  );
}
