import { FieldPath, FieldValues } from "react-hook-form";
import getBasicMetadata, { BasicMetadata } from "./getBasicMetadata";

export interface TextAreaMetadata<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends BasicMetadata<TFieldValues, TName> {
  maxLength: number;
  minLength?: number;
  trimmedString?: boolean;
  transformEmptyStringToNull?: boolean;
  noWhitespacesOnly?: boolean;
}

export default function getTextAreaMetadata<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  name: TName,
  metadata: { [key: string]: any }
): TextAreaMetadata<TFieldValues, TName> {
  // below we temporaily force props without maxLength
  let props: TextAreaMetadata<TFieldValues, TName> = getBasicMetadata(
    name,
    metadata
  ) as TextAreaMetadata<TFieldValues, TName>;

  props.maxLength = metadata.maxLength;
  props.minLength = metadata.minLength;
  props.trimmedString = metadata.trimmed;
  props.transformEmptyStringToNull = metadata.noEmptyOrWhitespaceString;
  props.noWhitespacesOnly = metadata.noEmptyOrWhitespaceString;

  return props as TextAreaMetadata<TFieldValues, TName>;
}
