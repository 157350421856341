import { ValueFormatterParams } from "ag-grid-community";
import format from "format-number";

const twoFractionDigitsFormat = format({
  integerSeparator: "\xA0",
  round: 2,
  decimal: ",",
  padRight: 2,
});

export function twoFractionDigitsColumnFormatter(
  params: ValueFormatterParams
): string {
  return numberValueToTwoDecimalDigitsString(params.value);
}

export function numberValueToTwoDecimalDigitsString(value: any): string {
  if (typeof value === "number") {
    return twoFractionDigitsFormat(value);
  } else {
    return "";
  }
}

