import { Link } from "react-router-dom";

export function Pane(props: { link: string; imageUrl?: string; text: string }) {
  return (
    <Link className="-mko-lfp-pane" to={props.link}>
      {!!props.imageUrl && (
        <div>
          <img className="-mko-lfp-pane-icon" src={props.imageUrl} alt="" />
        </div>
      )}

      {!props.imageUrl && (
        <div>
          <div className="-mko-lfp-pane-icon"></div>
        </div>
      )}
      <div>
        <span className="-mko-lfp-pane-text">{props.text}</span>
      </div>
    </Link>
  );
}
