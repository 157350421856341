import "./LoginForm.scss";
import { useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import {
  ICredentials,
  login,
  loginFunction,
} from "../../repositories/userRepository";
import LoginButton from "./LoginButton";
import {
  appendTextDangerClassName,
  appendValidOrInvalidClassName,
} from "Common/EditForm/appendErrorClassNames";
import FieldError from "Common/EditForm/FieldError";
import { CompanyContext } from "App/companyContext";
import { companyInfoMap } from "repositories/companyRepository";
import CookieConsent from "App/controls/CookieConsent";
import TermsAndConditions from "App/controls/TermsAndConditions";
import { Link } from "react-router-dom";
import { ConfigFromServerContext } from "App/configFromServerContext";

interface Inputs {
  login: string;
  password: string;
  rememberMe: boolean;
}

let initialValues: Inputs = {
  login: "",
  password: "",
  rememberMe: false,
};

export default function LoginForm(props: {
  onLoggedIn: (userName: string) => void;
  onLogin?: loginFunction;
}) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, dirtyFields, touchedFields, isSubmitting },
  } = useForm<Inputs>({
    mode: "onTouched",
    defaultValues: initialValues,
  });

  const companyId = useContext(CompanyContext);
  const companyInfo = companyInfoMap.get(companyId ?? 0);
  if (companyInfo === undefined) throw new Error("Config error.");
  const configFromServer = useContext(ConfigFromServerContext)!;

  let [hasLoginError, setLoginError] = useState(false);

  const formLogin = async (credentials: ICredentials) => {
    let actualLogIn = props.onLogin ?? login;
    // the login function doesn't throw, it rather returns null
    let userName: string | null = await actualLogIn(credentials);
    if (userName) {
      setLoginError(false);
      props.onLoggedIn(userName);
    } else {
      setLoginError(true);
    }
  };

  const onSubmit = async (values: Inputs) => {
    let credentials: ICredentials = {
      userName: values.login,
      password: values.password,
      rememberMe: values.rememberMe,
    };
    await formLogin(credentials);
  };

  return (
    <div className="-app-login-form-main-wrapper">
      <div
        className={`-app-login-form content-wrapper -app-background-holder ${companyInfo.className}`}
      >
        <div>
          <img
            src={`/logo/${companyInfo.companyLogo}`}
            title="logo"
            className="-app-company-logo"
          />
        </div>
        <div className="container">
          <h1 className="mb-5 pt-4 pb-2 mt-0 border-bottom-0 text-center border-light border-bottom">
            <span>Logowanie do BOK {companyInfo.companyName}</span>
            {configFromServer.isTestEnvironment && (
              <div
                className="text-warning mt-2"
                style={{ fontVariant: "small-caps" }}
                title="Logujesz się do środowiska testowego. To, co robisz w tym środowisku, nie wpływa na środowisko produkcyjne."
              >
                Środowisko testowe
              </div>
            )}
          </h1>
          <div className="row">
            <div className="col-lg-6 offset-lg-3 border border-secondary p-4 bg-light bg-gradient shadow bg-opacity-50">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-3">
                  <div className="col">
                    <label
                      className={appendTextDangerClassName(
                        "form-label",
                        errors.login,
                        touchedFields.login
                      )}
                      htmlFor="login"
                    >
                      Login
                    </label>
                    <input
                      id="login"
                      className={appendValidOrInvalidClassName(
                        "form-control",
                        errors.login,
                        touchedFields.login
                      )}
                      type="text"
                      {...register("login", {
                        required: "Pole login jest wymagane.",
                        maxLength: {
                          value: 250,
                          message: "Login może mieć maksimum 250 znaków.",
                        },
                        minLength: {
                          value: 2,
                          message: "Login musi mieć co najmniej 2 znaki.",
                        },
                      })}
                    />
                    <FieldError error={errors.login?.message} />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <label
                      className={appendTextDangerClassName(
                        "form-label",
                        errors.password,
                        touchedFields.password
                      )}
                      htmlFor="password"
                    >
                      Hasło
                    </label>
                    <input
                      id="password"
                      className={appendValidOrInvalidClassName(
                        "form-control",
                        errors.password,
                        touchedFields.password
                      )}
                      type="password"
                      {...register("password", {
                        required: "Hasło jest wymagane.",
                      })}
                    />
                    <FieldError error={errors.password?.message} />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="rememberMe"
                        {...register("rememberMe")}
                      />
                      <label className="form-check-label" htmlFor="rememberMe">
                        Nie wyloguj
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <div
                      className="text-danger mt-1 small"
                      style={{
                        display: hasLoginError ? "block" : "none",
                      }}
                    >
                      Podane poświadczenia są niepoprawne lub konto jest
                      zablokowane.
                    </div>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col">
                    <div className="mt-1 text-text-secondary small">
                      {/* <a href={companyInfo.resetPasswordUrl}>
                       Zapomniałeś hasła?
                      </a> */}
                      <Link to="/Account/ForgotPassword">
                        Zapomniałeś hasła?
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <div className="mt-1 text-text-secondary small">
                      Nie masz jeszcze konta?{" "}
                      {/* <a href={companyInfo.registrationUrl}>
                        Zarejestruj się poprzez system SOB.
                      </a> */}
                      <Link to="/Account/Register">Zarejestruj się</Link>
                      <br />
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <div className="mt-1 text-text-secondary small">
                      <b>
                        Korzystałeś już z systemu zwrotu bębnów? Twoje konto już
                        tu jest!
                      </b>
                      <br />
                      Zaloguj się z takim samym e-mailem i hasłem, jakiego
                      używałeś wcześniej.
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xxl-5 offset-xxl-7 col-xl-6 offset-xl-6 col-lg-8 offset-lg-4">
                    <LoginButton isLoading={isSubmitting}></LoginButton>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <CookieConsent />
      <TermsAndConditions />
    </div>
  );
}
