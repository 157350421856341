import { useEffect, useState } from "react";
import {
  ComplaintItemViewModel,
  ComplaintServerVM,
  ViewModel,
  complaintViewModelToServerModel,
} from "./ViewModel";
import getViewModelNewValues from "./ViewModelNewValues";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { httpPost } from "Common/Http/httpPost";
import { IdNameObject } from "../../Common/Helpers/IdNameObject";
import httpGet from "Common/Http/httpGet";
import getClassMetadata from "Common/classMetadataService";
import LoadingContentWrapper from "Common/Loading/LoadingContentWrapper";
import TextInputFormRow from "Common/EditForm/TextInput/TextInputFormRow";
import getTextMetadata from "Common/EditForm/ClassMetadata/getTextMetadata";
import SelectFormRow from "Common/EditForm/Select/SelectFormRow";
import DateInputInputFormRow from "Common/EditForm/Date/DateInputFormRow";
import { AttachmentUpload } from "./controls/AttachmentUpload";
import FormButtons from "Common/EditForm/formButtons";
import SaveError from "Common/EditForm/SaveError";
import { ConfirmDialog } from "Common/Dialog/ConfirmDialog";
import httpPostNoBody from "Common/Http/httpPostNoBody";
import SaveSuccess from "./controls/SaveSuccess";
import ComplaintItemForm, {
  complaintReasonId_TransportDamage,
} from "./ComplaintItemForm";
import ComplaintItemDisplayFragment from "./controls/ComplaintItemDisplayFragment";
import { AlertDialog } from "Common/Dialog/AlertDialog";
import { getCompany } from "repositories/companyRepository";
import { validateDocumentNumber } from "./helpers/documentNumberValidation";

export default function CustomerComplaintForm(props: {
  navigate: (url: string) => void;
  backUrl?: string;
  onOk?: (id: number, name: string) => void;
  onCancel?: () => void;
}) {
  let [isLoading, setIsLoading] = useState<boolean>(true);
  let [isSaveError, setIsSaveError] = useState<boolean>(false);
  let [isSaveSuccessful, setIsSaveSuccessful] = useState<boolean>(false);
  let [complaintDocumentTypes, setComplaintDocumentTypes] = useState<
    {
      value: number;
      label: string;
    }[]
  >([]);
  let [metadata, setMetadata] = useState<{ [key: string]: any }>({});

  let [isItemFormShown, setIsItemFormShown] = useState<boolean>(false);
  let [editedItem, setEditedItem] =
    useState<ComplaintItemViewModel | null>(null);
  let [editedItemIndex, setEditedItemIndex] = useState<number>(0);
  let [companyId, setCompanyId] = useState<number | null>(null);
  let [isAttachmentRequired, setIsAttachmentRequired] =
    useState<boolean>(false);

  const methods = useForm<ViewModel>({
    mode: "onTouched",
    defaultValues: getViewModelNewValues(),
  });

  const { handleSubmit, formState, control, getValues, watch, trigger, reset } =
    methods;

  const itemsMethods = useFieldArray<ViewModel>({
    control,
    name: "complaintItems",
    rules: {},
  });

  const isThereTransportDamage = (
    itemsMethods.fields as ComplaintItemViewModel[]
  ).some((x) => x.complaintTypeId === complaintReasonId_TransportDamage);
  if (isThereTransportDamage != isAttachmentRequired)
    setIsAttachmentRequired(isThereTransportDamage);

  const { isSubmitting } = formState;

  const onSubmit = async (values: ViewModel) => {
    if (values.complaintItems.length === 0) {
      await AlertDialog(
        "Brak pozycji",
        "Musisz wprowadzić co najmniej jedną pozycję zgłoszenia."
      );
      return;
    }

    if (isAttachmentRequired && values.attachments.length === 0) {
      await AlertDialog(
        "Brak załącznika",
        "Ponieważ zgłosiłeś reklamację z powodu szkody transportowej, musisz dodać załącznik z protokołem szkody."
      );
      return;
    }

    // confirm submission

    let confirm = await ConfirmDialog(
      "Potwierdzenie wysyłania formularza",
      "Czy chcesz zakończyć wypełnianie formularza reklamacji i wysłać go?\r\nWysłanego zgłoszenia nie można wycofać ani edytować."
    );

    if (confirm === false) return;

    // let vmToSave = Object.assign({}, viewModel, values); // if values contain all the necessary data, this step will be unnecessary
    let data = complaintViewModelToServerModel(values);

    try {
      /* let o = */ await httpPost<ComplaintServerVM, any>(
        "/api/Complaints/Complaint",
        data
      );
      setIsSaveSuccessful(true);
    } catch (e) {
      setIsSaveError(true);
    }

    return;
  };

  const onInitialize = async () => {
    // load form values
    // fetch existing data for the form
    // load data for selects, etc.
    // this method is async so we can use await here

    let retrievedDocumentTypes = await httpGet<IdNameObject[]>(
      "/api/Complaints/ComplaintDocumentType"
    );

    let valueLabelPairs = retrievedDocumentTypes.map((x) => {
      return { value: x.id, label: x.name };
    });
    setComplaintDocumentTypes(valueLabelPairs);

    let metadata_response = await getClassMetadata("ComplaintVM");
    setMetadata(metadata_response);

    const localCompanyId = await getCompany();
    setCompanyId(localCompanyId);

    reset(getViewModelNewValues());

    setIsLoading(false);

    // // temporary fake line to test saving
    // itemsMethods.append({
    //   productName: "Some product1",
    //   catalogNumber: "Product cn",
    //   amount: 1.23,
    //   complaintTypeId: 1,
    //   lineCode: "Product line code1.",
    //   comments: "my comments 1.",
    // });

    // // temporary fake line to test saving
    // itemsMethods.append({
    //   productName: "Some product2",
    //   catalogNumber: "Product cn",
    //   amount: 1.23,
    //   complaintTypeId: 1,
    //   lineCode: "Product line code2",
    //   comments:
    //     "These are long comments.\r\nWith new lines.\r\nAnd some more text.",
    // });
  };

  useEffect(() => {
    onInitialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // we want to trigger revalidation of the sales document number on every change of the document type
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "complaintDocumentTypeId") {
        // only if the field is touched: this makes sense only when it is a new form, otherwise we should display an error immediatelly
        // Note: isSubmitted becomes true after the user attempts to submit, even if the validation fails; after that the validation messages are displayed and the field shoud be revalidated
        // Here isSubmitted is in practice not critical, because when a number is entered, the field is already touched
        if (
          formState.touchedFields.salesDocumentNumber ||
          formState.isSubmitted
        ) {
          trigger("salesDocumentNumber");
        }
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, formState]);

  const onCancel = async () => {
    let r = await ConfirmDialog(
      "Anulowanie wysyłania",
      "Czy na pewno chcesz porzucić ten formularz?\r\nWybierz OK aby porzucić formularz."
    );
    if (r === true) {
      let attachments = getValues("attachments");
      for (let attachement of attachments) {
        try {
          let fileId = attachement.id;
          await httpPostNoBody(
            `/api/Complaints/AttachmentUpload/removeUploadedFile/${fileId}`
          );
        } catch {
          // silently ignore a failure to remove an attachment, it will be deleted anyway.
        }
      }
      props.navigate("./../index");
    }
  };

  // const onFailure = async () => {
  //   reset(getViewModelNewValues());
  //   setIsSaveError(false);
  // };

  const onSaveSuccess = async () => {
    props.navigate("./../index");
  };

  const onRemoveItem = async (index: number) => {
    let r = await ConfirmDialog(
      "Usunięcie pozycji",
      `Czy na usunąć pozycję ${index + 1} ze zgłoszenia?`
    );
    if (r === true) {
      itemsMethods.remove(index);
    }
  };

  const onEditItem = async (index: number) => {
    const item = getValues().complaintItems[index];
    setEditedItem(item);
    setEditedItemIndex(index);
    setIsItemFormShown(true);
  };

  const onNewItem = async () => {
    setEditedItem(null);
    setIsItemFormShown(true);
  };

  const onItemSave = async (values: ComplaintItemViewModel) => {
    if (editedItem !== null) {
      // this was an edit
      itemsMethods.update(editedItemIndex, values);
    } else {
      // this was a new item
      itemsMethods.append(values!);
    }
    setIsItemFormShown(false);
  };

  return (
    <>
      {!isItemFormShown && (
        <div className="content-wrapper">
          <h1 className="border-bottom pb-2 mt-4 mb-5">Formularz reklamacji</h1>
          <LoadingContentWrapper isLoading={isLoading}>
            {!isLoading && !isSaveError && !isSaveSuccessful && (
              <FormProvider {...methods}>
                <form noValidate>
                  <TextInputFormRow
                    {...getTextMetadata<ViewModel>(
                      "customerRepName",
                      metadata["customerRepName"]
                    )}
                    label="Imię i nazwisko *"
                    longDescription="Imię i nazwisko osoby składającej reklamację (wymagane)."
                    control={control}
                    inlineDescription={undefined}
                  />

                  <TextInputFormRow
                    {...getTextMetadata<ViewModel>(
                      "customerRepEMail",
                      metadata["customerRepEMail"]
                    )}
                    label="Adres e-mail *"
                    longDescription="Adres e-mail osoby składającej reklamację (wymagane)."
                    control={control}
                    inlineDescription={undefined}
                  />

                  <TextInputFormRow
                    {...getTextMetadata<ViewModel>(
                      "customerRepPhone",
                      metadata["customerRepPhone"]
                    )}
                    label="Numer telefonu"
                    longDescription="Numer telefonu osoby składającej reklamację (niewymagane)."
                    control={control}
                    inlineDescription={undefined}
                    className="-app-medium-control"
                  />

                  <SelectFormRow
                    {...getTextMetadata<ViewModel>(
                      "complaintDocumentTypeId",
                      metadata["complaintDocumentTypeId"]
                    )}
                    label="Rodzaj dokumentu sprzedaży *"
                    longDescription="Rodzaj dokumentu (faktura VAT lub dokument Wz) (wymagane)."
                    control={control}
                    inlineDescription={undefined}
                    options={complaintDocumentTypes}
                    addNullOption={true}
                    valueIsNumber={true}
                    className="-app-medium-control"
                  />

                  <TextInputFormRow
                    {...getTextMetadata<ViewModel>(
                      "salesDocumentNumber",
                      metadata["salesDocumentNumber"]
                    )}
                    label="Numer dokumentu sprzedaży *"
                    longDescription="Numer faktury VAT lub dokumentu Wz, którego dotyczą towary do reklamacji (wymagane)."
                    control={control}
                    inlineDescription={undefined}
                    className="-app-medium-control"
                    uppercase={true}
                    rules={{
                      validate: {
                        numberingSchemaMatchesDocumentAndCompany: (v: any) => {
                          // shortcut for empty field
                          if (!v) return undefined;
                          // validaton between fields - with useForm/getValues()
                          const documentTypeId = getValues(
                            "complaintDocumentTypeId"
                          );
                          return validateDocumentNumber(
                            v,
                            companyId,
                            documentTypeId
                          );
                        },
                      },
                    }}
                  />

                  <DateInputInputFormRow
                    {...getTextMetadata<ViewModel>(
                      "salesDate",
                      metadata["salesDate"]
                    )}
                    label="Data sprzedaży"
                    longDescription={undefined}
                    control={control}
                    inlineDescription={undefined}
                  />
                  <hr />
                  <h2
                    className="mb-3"
                    title="Poniżej wprowadź pozycje dokumentu, których dotyczy reklamacja."
                  >
                    Pozycje
                    <sup>
                      <i className="bi bi-question-circle -app-h2-section-label-help"></i>
                    </sup>
                  </h2>

                  <ComplaintItemDisplayFragment
                    items={itemsMethods.fields as ComplaintItemViewModel[]}
                    onRemoveItem={onRemoveItem}
                    onEditItem={onEditItem}
                    onNewItem={onNewItem}
                  ></ComplaintItemDisplayFragment>

                  <hr />
                  <h2
                    className="mb-3"
                    title="Poniżej możesz dodać załączniki i podać ich opis."
                  >
                    Załączniki
                    <sup>
                      <i className="bi bi-question-circle -app-h2-section-label-help"></i>
                    </sup>
                  </h2>

                  <AttachmentUpload
                    parent="attachments"
                    isTransportDamageAttachmentRequired={isAttachmentRequired}
                  ></AttachmentUpload>

                  <hr />

                  <div className="row pt-2">
                    <div className="offset-sm-4 col-sm-8">
                      <FormButtons
                        onSave={(e) => handleSubmit(onSubmit)(e)}
                        onCancel={(e) => onCancel()}
                        isSubmitting={isSubmitting}
                      ></FormButtons>
                    </div>
                  </div>
                </form>
              </FormProvider>
            )}
            {isSaveError && (
              <SaveError
                navigate={props.navigate}
                backUrl="./../index"
              ></SaveError>
            )}
            {isSaveSuccessful && <SaveSuccess done={() => onSaveSuccess()} />}
          </LoadingContentWrapper>
        </div>
      )}

      {isItemFormShown && (
        <ComplaintItemForm
          values={editedItem}
          onOk={(values: ComplaintItemViewModel) => onItemSave(values)}
          onCancel={() => {
            setIsItemFormShown(false);
          }}
        ></ComplaintItemForm>
      )}
    </>
  );
}
