import "./AnonymousLayout.scss";
import { useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import CookieConsent from "App/controls/CookieConsent";
import TermsAndConditions from "App/controls/TermsAndConditions";
import { CompanyContext } from "App/companyContext";
import { companyInfoMap } from "repositories/companyRepository";
import { Outlet } from "react-router-dom";

export default function AnonymousLayout() {
  const companyId = useContext(CompanyContext);
  const companyInfo = companyInfoMap.get(companyId ?? 0);
  if (companyInfo === undefined) throw new Error("Config error.");

  return (
    <div className="-app-anonymous-layout-main-wrapper">
      <div
        className={`-app-anonymous-layout content-wrapper -app-background-holder ${companyInfo.className}`}
      >
        <div>
          <img src={`/logo/${companyInfo.companyLogo}`} title="logo" className="-app-company-logo" />
        </div>
        <Outlet/>
      </div>
      <CookieConsent />
      <TermsAndConditions />
    </div>
  );
}
