import format from "format-number";
import { sprintf } from "sprintf-js";

/**
 * Internal formatting function, extracted for unit tests.
 * @param value
 * @param decimalPlaces
 * @param format
 * @returns
 */
export function formatDecimalNumber(
    value: number,
    decimalPlaces: number,
    sprintfFormatString?: string
  ): string {
    let formatter = format({
      integerSeparator: "\xa0",
      round: decimalPlaces,
      decimal: ",",
      padRight: decimalPlaces,
    });
    let formatted = formatter(value);
  
    if (sprintfFormatString !== undefined) {
      formatted = sprintf(sprintfFormatString, formatted);
    }
  
    return formatted;
  }
  