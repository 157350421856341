export const PreventSubmitInputs = () => (
  <>
    <input
      type="text"
      id="username"
      style={{
        visibility: "hidden",
        width: 0,
        height: 0,
        position: "absolute",
        left: 0,
        right: 0,
      }}
    />
    <input
      type="password"
      style={{
        visibility: "hidden",
        width: 0,
        height: 0,
        position: "absolute",
        left: 0,
        right: 0,
      }}
    />
  </>
);
