export default function GridLoadingIndicator() {
    return (
      <div className="d-flex align-items-center">
        <div
          className="spinner-border text-primary flex-shrink-0 me-3"
          role="status"
        >
          <span className="visually-hidden">Wczytywanie...</span>
        </div>
        <div>Wczytywanie danych...</div>
      </div>
    );
  }